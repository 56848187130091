import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import SvgIcon from '@mui/material/SvgIcon';
import HomeSmileIcon from 'src/icons/untitled-ui/duocolor/home-smile';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import Users03Icon from 'src/icons/untitled-ui/duocolor/users-03';
import BusinessIcon from '@mui/icons-material/Business';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import Upload04Icon from 'src/icons/untitled-ui/duocolor/upload-04';

import { tokens } from 'src/locales/tokens';
import { paths } from 'src/paths';
import { RootState } from 'src/store';
import { useSelector } from 'react-redux';
import { Employee } from 'src/interfaces/employee.interface';
import { AuthContextType } from 'src/contexts/auth/jwt-context';
import { useAuth } from 'src/hooks/use-auth';

export interface Item {
  disabled?: boolean;
  external?: boolean;
  icon?: ReactNode;
  items?: Item[];
  label?: ReactNode;
  path?: string;
  title: string;
  show?: boolean;
}

export interface Section {
  items: Item[];
  subheader?: string;
}

export const useSections = () => {
  const { t } = useTranslation();
  const { employeeRights } = useAuth<AuthContextType>();
  const employee: Employee = useSelector((state: RootState) => state.employeeAccount.employee);

  console.log({
    componentName: "useSections",
    employeeRights,
    employee
  })
  
  return useMemo(
    () => {
      console.log({
        componentName: "useSections",
        line: "55",
        message: "inside useMemo",
        employeeRights,
        employee
      })
      return [
        {
          items: [
            {
              title: t(tokens.nav.overview),
              path: paths.dashboard.index,
              icon: (
                <SvgIcon fontSize="small">
                  <HomeSmileIcon />
                </SvgIcon>
              )
            },
            {
              title: t(tokens.nav.account),
              path: paths.dashboard.account,
              icon: (
                <SvgIcon fontSize="small">
                  <HomeSmileIcon />
                </SvgIcon>
              )
            }
          ]
        },
        {
          subheader: t(tokens.nav.modules),
          items: [
            {
              title: t(tokens.nav.entities),
              path: paths.dashboard.entities.index,
              show: employeeRights?.protect?.isVisible,
              icon: (
                <SvgIcon fontSize="small">
                  <BusinessIcon />
                </SvgIcon>
              ),
              items: [
                {
                  title: t(tokens.nav.viewEntities),
                  path: `${paths.dashboard.entities.index}`
                },
                {
                  title: t(tokens.nav.addEntity),
                  path: `${paths.dashboard.entities.add}`,
                  show: employeeRights?.protect?.canEdit
                },
              ]
            },
            {
              title: t(tokens.nav.teamMembers),
              path: paths.dashboard.teamMembers.index,
              show: employeeRights?.profile?.isVisible,
              icon: (
                <SvgIcon fontSize="small">
                  <Users03Icon />
                </SvgIcon>
              ),
              items: [
                {
                  title: t(tokens.nav.viewTeamMembers),
                  path: paths.dashboard.teamMembers.index
                },
                {
                  title: t(tokens.nav.yourProfile),
                  path: `${paths.dashboard.teamMembers.details}/${employee._id}`
                },
                {
                  title: t(tokens.nav.editYourProfile),
                  path: `${paths.dashboard.teamMembers.edit}/${employee._id}`,
                  show: employeeRights?.profile?.canEdit
                },
              ]
            },
            {
              title: t(tokens.nav.groupTransactions),
              path: paths.dashboard.groupTransactions.index,
              show: employeeRights?.professional?.isVisible,
              icon: (
                <SvgIcon fontSize="small">
                  <ReceiptLongIcon />
                </SvgIcon>
              ),
              items: [
                {
                  title: t(tokens.nav.viewTransactions),
                  path: `${paths.dashboard.groupTransactions.index}`
                },
                {
                  title: t(tokens.nav.addTransaction),
                  path: `${paths.dashboard.groupTransactions.add}`,
                  show: employeeRights?.professional?.canEdit
                },
              ],
            },
            {
              title: t(tokens.nav.calendar),
              path: paths.dashboard.calendar.index,
              show: employeeRights?.professional?.isVisible,
              icon: (
                <SvgIcon fontSize="small">
                  <EditCalendarIcon />
                </SvgIcon>
              ),
              items: [
                {
                  title: t(tokens.nav.agenda),
                  path: `${paths.dashboard.calendar.agenda}`
                },
                {
                  title: t(tokens.nav.newEvent),
                  path: `${paths.dashboard.calendar.newEvent}`,
                  show: employeeRights?.professional?.haveAdminRights
                },
              ],
            },
            {
              title: t(tokens.nav.fileManager),
              path: paths.dashboard.fileManager,
              show: employeeRights?.produce?.isVisible,
              icon: (
                <SvgIcon fontSize="small">
                  <Upload04Icon />
                </SvgIcon>
              ),
            },
          ]
        },
      ];
    },
    [employee, employeeRights.account, employeeRights.produce, employeeRights.professional, employeeRights.profile, employeeRights.protect, employeeRights.protocol, t]
  );
};

import type { FC, ReactNode } from 'react';
import { createContext, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { authApi } from 'src/api/auth';
import type { User } from 'src/types/user';
import { Issuer } from 'src/utils/auth';
import { Company } from 'src/interfaces/company.interface';
import { AUTH } from 'src/endpoints';
import { useDispatch } from "react-redux";
import { RootState } from "src/store";
import { ThunkDispatch } from "redux-thunk";
import type { AnyAction } from "@reduxjs/toolkit";
import { getEmployeeAccounts } from "src/slices/employee";
import { Employee } from 'src/interfaces/employee.interface';
import { getLoginLogs } from 'src/slices/loginLogs';
import { getOfficeHolderType } from 'src/slices/officeHolderType';
import { getEntities } from 'src/slices/entity';
import { getRoles } from 'src/slices/roles';
import { getModules } from 'src/slices/modules';
import { getDepartments } from 'src/slices/department';
import { getTransactions } from 'src/slices/transaction';
import { getCompanyAccounts } from 'src/slices/account';
import { getEvents } from 'src/slices/event';
import { useNavigate } from 'react-router';
import { authRequestHandler, requestHandler } from 'src/utils/fetch';
import { useSelector } from 'react-redux';
import { IModule } from 'src/interfaces/module.interface';
import { Role } from 'src/interfaces/role.interface';
import { checkAccessRights } from 'src/utils/checkAccesRights';
import toast from 'react-hot-toast';
import { paths } from 'src/paths';
import { getFolders } from 'src/slices/file-manager';
import { useTranslation } from 'react-i18next';
import { getEmployees } from 'src/slices/companyEmployees';

interface AccessRights {
  isVisible: boolean;
  canEdit: boolean;
  haveAdminRights: boolean;
}

interface EmployeeRights {
  protect?: AccessRights,
  professional?: AccessRights,
  produce?: AccessRights,
  protocol?: AccessRights,
  profile?: AccessRights,
  account?: AccessRights,
}
interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

enum ActionType {
  INITIALIZE = 'INITIALIZE',
  SIGN_IN = 'SIGN_IN',
  SIGN_UP = 'SIGN_UP',
  SIGN_OUT = 'SIGN_OUT'
}

type InitializeAction = {
  type: ActionType.INITIALIZE;
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type SignInAction = {
  type: ActionType.SIGN_IN;
  payload: {
    user: User;
  };
};

type SignUpAction = {
  type: ActionType.SIGN_UP;
  payload: {
    user: User;
  };
};

type SignOutAction = {
  type: ActionType.SIGN_OUT;
};

type Action =
  | InitializeAction
  | SignInAction
  | SignUpAction
  | SignOutAction;

type Handler = (state: State, action: any) => State;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers: Record<ActionType, Handler> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  SIGN_IN: (state: State, action: SignInAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  SIGN_UP: (state: State, action: SignUpAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  SIGN_OUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null
  })
};

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

export interface AuthContextType extends State {
  issuer: Issuer.JWT;
  employeeRights: EmployeeRights;
  signIn: (email: string, password: string, clientBrowser: string) => Promise<void>;
  companySignUp: (company: Company) => Promise<void>;
  employeeSignup: (employee: Employee) => Promise<void>;
  signOut: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({
  ...initialState,
  issuer: Issuer.JWT,
  employeeRights: {},
  signIn: () => Promise.resolve(),
  companySignUp: () => Promise.resolve(),
  employeeSignup: () => Promise.resolve(),
  signOut: () => Promise.resolve()
});

interface AuthProviderProps {
  children: ReactNode;
} 

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const Dispatch: ThunkDispatch<RootState, undefined, AnyAction> =
  useDispatch();

  const navigate = useNavigate();
  const { t } = useTranslation();

  // me request not implemented yet
  const initialize = useCallback(
    async (): Promise<void> => {
      try {
        const accessToken = localStorage.getItem('operatingpro_access_token');

        if (accessToken) {
          const user = await authApi.me({ accessToken });

          dispatch({
            type: ActionType.INITIALIZE,
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: ActionType.INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    },
    [dispatch]
  );

  useEffect(
    () => {
      initialize();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const employee: Employee = useSelector(
    (state: RootState) => state.employeeAccount.employee
  );
  const modulesList: IModule[] = useSelector(
    (state: RootState) => state.module.modules
  );
  const rolesList: Role[] = useSelector((state: RootState) => state.role.roles);

  const [employeeRights, setEmployeeRights] = useState<EmployeeRights>({});

  useEffect(() => {
    setEmployeeRights(() => ({
      protect: checkAccessRights( "protect", rolesList, modulesList, employee),
      professional: checkAccessRights( "professional", rolesList, modulesList, employee),
      produce: checkAccessRights( "produce", rolesList, modulesList, employee),
      profile: checkAccessRights( "profile", rolesList, modulesList, employee),
      protocol: checkAccessRights( "protocol", rolesList, modulesList, employee),
      account: checkAccessRights( "account", rolesList, modulesList, employee),
    }))
  }, [employee, modulesList, rolesList, employee.moduleAccessRights])

  console.log({
    componentName: "jwt-context",
    line: "247",
    employeeRights: employeeRights,
    employeeModuleAccessRights: employee.moduleAccessRights, 
    modulesList, 
    rolesList
  })

  const signIn = useCallback(
    async (email: string, password: string, clientBrowser: string): Promise<void> => {
      const { accessToken } = await authApi.signIn({ email, password, clientBrowser }, navigate);
      localStorage.setItem('isLoggedIn', 'true');

      const { response, resJson } = await authRequestHandler({
        url: AUTH.DECODE_TOKEN,
        navigate,
        method: 'GET',
      })

      if(response.ok){
        const tokenData = JSON.stringify(resJson?.data)
        localStorage.setItem('decoded_token',tokenData);

        Dispatch(getDepartments(resJson?.data?.companyId, navigate));
        Dispatch(getModules(navigate));
        Dispatch(getRoles(navigate));
        Dispatch(getEntities(navigate));
        Dispatch(getTransactions(navigate));
        Dispatch(getOfficeHolderType(navigate));
        Dispatch(getEmployeeAccounts(navigate));
        Dispatch(getLoginLogs(navigate));
        Dispatch(getCompanyAccounts(navigate));
        Dispatch(getEvents(navigate));
        Dispatch(getFolders(navigate));
        Dispatch(getEmployees(navigate));

      } else {
        console.log(resJson?.error);
      }
    },
    [Dispatch]
  );

  const companySignUp = useCallback(
    async (company: Company): Promise<void> => {
      const { tempToken } = await authApi.companySignUp(company, navigate);
      const plan = localStorage.getItem("plan")
      if (plan){
        localStorage.removeItem("plan")
        window.location.href = `${process.env.REACT_APP_LANDING_PAGE_BASE_URL}/checkout?token=${tempToken}&plan=${plan}`
      } else {
        window.location.href = `${process.env.REACT_APP_LANDING_PAGE_BASE_URL}/pricing?token=${tempToken}`
      }
    },
    []
  );
  
  const employeeSignup = useCallback(
    async (employee: Employee): Promise<void> => {
      const { redirectUrl } = await authApi.employeeSignup(employee, navigate);
      window.location.href = `${process.env.REACT_APP_URL}${redirectUrl}`
    },
    []
  );

  const signOut = useCallback(
    async (): Promise<void> => {
      localStorage.removeItem("isLoggedIn")
      localStorage.removeItem("decoded_token")
      const { response ,resJson } = await requestHandler({
        url: AUTH.SIGNOUT,
        navigate,
        method: "GET"
      })
      if(response.ok){
        toast.success(t(resJson.data))
        navigate(paths.auth.login)
      }
      dispatch({ type: ActionType.SIGN_OUT });
    },
    [dispatch]
  );

  return (
    <AuthContext.Provider
      value={{
        ...state,
        issuer: Issuer.JWT,
        employeeRights,
        signIn,
        companySignUp,
        employeeSignup,
        signOut
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const AuthConsumer = AuthContext.Consumer;

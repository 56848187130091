import i18next from "i18next";
import { TFunction } from "i18next";
import toast from "react-hot-toast";
import { NavigateFunction } from "react-router";
import { AUTH } from "src/endpoints";
import { tokens } from "src/locales/tokens";

interface RequestHandlerProps {
  url: string;
  navigate: NavigateFunction;
  method?: string;
  headers?: object;
  body?: string; // JSON.stringify,
  contentType?: string;
}

export const authRequestHandler = async (props: RequestHandlerProps) => {
  const { url, method, navigate, body, headers, contentType } = props;
  const t = i18next.t.bind(i18next);
  const requestOptions: RequestInit = {
    method: method || "GET",
    credentials: "include",
    headers: {
      "Content-Type": contentType || "application/json",
      ...headers,
    },
    body,
  };
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/${url}`,
    requestOptions
  );
  const resJson = await response.json();
  if (
    response.status === 401 &&
    resJson?.error === tokens.message.tokenValidationFailed
  ) {
    const signInSuccesful = await refreshTokenSignIn(navigate, t);
    if(signInSuccesful){
      // re attempt the api
      const newResponse = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/${url}`,
        {...requestOptions, headers: {
          ...requestOptions.headers,
        }}
      );
      const newResJson = await newResponse.json();

      if (newResponse.status !== 200 && newResponse.status !== 409) {
        console.log("error in re attempt:", newResJson?.error);
        toast.error(t(newResJson?.error));
      }

      return { 
        response: newResponse, 
        resJson: newResJson
      };
    }
  } else if (response.status !== 200 && response.status !== 409) {
    console.log(resJson?.error);
    toast.error(t(resJson?.error));
  }
  return { response, resJson };
};

// can make navigate as optional prop
export const requestHandler = async (props: RequestHandlerProps) => {
  const { url, method, navigate, body, headers, contentType } = props;
  const t = i18next.t.bind(i18next);
  const requestOptions: RequestInit = {
    method: method || "GET",
    credentials: "include",
    headers: {
      "Content-Type": contentType || "application/json",
      ...headers,
    },
    body,
  };
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/${url}`,
    requestOptions
  );
  const resJson = await response.json();
  if (response.status === 500) {
    console.log(resJson?.error);
    toast.error(t(resJson?.error));
  }
  return { response, resJson };
};

interface FileUploadRequestHandlerProps {
  url: string;
  navigate: NavigateFunction;
  method?: string;
  headers?: object;
  body?: FormData; 
}

export const fileUploadRequestHandler = async (props: FileUploadRequestHandlerProps) => {
  const { body, url, navigate, method } = props;
  const t = i18next.t.bind(i18next);
  const requestOptions: RequestInit = {
    method: method || "POST",
    credentials: "include",
    headers: {},
    body: body,
  };
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/${url}`,
    requestOptions
  );
  const resJson = await response.json();
  if (
    response.status === 401 &&
    resJson?.error === tokens.message.tokenValidationFailed
  ) {
    const signInSuccesful = await refreshTokenSignIn(navigate, t);
    if(signInSuccesful){
      // re attempt the api
      const newResponse = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/${url}`,
        requestOptions
      );
      const newResJson = await newResponse.json();

      if (newResponse.status !== 200 && newResponse.status !== 409) {
        console.log("error in re attempt:", newResJson?.error);
        toast.error(t(newResJson?.error));
      }

      return { 
        response: newResponse, 
        resJson: newResJson
      };
    }
  } else if (response.status !== 200 && response.status !== 409) {
    console.log(resJson?.error);
    toast.error(t(resJson?.error));
  }
  return { response, resJson };
}

const refreshTokenSignIn = async (navigate: NavigateFunction, t: TFunction<string[], undefined, string[]>) => {
  const refreshResponse = await requestHandler({
    url: AUTH.REFRESH_TOKEN,
    navigate,
    method: "GET"
  });
  if(refreshResponse?.response.ok){
    localStorage.setItem('isLoggedIn', 'true');

    const decodedResponse = await authRequestHandler({
      url: AUTH.DECODE_TOKEN,
      navigate,
      method: 'GET',
    })

    if(decodedResponse?.response.ok){
      const tokenData = JSON.stringify(decodedResponse?.resJson?.data)
      localStorage.setItem('decoded_token',tokenData);

      //successful signin
      return true
    }
  } else if (refreshResponse?.response?.status === 401){
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("decoded_token");
    navigate("/401");
    toast.error(t(refreshResponse?.resJson?.error));
    return false
  }
}
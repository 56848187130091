export const tokens = {
  common: {
    languageChanged: 'common.languageChanged'
  },
  nav: {
    groupTransactions: 'nav.groupTransactions',
    modules: 'nav.modules',
    academy: 'nav.academy',
    account: 'nav.account',
    analytics: 'nav.analytics',
    auth: 'nav.auth',
    addEntity: 'nav.addEntity',
    blog: 'nav.blog',
    billing: 'nav.billing',
    browse: 'nav.browse',
    calendar: 'nav.calendar',
    chat: 'nav.chat',
    checkout: 'nav.checkout',
    concepts: 'nav.concepts',
    contact: 'nav.contact',
    course: 'nav.course',
    create: 'nav.create',
    crypto: 'nav.crypto',
    customers: 'nav.customers',
    dashboard: 'nav.dashboard',
    details: 'nav.details',
    ecommerce: 'nav.ecommerce',
    entities: 'nav.entities',
    edit: 'nav.edit',
    error: 'nav.error',
    feed: 'nav.feed',
    fileManager: 'nav.fileManager',
    files: 'nav.files',
    finance: 'nav.finance',
    fleet: 'nav.fleet',
    forgotPassword: 'nav.forgotPassword',
    invoiceList: 'nav.invoices',
    jobList: 'nav.jobList',
    kanban: 'nav.kanban',
    list: 'nav.list',
    login: 'nav.login',
    logistics: 'nav.logistics',
    logout: 'nav.logout',
    mail: 'nav.mail',
    management: 'nav.management',
    orderList: 'nav.orders',
    overview: 'nav.overview',
    pages: 'nav.pages',
    postCreate: 'nav.postCreate',
    postDetails: 'nav.postDetails',
    postList: 'nav.postList',
    pricing: 'nav.pricing',
    productList: 'nav.products',
    profile: 'nav.profile',
    register: 'nav.register',
    resetPassword: 'nav.resetPassword',
    socialMedia: 'nav.socialMedia',
    teamMembers: 'nav.teamMembers',
    verifyCode: 'nav.verifyCode',
    viewEntities: 'nav.viewEntities',
    viewTeamMembers: 'nav.viewTeamMembers',
    yourProfile: 'nav.yourProfile',
    editYourProfile: 'nav.editYourProfile',
    viewTransactions: 'nav.viewTransactions',
    addTransaction: 'nav.addTransaction',
    newEvent: 'nav.newEvent',
    agenda: 'nav.agenda',
  },
  page: {
    back: 'page.back',
    dashboard: 'page.dashboard',
    teamMembers: 'page.teamMembers',
    search: 'page.search',
    overview: {
      // top heading
      overview: 'page.overview.overview',
      // cards
      entities: 'page.overview.entities',
      teamMembers: 'page.overview.teamMembers',
      groupTransactions: 'page.overview.groupTransactions',
      seeAllEntities: 'page.overview.seeAllEntities',
      seeAllTeamMembers: 'page.overview.seeAllTeamMembers',
      seeAllGroupTransactions: 'page.overview.seeAllGroupTransactions',
      // welcome card
      welcome: 'page.overview.welcome',
      startingWithOperatingPRO: 'page.overview.startingWithOperatingPRO',
      startingWithOperatingPROBody: 'page.overview.startingWithOperatingPROBody',
      // sliding cards
      inviteTeamMemberTitle: 'page.overview.inviteTeamMemberTitle',
      inviteTeamMemberBody: 'page.overview.inviteTeamMemberBody',
      verifyYourEmailTitle: 'page.overview.verifyYourEmailTitle',
      verifyYourEmailBody: 'page.overview.verifyYourEmailBody',
      addYourFirstEntityTitle: 'page.overview.addYourFirstEntityTitle',
      addYourFirstEntityBody: 'page.overview.addYourFirstEntityBody',
      addYourSecondEntityTitle: 'page.overview.addYourSecondEntityTitle',
      addYourSecondEntityBody: 'page.overview.addYourSecondEntityBody',
      addIntercompanyGroupTransactionTitle: 'page.overview.addIntercompanyGroupTransactionTitle',
      addIntercompanyGroupTransactionBody: 'page.overview.addIntercompanyGroupTransactionBody',
      // upgrade your account
      upgrade: 'page.overview.upgrade',
      upgradeYourAccountTitle: 'page.overview.upgradeYourAccountTitle',
      upgradeYourAccountBody: 'page.overview.upgradeYourAccountBody',
      supportCenter: 'page.overview.supportCenter',
      needHelpFiguringThingsOut: 'page.overview.needHelpFiguringThingsOut',
      needHelpFiguringThingsOutBody: 'page.overview.needHelpFiguringThingsOutBody',
      support: 'page.overview.support',
      upcomingEvents: 'page.overview.upcomingEvents',
      basedOnCalendarModule: 'page.overview.basedOnCalendarModule',
      seeAll: 'page.overview.seeAll',
      noUpcomingEvents: 'page.overview.noUpcomingEvents',
      newEvent: 'page.overview.newEvent',
    },
    accountPage: {
      // top heading:
      account: 'page.accountPage.account',
      // tabs:
      generalTab: 'page.accountPage.generalTab',
      billingTab: 'page.accountPage.billingTab',
      teamTab: 'page.accountPage.teamTab',
      notificationsTab: 'page.accountPage.notificationsTab',
      securityTab: 'page.accountPage.securityTab',
      // account general settings:
      basicDetails: 'page.accountPage.basicDetails',
      select: 'page.accountPage.select',
      companyName: 'page.accountPage.companyName',
      save: 'page.accountPage.save',
      fullName: 'page.accountPage.fullName',
      emailAddress: 'page.accountPage.emailAddress',
      roleDetails: 'page.accountPage.roleDetails',
      jobTitle: 'page.accountPage.jobTitle',
      departments: 'page.accountPage.departments',
      entity: 'page.accountPage.entity',
      locationDetails: 'page.accountPage.locationDetails',
      basedIn: 'page.accountPage.basedIn',
      localTime: 'page.accountPage.localTime',
      preferredLanguage: 'page.accountPage.preferredLanguage',
      // account billing settings:
      changePlan: 'page.accountPage.changePlan',
      changePlanSubHeader: 'page.accountPage.changePlanSubHeader',
      year: 'page.accountPage.year',
      usingNow: 'page.accountPage.usingNow',
      billingDetails: 'page.accountPage.billingDetails',
      cardNumber: 'page.accountPage.cardNumber',
      name: 'page.accountPage.name',
      notRequiredForTrialPeriod: 'page.accountPage.notRequiredForTrialPeriod',
      notProvided: 'page.accountPage.notProvided',
      trialEndDate: 'page.accountPage.trialEndDate',
      upgradePlan: 'page.accountPage.upgradePlan',
      autoRenewText1: 'page.accountPage.autoRenewText1',
      autoRenewText2: 'page.accountPage.autoRenewText2',
      autoRenewText3: 'page.accountPage.autoRenewText3',
      // account team settings
      inviteTeamMembers: 'page.accountPage.inviteTeamMembers',
      inviteUpTo50TeamMembers: 'page.accountPage.inviteUpTo50TeamMembers',
      inviteUpTo150TeamMembers: 'page.accountPage.inviteUpTo150TeamMembers',
      sendInvite: 'page.accountPage.sendInvite',
      searchTeamMembers: 'page.accountPage.searchTeamMembers',
      sortBy: 'page.accountPage.sortBy',
      oldest: 'page.accountPage.oldest',
      newest: 'page.accountPage.newest',
      teamMembers: 'page.accountPage.teamMembers',
      location: 'page.accountPage.location',
      role: 'page.accountPage.role',
      status: 'page.accountPage.status',
      actions: 'page.accountPage.actions',
      changeRole: 'page.accountPage.changeRole',
      remove: 'page.accountPage.remove',
      revokeInvite: 'page.accountPage.revokeInvite',
      // account security settings
      changePassword: 'page.accountPage.changePassword',
      currentPassword: 'page.accountPage.currentPassword',
      newPassword: 'page.accountPage.newPassword',
      newConfirmPassword: 'page.accountPage.newConfirmPassword',
      password: 'page.accountPage.password',
      cancel: 'page.accountPage.cancel',
      edit: 'page.accountPage.edit',
      deleteAccount: 'page.accountPage.deleteAccount',
      deleteAccountSubTitle: 'page.accountPage.deleteAccountSubTitle',
      loginHistory: 'page.accountPage.loginHistory',
      loginHistorySubtitle: 'page.accountPage.loginHistorySubtitle',
      deviceTime: 'page.accountPage.deviceTime',
      IPAddress: 'page.accountPage.IPAddress',
      // account notification settings
      email: 'page.accountPage.email',
      productUpdates: 'page.accountPage.productUpdates',
      newsAnnouncementsAndProductUpdates: 'page.accountPage.newsAnnouncementsAndProductUpdates',
      securityUpdates: 'page.accountPage.securityUpdates',
      NotificationsAboutAccountSecurity: 'page.accountPage.NotificationsAboutAccountSecurity',
      phoneNotifications: 'page.accountPage.phoneNotifications',
      removeEmployeeTitle: 'page.accountPage.removeEmployeeTitle',
      removeEmployeeBody: 'page.accountPage.removeEmployeeBody',
      revokeEmployeeTitle: 'page.accountPage.revokeEmployeeTitle',
      revokeEmployeeBody: 'page.accountPage.revokeEmployeeBody',
      revoke: 'page.accountPage.revoke',
    },
    entityListPage: {
      entities: 'page.entityListPage.entities',
      export: 'page.entityListPage.export',
      add: 'page.entityListPage.add',
      all: 'page.entityListPage.all',
      searchEntities: 'page.entityListPage.searchEntities',
      sortBy: 'page.entityListPage.sortBy',
      newest: 'page.entityListPage.newest',
      oldest: 'page.entityListPage.oldest',
      delete: 'page.entityListPage.delete',
      edit: 'page.entityListPage.edit',
      title: 'page.entityListPage.title',
      location: 'page.entityListPage.location',
      reportingDate: 'page.entityListPage.reportingDate',
      status: 'page.entityListPage.status',
      actions: 'page.entityListPage.actions',
      rowPerPage: 'page.entityListPage.rowPerPage',
    },
    entityAddPage: {
      addEntity: 'page.entityAddPage.addEntity',
      entityAdded: 'page.entityAddPage.entityAdded',
      type: 'page.entityAddPage.type',
      country: 'page.entityAddPage.country',
      registration: 'page.entityAddPage.registration',
      selectMostSuitable: 'page.entityAddPage.selectMostSuitable',
      continue: 'page.entityAddPage.continue',
      companyCorporation: 'page.entityAddPage.companyCorporation',
      trust: 'page.entityAddPage.trust',
      partnership: 'page.entityAddPage.partnership',
      soleProprietorshipSoleTrader: 'page.entityAddPage.soleProprietorshipSoleTrader',
      nonProfitOrganizationFoundation: 'page.entityAddPage.nonProfitOrganizationFoundation',
      coOperative: 'page.entityAddPage.coOperative',
      overseasBranch: 'page.entityAddPage.overseasBranch',
      whichCountryWasItEstablishedIn: 'page.entityAddPage.whichCountryWasItEstablishedIn',
      enterCountry: 'page.entityAddPage.enterCountry',
      selectedCountry: 'page.entityAddPage.selectedCountry',
      back: 'page.entityAddPage.back',
      whatIsFullLegalNameOfEntity: 'page.entityAddPage.whatIsFullLegalNameOfEntity',
      entityName: 'page.entityAddPage.entityName',
      entityNumber: 'page.entityAddPage.entityNumber',
      whatWasOfficialDateOfRegistration: 'page.entityAddPage.whatWasOfficialDateOfRegistration',
      registrationDate: 'page.entityAddPage.registrationDate',
      createEntity: 'page.entityAddPage.createEntity',
      allDone: 'page.entityAddPage.allDone',
      previewOfEntity: 'page.entityAddPage.previewOfEntity',
      view: 'page.entityAddPage.view',
      addAnotherEntity: 'page.entityAddPage.addAnotherEntity',
    },
    entityDetailsPage: {
      entities: 'page.entityDetailsPage.entities',
      entityId: 'page.entityDetailsPage.entityId',
      edit: 'page.entityDetailsPage.edit',
      name: 'page.entityDetailsPage.name',
      entityNumber: 'page.entityDetailsPage.entityNumber',
      entityType: 'page.entityDetailsPage.entityType',
      status: 'page.entityDetailsPage.status',
      registrationDate: 'page.entityDetailsPage.registrationDate',
      yearlyReportingDate: 'page.entityDetailsPage.yearlyReportingDate',
      locationDetails: 'page.entityDetailsPage.locationDetails',
      country: 'page.entityDetailsPage.country',
      stateRegion: 'page.entityDetailsPage.stateRegion',
      licenceType: 'page.entityDetailsPage.licenceType',
      licence: 'page.entityDetailsPage.licence',
      licenceNumber: 'page.entityDetailsPage.licenceNumber',
      regulatoryBody: 'page.entityDetailsPage.regulatoryBody',
      state: 'page.entityDetailsPage.state',
      officeHolder: 'page.entityDetailsPage.officeHolder',
      type: 'page.entityDetailsPage.type',
      shareholder: 'page.entityDetailsPage.shareholder',
      numberOfSharesHeld: 'page.entityDetailsPage.numberOfSharesHeld',
      countryOfResidence: 'page.entityDetailsPage.countryOfResidence',
      passport: 'page.entityDetailsPage.passport',
      politicallyExposedPerson: 'page.entityDetailsPage.politicallyExposedPerson',
      personWithSignificantControl: 'page.entityDetailsPage.personWithSignificantControl',
      personWithSignificantControlOptionYes: 'page.entityEditPage.personWithSignificantControlOptionYes',
      personWithSignificantControlOptionNo: 'page.entityEditPage.personWithSignificantControlOptionNo',
      politicallyExposedPersonYes: 'page.entityEditPage.politicallyExposedPersonYes',
      politicallyExposedPersonNo: 'page.entityEditPage.politicallyExposedPersonNo',
    },
    entityEditPage: {
      entities: 'page.entityEditPage.entities',
      select: 'page.entityEditPage.select',
      entityId: 'page.entityEditPage.entityId',
      basicDetails: 'page.entityEditPage.basicDetails',
      name: 'page.entityEditPage.name',
      entityNumber: 'page.entityEditPage.entityNumber',
      country: 'page.entityEditPage.country',
      state: 'page.entityEditPage.state',
      typeStatus: 'page.entityEditPage.typeStatus',
      entityType: 'page.entityEditPage.entityType',
      entityStatus: 'page.entityEditPage.entityStatus',
      registrationReportingDate: 'page.entityEditPage.registrationReportingDate',
      registrationDate: 'page.entityEditPage.registrationDate',
      yearlyReportingDate: 'page.entityEditPage.yearlyReportingDate',
      officeHolders: 'page.entityEditPage.officeHolders',
      shareholders: 'page.entityEditPage.shareholders',
      licence: 'page.entityEditPage.licence',
      update: 'page.entityEditPage.update',
      cancel: 'page.entityEditPage.cancel',
      licenceType: 'page.entityEditPage.licenceType',
      licenceNumber: 'page.entityEditPage.licenceNumber',
      regulatoryBody: 'page.entityEditPage.regulatoryBody',
      add: 'page.entityEditPage.add',
      officeHolderType: 'page.entityEditPage.officeHolderType',
      addCustom: 'page.entityEditPage.addCustom',
      shareHolderType: 'page.entityEditPage.shareHolderType',
      numberOfSharesHeld: 'page.entityEditPage.numberOfSharesHeld',
      countryOfResidence: 'page.entityEditPage.countryOfResidence',
      individual: 'page.entityEditPage.individual',
      companyCorporation: 'page.entityEditPage.companyCorporation',
      trust: 'page.entityEditPage.trust',
      partnership: 'page.entityEditPage.partnership',
      soleProprietorshipSoleTrader: 'page.entityEditPage.soleProprietorshipSoleTrader',
      nonProfitOrganizationFoundation: 'page.entityEditPage.nonProfitOrganizationFoundation',
      coOperative: 'page.entityEditPage.coOperative',
      overseasBranch: 'page.entityEditPage.overseasBranch',
      active: 'page.entityEditPage.active',
      dormant: 'page.entityEditPage.dormant',
      suspended: 'page.entityEditPage.suspended',
      administration: 'page.entityEditPage.administration',
      receivership: 'page.entityEditPage.receivership',
      liquidation: 'page.entityEditPage.liquidation',
      deregistered: 'page.entityEditPage.deregistered',
      politicallyExposedPerson: 'page.entityEditPage.politicallyExposedPerson',
      personWithSignificantControl: 'page.entityEditPage.personWithSignificantControl',
      personWithSignificantControlOptionYes: 'page.entityEditPage.personWithSignificantControlOptionYes',
      personWithSignificantControlOptionNo: 'page.entityEditPage.personWithSignificantControlOptionNo',
      politicallyExposedPersonYes: 'page.entityEditPage.politicallyExposedPersonYes',
      politicallyExposedPersonNo: 'page.entityEditPage.politicallyExposedPersonNo',
      entityTimezone: 'page.entityEditPage.entityTimezone',
      entityTeamSize: 'page.entityEditPage.entityTeamSize',
      entityCurrency: 'page.entityEditPage.entityCurrency',
      functionalCurrency: 'page.entityEditPage.functionalCurrency',
      presentationCurrency: 'page.entityEditPage.presentationCurrency',
      industry: 'page.entityEditPage.industry',
      industryGroup: 'page.entityEditPage.industryGroup',
      industryDivision: 'page.entityEditPage.industryDivision',
      industrySubclass: 'page.entityEditPage.industrySubclass',
    },
    fileManager: {
      fileManager: 'page.fileManager.fileManager',
      back: 'page.fileManager.back',
      deleteFileTitle: 'page.fileManager.deleteFileTitle',
      deleteFileBody: 'page.fileManager.deleteFileBody',
      cancel: 'page.fileManager.cancel',
      delete: 'page.fileManager.delete',
      search: 'page.fileManager.search',
      sortBy: 'page.fileManager.sortBy',
      latest: 'page.fileManager.latest',
      oldest: 'page.fileManager.oldest',
      byFavorites: 'page.fileManager.byFavorites',
      rowPerPage: 'page.fileManager.rowPerPage',
      items: 'page.fileManager.items',
      updatedAt: 'page.fileManager.updatedAt',
      copyLink: 'page.fileManager.copyLink',
    },
    fileUploaderModal: {
      uploadFile: 'page.fileUploaderModal.uploadFile',
      fileTag: 'page.fileUploaderModal.fileTag',
      remove: 'page.fileUploaderModal.remove',
      removeAll: 'page.fileUploaderModal.removeAll',
      Uploading: 'page.fileUploaderModal.Uploading',
      upload: 'page.fileUploaderModal.upload',
      clickToUpload: 'page.fileUploaderModal.clickToUpload',
      dragAndDrop: 'page.fileUploaderModal.dragAndDrop',
      maxFileSize: 'page.fileUploaderModal.maxFileSize',
    },
    teamMembersPage: {
      name: 'page.teamMembers.name',
      location: 'page.teamMembers.location',
      localTime: 'page.teamMembers.localTime',
      department: 'page.teamMembers.department',
      entity: 'page.teamMembers.entity',
      actions: 'page.teamMembers.actions',
      rowPerPage: 'page.teamMembers.rowPerPage',
      edit: 'page.teamMembers.edit',
      email: 'page.teamMembers.email',
      details: 'page.teamMembers.details',
      confidential: 'page.teamMembers.confidential',
      basicDetails: 'page.teamMembers.basicDetails',
      roleDetails: 'page.teamMembers.roleDetails',
      locationDetails: 'page.teamMembers.locationDetails',
      phone: 'page.teamMembers.phone',
      country: 'page.teamMembers.country',
      stateRegion: 'page.teamMembers.stateRegion',
      streetAddress: 'page.teamMembers.streetAddress',
      zipCode: 'page.teamMembers.zipCode',
      jobTitle: 'page.teamMembers.jobTitle',
      resetPassword: 'page.teamMembers.resetPassword',
      userId: 'page.teamMembers.userId',
      teamMemberId: 'page.teamMembers.teamMemberId',
      fullName: 'page.teamMembers.fullName',
      departments: 'page.teamMembers.departments',
      dateFormat: 'page.teamMembers.dateFormat',
      preferredLanguage: 'page.teamMembers.preferredLanguage',
      timeZone: 'page.teamMembers.timeZone',
      city: 'page.teamMembers.city',
      update: 'page.teamMembers.update',
      cancel: 'page.teamMembers.cancel',
    },
    calendarPage: {
      newEvent: 'page.calendarPage.newEvent',
      view: 'page.calendarPage.view',
      viewOptions: {
        month: 'page.calendarPage.viewOptions.month',
        week: 'page.calendarPage.viewOptions.week',
        day: 'page.calendarPage.viewOptions.day',
        agenda: 'page.calendarPage.viewOptions.agenda',
      },
      months: {
        january: 'page.calendarPage.months.january',
        february: 'page.calendarPage.months.february',
        march: 'page.calendarPage.months.march',
        april: 'page.calendarPage.months.april',
        may: 'page.calendarPage.months.may',
        june: 'page.calendarPage.months.june',
        july: 'page.calendarPage.months.july',
        august: 'page.calendarPage.months.august',
        september: 'page.calendarPage.months.september',
        october: 'page.calendarPage.months.october',
        november: 'page.calendarPage.months.november',
        december: 'page.calendarPage.months.december',
      },
      updateConfirmationModal: {
        title: 'page.calendarPage.updateConfirmationModal.title',
        body: 'page.calendarPage.updateConfirmationModal.body',
        cancelText: 'page.calendarPage.updateConfirmationModal.cancelText',
        confirmText: 'page.calendarPage.updateConfirmationModal.confirmText',
      },
      deleteModal: {
        title: 'page.calendarPage.deleteModal.title',
        body: 'page.calendarPage.deleteModal.body',
        cancelText: 'page.calendarPage.deleteModal.cancelText',
        confirmText: 'page.calendarPage.deleteModal.confirmText',
      },
      eventDialog: {
        editEvent: 'page.calendarPage.eventDialog.editEvent',
        addEvent: 'page.calendarPage.eventDialog.addEvent',
        title: 'page.calendarPage.eventDialog.title',
        description: 'page.calendarPage.eventDialog.description',
        entity: 'page.calendarPage.eventDialog.entity',
        timeZone: 'page.calendarPage.eventDialog.timeZone',
        startDate: 'page.calendarPage.eventDialog.startDate',
        endDate: 'page.calendarPage.eventDialog.endDate',
        allDay: 'page.calendarPage.eventDialog.allDay',
        type: 'page.calendarPage.eventDialog.type',
        regulatoryFilingDeadlineTitle: 'page.calendarPage.eventDialog.regulatoryFilingDeadlineTitle',
        regulatoryFilingDeadlineDescription: 'page.calendarPage.eventDialog.regulatoryFilingDeadlineDescription',
        significantEntityMilestoneTitle: 'page.calendarPage.eventDialog.significantEntityMilestoneTitle',
        significantEntityMilestoneDescription: 'page.calendarPage.eventDialog.significantEntityMilestoneDescription',
        otherImportantEventsTitle: 'page.calendarPage.eventDialog.otherImportantEventsTitle',
        otherImportantEventsDescription: 'page.calendarPage.eventDialog.otherImportantEventsDescription',
        cancel: 'page.calendarPage.eventDialog.cancel',
        confirm: 'page.calendarPage.eventDialog.confirm',
      },
      yearPicker: {
        buttonText: 'page.calendarPage.yearPicker.buttonText',
        cancel: 'page.calendarPage.yearPicker.cancel',
        ok: 'page.calendarPage.yearPicker.ok',
      }
    },
    groupTransactionList: {
      headerTitle: 'page.groupTransactionList.headerTitle',
      headerBody: 'page.groupTransactionList.headerBody',
      addGroupTransaction: 'page.groupTransactionList.addGroupTransaction',
      itemsPerPage: 'page.groupTransactionList.itemsPerPage',
      enterKeyword: 'page.groupTransactionList.enterKeyword',
      noFiltersApplied: 'page.groupTransactionList.noFiltersApplied',
      activeOnly: 'page.groupTransactionList.activeOnly',
      type: 'page.groupTransactionList.type',
      entity: 'page.groupTransactionList.entity',
      country: 'page.groupTransactionList.country',
      attachment: 'page.groupTransactionList.attachment',
      method: 'page.groupTransactionList.method',
      year: 'page.groupTransactionList.year',
      yes: 'page.groupTransactionList.yes',
      no: 'page.groupTransactionList.no',
    },
    groupTransactionAdd: {
      addGroupTransaction: 'page.groupTransactionAdd.addGroupTransaction',
      groupTransactionAdded: 'page.groupTransactionAdd.groupTransactionAdded',
      transactionTypeCapital: 'page.groupTransactionAdd.transactionTypeCapital',
      contractCapital: 'page.groupTransactionAdd.contractCapital',
      entityCapital: 'page.groupTransactionAdd.entityCapital',
      selectMostSuitable: 'page.groupTransactionAdd.selectMostSuitable',
      continue: 'page.groupTransactionAdd.continue',
      saleOfGoodsDescription: 'page.groupTransactionAdd.saleOfGoodsDescription',
      provisionOfServicesDescription: 'page.groupTransactionAdd.provisionOfServicesDescription',
      licenseOfIPDescription: 'page.groupTransactionAdd.licenseOfIPDescription',
      cashCapitalLoanDescription: 'page.groupTransactionAdd.cashCapitalLoanDescription',
      costSharingDescription: 'page.groupTransactionAdd.costSharingDescription',
      leaseOfTengibleAssetsDescription: 'page.groupTransactionAdd.leaseOfTengibleAssetsDescription',
      distributionResaleOfGoodsDescription: 'page.groupTransactionAdd.distributionResaleOfGoodsDescription',
      managementFeesDescription: 'page.groupTransactionAdd.managementFeesDescription',
      uploadedFiles: 'page.groupTransactionAdd.uploadedFiles',
      uploadFiles: 'page.groupTransactionAdd.uploadFiles',
      upload: 'page.groupTransactionAdd.upload',
      back: 'page.groupTransactionAdd.back',
      noAgreementAvailable: 'page.groupTransactionAdd.noAgreementAvailable',
      otherFile: 'page.groupTransactionAdd.otherFile',
      maxFileSize: 'page.groupTransactionAdd.maxFileSize',
      view: 'page.groupTransactionAdd.view',
      enterEntity: 'page.groupTransactionAdd.enterEntity',
      addTransaction: 'page.groupTransactionAdd.addTransaction',
      allDone: 'page.groupTransactionAdd.allDone',
      previewOfAddedTransaction: 'page.groupTransactionAdd.previewOfAddedTransaction',
      addAnother: 'page.groupTransactionAdd.addAnother',
    },
    groupTransactionEdit: {
      groupTransactions: 'page.groupTransactionEdit.groupTransactions',
      transactionNotFound: 'page.groupTransactionEdit.transactionNotFound',
      transaction: 'page.groupTransactionEdit.transaction',
      transactionId: 'page.groupTransactionEdit.transactionId',
      details: 'page.groupTransactionEdit.details',
      transactionType: 'page.groupTransactionEdit.transactionType',
      pricingMethod: 'page.groupTransactionEdit.pricingMethod',
      sendingEntity: 'page.groupTransactionEdit.sendingEntity',
      receivingEntity: 'page.groupTransactionEdit.receivingEntity',
      effectiveDateOfTransaction: 'page.groupTransactionEdit.effectiveDateOfTransaction',
      verified: 'page.groupTransactionEdit.verified',
      active: 'page.groupTransactionEdit.active',
      attachments: 'page.groupTransactionEdit.attachments',
      uploadMore: 'page.groupTransactionEdit.uploadMore',
      update: 'page.groupTransactionEdit.update',
      cancel: 'page.groupTransactionEdit.cancel',
      deleteAttachment: 'page.groupTransactionEdit.deleteAttachment',
      deleteAttachmentBody: 'page.groupTransactionEdit.deleteAttachmentBody',
      delete: 'page.groupTransactionEdit.delete',
      view: 'page.groupTransactionEdit.view',
    },
    groupTransactionDetails: {
      groupTransactions: 'page.groupTransactionDetails.groupTransactions',
      pricingMethodology: 'page.groupTransactionDetails.pricingMethodology',
      pricingMethodologyDescription: 'page.groupTransactionDetails.pricingMethodologyDescription',
      comparableTransactions: 'page.groupTransactionDetails.comparableTransactions',
      comparableTransactionsDescription: 'page.groupTransactionDetails.comparableTransactionsDescription',
      functionalAnalysis: 'page.groupTransactionDetails.functionalAnalysis',
      functionalAnalysisDescription: 'page.groupTransactionDetails.functionalAnalysisDescription',
      documentationRequirements: 'page.groupTransactionDetails.documentationRequirements',
      documentationRequirementsDescription: 'page.groupTransactionDetails.documentationRequirementsDescription',
      upload: 'page.groupTransactionDetails.upload',
      otherFile: 'page.groupTransactionDetails.otherFile',
      riskAssessment: 'page.groupTransactionDetails.riskAssessment',
      riskAssessmentDescription: 'page.groupTransactionDetails.riskAssessmentDescription',
      summary: 'page.groupTransactionDetails.summary',
      countries: 'page.groupTransactionDetails.countries',
      transactionYear: 'page.groupTransactionDetails.transactionYear',
      entities: 'page.groupTransactionDetails.entities',
      method: 'page.groupTransactionDetails.method',
      transactionDetails: 'page.groupTransactionDetails.transactionDetails',
      detailsTab: 'page.groupTransactionDetails.detailsTab',
      pricingMethodTab: 'page.groupTransactionDetails.pricingMethodTab',
      comparablesTab: 'page.groupTransactionDetails.comparablesTab',
      functionalTab: 'page.groupTransactionDetails.functionalTab',
      documentationTab: 'page.groupTransactionDetails.documentationTab',
      riskTab: 'page.groupTransactionDetails.riskTab',
      saleOfGoodsDetails1: 'page.groupTransactionDetails.saleOfGoodsDetails1',
      provisionOfServicesDetails1: 'page.groupTransactionDetails.provisionOfServicesDetails1',
      licenseOfIPDetails1: 'page.groupTransactionDetails.licenseOfIPDetails1',
      cashCapitalLoanDetails1: 'page.groupTransactionDetails.cashCapitalLoanDetails1',
      cashCapitalLoanDetails2: 'page.groupTransactionDetails.cashCapitalLoanDetails2',
      cashCapitalLoanDetails3: 'page.groupTransactionDetails.cashCapitalLoanDetails3',
      costSharingDetails1: 'page.groupTransactionDetails.costSharingDetails1',
      costSharingDetails2: 'page.groupTransactionDetails.costSharingDetails2',
      costSharingDetails3: 'page.groupTransactionDetails.costSharingDetails3',
      leaseOfTengibleAssetsDetails1: 'page.groupTransactionDetails.leaseOfTengibleAssetsDetails1',
      distributionResaleOfGoodsDetails1: 'page.groupTransactionDetails.distributionResaleOfGoodsDetails1',
      managementFeesDetails1: 'page.groupTransactionDetails.managementFeesDetails1',
    }
  },
  message: {
    requiredFieldsEmpty: 'message.requiredFieldsEmpty',
    successfullSignIn: 'message.successfullSignIn',
    alreadyInvited: 'message.alreadyInvited',
    employeeAlreadyExist: 'message.employeeAlreadyExist',
    invalidCredentials: 'message.invalidCredentials',
    internalServerError: 'message.internalServerError',
    notFound: 'message.notFound',
    alreadyExist: 'message.alreadyExist',
    savedSuccesfully: 'message.savedSuccesfully',
    trialPeriodExpired: 'message.trialPeriodExpired',
    subscriptionCreated: 'message.subscriptionCreated',
    paymentSuccesful: 'message.paymentSuccesful',
    companyRegistrationSuccessful: 'message.companyRegistrationSuccessful',
    invalidOTP: 'message.invalidOTP',
    unsubscribed: 'message.unsubscribed',
    emailSent: 'message.emailSent',
    emailNotSent: 'message.emailNotSent',
    validToken: 'message.validToken',
    inavlidToken: 'message.inavlidToken',
    tokenValidationFailed: 'message.tokenValidationFailed',
    notAuthrized: 'message.notAuthrized',
    updatedSuccesfully: 'message.updatedSuccesfully',
    companyNotFound: 'message.companyNotFound',
    currentPasswordIsWrong: 'message.currentPasswordIsWrong',
    loggedOutSuccessfully: 'message.loggedOutSuccessfully',
    verifyEmailFirst: 'message.verifyEmailFirst',

    removedSuccessfully: 'message.removedSuccessfully',
    invitationRevokedSuccessfully: 'message.invitationRevokedSuccessfully',
    resetPasswordLinkSent: 'message.resetPasswordLinkSent',
    passwordAndConfirmPassMustMatch: 'message.passwordAndConfirmPassMustMatch',
    passwordTooShort: 'message.passwordTooShort',
    passwordUpdated: 'message.passwordUpdated',
    otpSentToEmail: 'message.otpSentToEmail',
    photoUpdatedSuccessfully: 'message.photoUpdatedSuccessfully',
    employeeRoleUpdatedSuccessfully: 'message.employeeRoleUpdatedSuccessfully',
    companyNameUpdatedSuccessfully: 'message.companyNameUpdatedSuccessfully',
    nameUpdatedSuccessfully: 'message.nameUpdatedSuccessfully',
    somethingWentWrong: 'message.somethingWentWrong',
    countryFieldRequired: 'message.countryFieldRequired',
    entityNameFieldRequired: 'message.entityNameFieldRequired',
    entityCategoryFieldRequired: 'message.entityCategoryFieldRequired',
    entityUpdated: 'message.entityUpdated',
    officeHolderCreated: 'message.officeHolderCreated',
    entityDeleted: 'message.entityDeleted',
    transactionDeleted: 'message.transactionDeleted',
    departmentCreated: 'message.departmentCreated',
    teamMemberUpdated: 'message.teamMemberUpdated',
    transactionTypeRequired: 'message.transactionTypeRequired',
    contractTypeRequired: 'message.contractTypeRequired',
    contractFileAndFileTagRequired: 'message.contractFileAndFileTagRequired',
    entityFieldRequired: 'message.entityFieldRequired',
    groupTransactionUpdatedSuccessfully: 'message.groupTransactionUpdatedSuccessfully',

    eventCreated: 'message.eventCreated',
    eventUpdated: 'message.eventUpdated',
    eventDeleted: 'message.eventDeleted',
  }
};

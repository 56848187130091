import type { AnyAction, PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "src/store";
import { ThunkAction } from "redux-thunk";
import { ENTITY } from "src/endpoints";
import { Entity } from "src/interfaces/entity.interface";
import { NavigateFunction } from "react-router";
import { authRequestHandler } from "src/utils/fetch";

interface EntityState {
  entities: Entity[];
  isLoading: boolean
}

const initialState: EntityState = {
  entities: [],
  isLoading: true,
};

export const slice = createSlice({
  name: "entities",
  initialState,
  reducers: {
    setLoading(      
      state: EntityState,
      action: PayloadAction<boolean>
    ): void {
      state.isLoading = action.payload
    },
    setEntities(
      state: EntityState,
      action: PayloadAction<Entity[]>
    ): void {
      state.entities = action.payload;
    },
    updateEntity(      
      state: EntityState,
      action: PayloadAction<Entity>
      ): void {
        state.entities = state.entities.map(entity => {
          if(action.payload._id === entity._id){
            return {
              ...action.payload
            }
          }
          return entity
        })
      }
  },
});

type AsyncAction = ThunkAction<Promise<void>, RootState, undefined, AnyAction>;

export const getEntities =
  (navigate: NavigateFunction): AsyncAction =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(slice.actions.setLoading(true))
    const { response, resJson } = await authRequestHandler({
      url: ENTITY.LIST,
      method: 'GET',
      navigate,
    })
    if(response){
      dispatch(slice.actions.setEntities(resJson?.data));
      dispatch(slice.actions.setLoading(false))
    }
  };

export const updateEntityInRedux = (entity: Entity): AsyncAction =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(slice.actions.updateEntity(entity));
  };


export const { reducer } = slice;

import { lazy } from "react";
import type { RouteObject } from "react-router";
import { Outlet } from "react-router-dom";

import { Layout as AuthLayout } from "src/layouts/auth/auth-layout";

// Auth Pages with lazy loading
const LoginPage = lazy(() => import("src/pages/auth/login"));
const RegisterPage = lazy(() => import("src/pages/auth/register"));
const RegisterCompanyPage = lazy(() => import("src/pages/auth/register-company"));
const ForgetPasswordPage = lazy(() => import("src/pages/auth/forget-password"));
const ResetPasswordPage = lazy(() => import("src/pages/auth/reset-password"));

export const authRoutes: RouteObject[] = [
  {
    path: "auth",
    element: (
        <AuthLayout>
          <Outlet />
        </AuthLayout>),
    children: [
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "register/:token",
        element: <RegisterPage />,
      },
      {
        path: "register-company",
        element: <RegisterCompanyPage />,
      },
      {
        path: "forget-password",
        element: <ForgetPasswordPage />,
      },
      {
        path: "reset-password/:token",
        element: <ResetPasswordPage />,
      },
    ],
  },
];

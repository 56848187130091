import { combineReducers } from '@reduxjs/toolkit';
import { reducer as accountReducer } from 'src/slices/account';
import { reducer as employeeAccountReducer } from 'src/slices/employee';
import { reducer as companyEmployeesReducer } from 'src/slices/companyEmployees';
import { reducer as roleReducer } from 'src/slices/roles';
import { reducer as moduleReducer } from 'src/slices/modules';
import { reducer as loginLogsReducer } from 'src/slices/loginLogs';
import { reducer as departmentReducer } from 'src/slices/department';
import { reducer as entityReducer } from 'src/slices/entity';
import { reducer as officeHolderTypeReducer } from 'src/slices/officeHolderType';
import { reducer as transactionReducer } from 'src/slices/transaction';
import { reducer as eventReducer } from 'src/slices/event';
import { reducer as fileManagerReducer } from 'src/slices/file-manager';

export const rootReducer = combineReducers({
  account:accountReducer,
  employeeAccount:employeeAccountReducer,
  companyEmployees: companyEmployeesReducer,
  role: roleReducer,
  module: moduleReducer,
  loginLogs: loginLogsReducer,
  department: departmentReducer,
  entity: entityReducer,
  officeHolderType: officeHolderTypeReducer,
  transaction: transactionReducer,
  event: eventReducer,
  fileManager: fileManagerReducer,
});

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import type { Theme } from "@mui/material/styles/createTheme";

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { COMPANY } from "src/endpoints";
import { authRequestHandler } from "src/utils/fetch";
import { RouterLink } from "src/components/router-link";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import { paths } from "src/paths";

export const VerifyEmail = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [emailVerification, setEmailVerification] = useState(2); // 0 -> failed, 1 -> success, 2 -> checking

  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  usePageView();

  useEffect(() => {
    (async () => {
      setEmailVerification(2);
      const { response, resJson } = await authRequestHandler({
        navigate: navigate,
        method: "POST",
        url: COMPANY.VERIFY_EMAIL,
        body: JSON.stringify({
          token,
        }),
      });
      if (response.ok) {
        setEmailVerification(1);
      } else {
        setEmailVerification(0);
      }
    })();
  }, []);

  return (
    <>
      <Seo title="Verify Email" />
      <Box
        component="main"
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          py: "80px",
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 6,
            }}
          >
            <Box
              alt="Not found"
              component="img"
              src={
                emailVerification === 1
                  ? "/assets/iconly/iconly-glass-tick.svg"
                  : emailVerification === 0
                  ? "/assets/errors/error-401.png"
                  : "/assets/table-loader.svg"
              }
              sx={{
                height: "auto",
                maxWidth: "100%",
                width: 200,
              }}
            />
          </Box>
          <Typography align="center" variant={mdUp ? "h1" : "h4"}>
          {emailVerification === 1
              ? "Email Verified!"
              : emailVerification === 0
              ? "Unauthorized"
              : "Verifying"}
            
          </Typography>
          <Typography align="center" color="text.secondary" sx={{ mt: 0.5 }}>
            {emailVerification === 1
              ? "You can now invite your team members!"
              : emailVerification === 0
              ? "Please try again"
              : ""}
          </Typography>
          {emailVerification === 1 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 6,
              }}
            >
              <Button component={RouterLink} href={paths.index}>
                Login
              </Button>
            </Box>
          )}
        </Container>
      </Box>
    </>
  );
};

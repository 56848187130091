import React, { ReactNode, FC } from 'react';
import { Navigate } from 'react-router';

interface AuthProtectedProps {
  children: ReactNode;
}

export const AuthProtected: FC<AuthProtectedProps> = ({ children }) => {
  const isLoggedIn = localStorage.getItem('isLoggedIn');

  if(isLoggedIn !== 'true'){
    return <Navigate to="/auth/login" />
  }
  return <>{children}</>;
};

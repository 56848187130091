import type { AnyAction, PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "src/store";
import { ThunkAction } from "redux-thunk";
import { EVENT } from "src/endpoints";
import { CalendarEvent } from "src/types/calendar";
import toast from "react-hot-toast";
import { authRequestHandler } from "src/utils/fetch";
import { useTranslation } from "react-i18next";
import { NavigateFunction } from "react-router";
import { tokens } from "src/locales/tokens";
import i18next from "i18next";

const t = i18next.t.bind(i18next);
interface EventState {
  events: CalendarEvent[];
  isLoading: boolean;
}

const initialState: EventState = {
  events: [],
  isLoading: true,
};

export const slice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setLoading(state: EventState, action: PayloadAction<boolean>): void {
      state.isLoading = action.payload;
    },
    setEvents(state: EventState, action: PayloadAction<CalendarEvent[]>): void {
      state.events = action.payload;
    },
    addEvents(state: EventState, action: PayloadAction<CalendarEvent>): void {
      state.events = [...state.events, { ...action.payload }];
    },
    updateEvent(state: EventState, action: PayloadAction<CalendarEvent>): void {
      state.events = state.events.map((event) => {
        if (action.payload._id === event._id) {
          return {
            ...event,
            ...action.payload,
          };
        }
        return event;
      });
    },
    deleteEvent(state: EventState, action: PayloadAction<CalendarEvent>): void {
      state.events = state.events.filter(
        (event) => action.payload._id !== event._id
      );
    },
  },
});

type AsyncAction = ThunkAction<Promise<void>, RootState, undefined, AnyAction>;

export const createEvent =
  (event: CalendarEvent, navigate: NavigateFunction): AsyncAction =>
  async (dispatch: AppDispatch): Promise<void> => {
    const { response, resJson } = await authRequestHandler({
      url: EVENT.CREATE,
      method: 'POST',
      body: JSON.stringify({
        ...event,
      }),
      navigate,
    })
    if (response.ok) {
      dispatch(slice.actions.addEvents(resJson.data));
      toast.success(t(tokens.message.eventCreated)) // need locale here
    } 
  };

export const getEvents =
  (navigate: NavigateFunction): AsyncAction =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(slice.actions.setLoading(true));
    const {response, resJson} = await authRequestHandler({url: EVENT.LIST_ALL, navigate, method: 'GET'})
    if(response.ok){
        dispatch(slice.actions.setEvents(resJson?.data));
        dispatch(slice.actions.setLoading(false));
    }
  };

export const updateEvent =
  (event: CalendarEvent, navigate: NavigateFunction): AsyncAction =>
  async (dispatch: AppDispatch): Promise<void> => {
    const { response, resJson } = await authRequestHandler({
      url: EVENT.UPDATE_BY_ID,
      method: 'POST',
      navigate,
      body: JSON.stringify({
        ...event,
      }),
    });
    if (response.ok) {
      dispatch(slice.actions.updateEvent({ ...event }));
      toast.success(t(tokens.message.eventUpdated))
    }
  };

export const deleteEvent =
  (event: CalendarEvent, navigate: NavigateFunction, ): AsyncAction =>
  async (dispatch: AppDispatch): Promise<void> => {
    const { response, resJson } = await authRequestHandler({
      url: EVENT.DELETE,
      method: "POST",
      navigate,
      body: JSON.stringify({
        ...event,
      }),
    });
    if (response.ok) {
      dispatch(slice.actions.deleteEvent({ _id: event._id }));
      toast.success(t(tokens.message.eventDeleted))
    }
  };

export const { reducer } = slice;

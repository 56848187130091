import type { AnyAction, PayloadAction} from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'src/store';
import { ThunkAction } from 'redux-thunk';
import { ROLE } from 'src/endpoints';
import { authRequestHandler } from 'src/utils/fetch';
import { NavigateFunction } from 'react-router';
interface roleState {
    roles :any
}

const initialState: roleState = {
    roles : []
};


export const slice = createSlice({
  name: 'roles',
  initialState,
  reducers : {
    setRoles(state: roleState, action: PayloadAction<roleState>): void {
        state.roles = action.payload
    }
  }
});
type AsyncAction = ThunkAction<Promise<void>, RootState, undefined, AnyAction>;

export const getRoles = (navigate: NavigateFunction): AsyncAction => async (dispatch: AppDispatch): Promise<void> => {
  const { response, resJson } = await authRequestHandler({
    method: 'GET',
    url: ROLE.LIST,
    navigate
  });
  if(response.ok){
    dispatch(slice.actions.setRoles(resJson?.data));
  }
  };
  

export const { reducer } = slice;

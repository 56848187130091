import type { FC } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';

import { PropertyList } from 'src/components/property-list';
import { PropertyListItem } from 'src/components/property-list-item';
import { useTranslation } from 'react-i18next';
import { tokens } from 'src/locales/tokens';

interface TeamMemberBasicDetailsProps {
  street?: string;
  zipCode?: string;
  country?: string;
  email?: string;
  phone?: string;
  state?: string;
}

export const TeamMemberBasicDetails: FC<TeamMemberBasicDetailsProps> = (props) => {
  const { street, zipCode, country, email, phone, state, ...other } = props;

  const { t } = useTranslation();

  return (
    <Card {...other}>
      <CardHeader title={`${t(tokens.page.teamMembersPage.basicDetails)}`} />
      <PropertyList>
        <PropertyListItem
          divider
          label={`${t(tokens.page.teamMembersPage.email)}`}
          value={email}
        />
        <PropertyListItem
          // divider
          label={`${t(tokens.page.teamMembersPage.phone)}`}
          value={phone}
        />
      </PropertyList>
    </Card>
  );
};

TeamMemberBasicDetails.propTypes = {
  street: PropTypes.string,
  zipCode: PropTypes.string,
  country: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  state: PropTypes.string
};

import { useSelector } from "react-redux";
import { Employee } from "src/interfaces/employee.interface";
import { IModule } from "src/interfaces/module.interface";
import { Role } from "src/interfaces/role.interface";
import { RootState } from "src/store";

interface AccessRights {
  isVisible: boolean;
  canEdit: boolean;
  haveAdminRights: boolean;
}

export const checkAccessRights = (
  module: string,
  roleList: Role[],
  moduleList: IModule[],
  employee: Employee
): AccessRights => {

    // if super admin, have all the rights.
    if(employee.isSuperAdmin){
        return {
            isVisible: true,
            canEdit: true,
            haveAdminRights: true
        }
    }

  const accountModule = moduleList?.find(
    (mod) => mod?.module?.toLocaleLowerCase() === module
  );
  const employeeRoleID = employee?.moduleAccessRights?.find(
    (accessRight) => accessRight.module === accountModule?._id
  )?.role;
  const employeeRole = roleList?.find((role) => role._id === employeeRoleID);

  const isVisible = employeeRole?.role !== undefined && (employeeRole?.role === 'admin' || employeeRole?.role === 'viewer' || employeeRole?.role === 'editor')
  const canEdit = employeeRole?.role !== undefined && (employeeRole?.role === 'admin' || employeeRole?.role === 'editor')
  const haveAdminRights = employeeRole?.role !== undefined && (employeeRole?.role === 'admin')

  return { isVisible, canEdit, haveAdminRights }
};

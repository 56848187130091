import { Navigate, type RouteObject } from 'react-router';

import Error401Page from 'src/pages/401';
import Error404Page from 'src/pages/404';
import Error500Page from 'src/pages/500';

import ProfilePage from 'src/pages/profile';

import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';
import { policyRoutes } from './policy';
import { AuthProtected } from 'src/guards/auth-protected';
import { VerifyEmail } from 'src/pages/verify-email';

export const routes: RouteObject[] = [
  ...authRoutes,
  ...dashboardRoutes,
  ...policyRoutes,
  {
    path: 'profile',
    element: <AuthProtected><ProfilePage /></AuthProtected>
  },
  {
    path: '',
    element: <Navigate to="/auth/login" />
  },
  {
    path: 'verify-email/:token',
    element: <VerifyEmail />
  },
  {
    path: '401',
    element: <Error401Page />
  },
  {
    path: '404',
    element: <Error404Page />
  },
  {
    path: '500',
    element: <Error500Page />
  },
  {
    path: '*',
    element: <Error404Page />
  }
];

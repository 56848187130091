import type { AnyAction, PayloadAction} from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { COMPANY } from 'src/endpoints';
import { AppDispatch, RootState } from 'src/store';
import { ThunkAction } from 'redux-thunk';
import { authRequestHandler } from 'src/utils/fetch';
import { NavigateFunction } from 'react-router';
interface AccountState {
    company:any
}

const initialState: AccountState = {
    company:{}
};


export const slice = createSlice({
  name: 'account',
  initialState,
  reducers : {
    setAccounts(state: AccountState, action: PayloadAction<AccountState>): void {
        state.company = action.payload
    }
  }
});
type AsyncAction = ThunkAction<Promise<void>, RootState, undefined, AnyAction>;

export const getCompanyAccounts = (navigate: NavigateFunction): AsyncAction => async (dispatch: AppDispatch): Promise<void> => {
  const { response, resJson } = await authRequestHandler({
    method: 'GET',
    url: COMPANY.GET_INFO,
    navigate,
  })
  if(response.ok){
    dispatch(slice.actions.setAccounts(resJson?.data));
  }
  };
  

export const { reducer } = slice;

export const AUTH = {
  SIGNUP: "auth/signup",
  SIGNIN: "auth/signin",
  SIGNOUT: "auth/signout",
  DECODE_TOKEN: "auth/decode-token",
  REFRESH_TOKEN: "auth/refresh-token",
  VALIDATE_TOKEN: "auth/validate-token",
  FORGOT_PASSWORD: "auth/forgot-password",
  GET_OTP: "auth/get-otp",
  VERIFY_OTP: "auth/verify-otp",
};

export const EMPLOYEE = {
  UPDATE_EMPLOYEE_BY_ID: "employee/updateEmployeeById",
  EMPLOYEE_UPDATE_PHOTO: "employee/updatePhoto",
  EMPLOYEE_UPDATE: "employee/update",
  UPDATE_EMPLOYEE_PASSWORD: "employee/updatePassword",
  GET_INFO: "employee/get-employee-info",
  GET_EMPLOYEE_LOGIN_LOGS: "employee/get-employee-login-logs",
};

export const COMPANY = {
  REMOVE_EMPLOYEE: "company/remove-employee",
  COMPANY_UPDATE_INFO: "company/update",
  COMPANY_SEND_MAIL: "company/send-team-member-invitation",
  GET_INFO: "company/get-info",
  LIST_EMPLOYEES: "company/list-all-company-employees",
  VERIFY_EMAIL: 'company/verify-email',
};

export const ENTITY = {
  UPDATE_PHOTO: "entity/updatePhoto",
  DELETE: "entity/delete",
  CREATE: "entity/create",
  UPDATE: "entity/update",
  LIST: "entity/list-all",
};

export const OFFICE_HOLDER_TYPE = {
  CREATE: "office-holder-type/create",
  LIST: "office-holder-type/list-all",
};

export const DEPARTMENT = {
  CREATE: "department/create",
  LIST: "department/list-all",
};

export const MODULE = {
  LIST: "module/list-all-modules",
};

export const ROLE = {
  LIST: "role/list-all-roles",
};

export const TRANSACTION = {
  CREATE: "transaction/create",
  LIST_ALL: "transaction/list-all",
  DELETE: "transaction/delete",
  UPDATE_BY_ID: "transaction/update",
  DELETE_ATTACHMENT: "transaction/delete-attachment",
};

export const FILEUPLOAD = {
  SINGLE_UPLOAD: "file/single-upload",
  MULTIPLE_UPLOAD: "file/multiple-upload",
  DELETE: "file/delete",
  LIST_FILES: "file/list-files",
};

export const EVENT = {
  CREATE: "event/create",
  LIST_ALL: "event/list-all",
  UPDATE_BY_ID: "event/update",
  DELETE: "event/delete",
};

export const FAVOURITE_ROUTE = {
  TOGGLE_FAVOURITE: "favourite/toggle-favourite",
};

import { tokens } from '../tokens';

export const en = {
  [tokens.common.languageChanged]: 'You have changed the application language to english',
  [tokens.nav.groupTransactions]: 'Group Transactions',
  [tokens.nav.academy]: 'Academy',
  [tokens.nav.modules]: 'Modules',
  [tokens.nav.account]: 'Account',
  [tokens.nav.analytics]: 'Analytics',
  [tokens.nav.auth]: 'Auth',
  [tokens.nav.blog]: 'Blog',
  [tokens.nav.browse]: 'Browse',
  [tokens.nav.calendar]: 'Calendar',
  [tokens.nav.chat]: 'Chat',
  [tokens.nav.checkout]: 'Checkout',
  [tokens.nav.concepts]: 'Concepts',
  [tokens.nav.contact]: 'Contact',
  [tokens.nav.course]: 'Course',
  [tokens.nav.create]: 'Create',
  [tokens.nav.crypto]: 'Crypto',
  [tokens.nav.customers]: 'Customers',
  [tokens.nav.teamMembers]: 'Team Members',
  [tokens.nav.entities]: 'Entities',
  [tokens.nav.dashboard]: 'Dashboard',
  [tokens.nav.details]: 'Details',
  [tokens.nav.ecommerce]: 'E-Commerce',
  [tokens.nav.edit]: 'Edit',
  [tokens.nav.error]: 'Error',
  [tokens.nav.feed]: 'Feed',
  [tokens.nav.fileManager]: 'File Manager',
  [tokens.nav.finance]: 'Finance',
  [tokens.nav.fleet]: 'Fleet',
  [tokens.nav.forgotPassword]: 'Forgot Password',
  [tokens.nav.invoiceList]: 'Invoices',
  [tokens.nav.jobList]: 'Job Listings',
  [tokens.nav.kanban]: 'Kanban',
  [tokens.nav.list]: 'List',
  [tokens.nav.login]: 'Login',
  [tokens.nav.logistics]: 'Logistics',
  [tokens.nav.mail]: 'Mail',
  [tokens.nav.management]: 'Management',
  [tokens.nav.orderList]: 'Orders',
  [tokens.nav.overview]: 'Overview',
  [tokens.nav.pages]: 'Pages',
  [tokens.nav.postCreate]: 'Post Create',
  [tokens.nav.postDetails]: 'Post Details',
  [tokens.nav.postList]: 'Post List',
  [tokens.nav.pricing]: 'Pricing',
  [tokens.nav.productList]: 'Products',
  [tokens.nav.profile]: 'Profile',
  [tokens.nav.billing]: 'Billing',
  [tokens.nav.logout]: 'Logout',
  [tokens.nav.register]: 'Register',
  [tokens.nav.resetPassword]: 'Reset Password',
  [tokens.nav.socialMedia]: 'Social Media',
  [tokens.nav.verifyCode]: 'Verify Code',
  [tokens.nav.viewEntities]: 'View Entities',
  [tokens.nav.addEntity]: 'Add Entity',
  [tokens.nav.viewTeamMembers]: 'View Team Members',
  [tokens.nav.yourProfile]: 'Your Profile',
  [tokens.nav.editYourProfile]: 'Edit Your Profile',
  [tokens.nav.viewTransactions]: 'View Group Transactions',
  [tokens.nav.addTransaction]: 'Add Group Transaction',
  [tokens.nav.newEvent]: 'New Event',
  [tokens.nav.agenda]: 'Agenda',

  // pages content
  [tokens.page.back]: 'Back',
  [tokens.page.dashboard]: 'Dashboard',
  [tokens.page.teamMembers]: 'Team Members',
  [tokens.page.search]: 'Search',

  // overview page
  [tokens.page.overview.overview]: 'Overview',
  [tokens.page.overview.entities]: 'Group Entities',
  [tokens.page.overview.teamMembers]: 'Team Members',
  [tokens.page.overview.groupTransactions]: 'Group Transactions',
  [tokens.page.overview.seeAllEntities]: 'See all Entities',
  [tokens.page.overview.seeAllTeamMembers]: 'See all Team Members',
  [tokens.page.overview.seeAllGroupTransactions]: 'See all Group Transactions',
  [tokens.page.overview.welcome]: 'Welcome',
  [tokens.page.overview.startingWithOperatingPRO]: 'Starting with Operating PRO',
  [tokens.page.overview.startingWithOperatingPROBody]: 'Be guided through a proactive approach to intercompany compliance. Saving you time, money, and potential headaches down the line.',
  [tokens.page.overview.inviteTeamMemberTitle]: 'Invite a Team Member',
  [tokens.page.overview.inviteTeamMemberBody]: 'Admins can grant invited user’s access controls and delegate specific tasks for completion. Review their work using approval flows.',
  [tokens.page.overview.verifyYourEmailTitle]: 'Verify your email',
  [tokens.page.overview.verifyYourEmailBody]: 'To ensure the integrity of our email systems, you will be prompted to verify your email the first time you invite a team member',
  [tokens.page.overview.addYourFirstEntityTitle]: 'Add your first Entity',
  [tokens.page.overview.addYourFirstEntityBody]: 'Begin building your group structure by adding your first entity, whether it’s your Holding Company or a subsidiary overseas.',
  [tokens.page.overview.addYourSecondEntityTitle]: 'Add your second Entity',
  [tokens.page.overview.addYourSecondEntityBody]: 'Entities in your Group can take the form of Company, Trust, Partnership, Sole Trader, Non-Profit, Co-up, or Branch.',
  [tokens.page.overview.addIntercompanyGroupTransactionTitle]: 'Add an Intercompany Group Transaction',
  [tokens.page.overview.addIntercompanyGroupTransactionBody]: 'Document transactions between your Entities using the Group Transaction module. ',
  [tokens.page.overview.upgrade]: 'Upgrade',
  [tokens.page.overview.upgradeYourAccountTitle]: 'Upgrade your account.',
  [tokens.page.overview.upgradeYourAccountBody]: 'Unlock exclusive features CbCR, Transfer Pricing Local Files, and Year-End Adjustments.',
  [tokens.page.overview.supportCenter]: 'Support Center',
  [tokens.page.overview.needHelpFiguringThingsOut]: 'Need help figuring things out?',
  [tokens.page.overview.needHelpFiguringThingsOutBody]: 'Unsure or have questions? Our expert team is here to support you. Contact us for help.',
  [tokens.page.overview.support]: 'Support',
  [tokens.page.overview.upcomingEvents]: 'Upcoming events',
  [tokens.page.overview.basedOnCalendarModule]: 'Based on the Calendar module',
  [tokens.page.overview.seeAll]: 'See all',
  [tokens.page.overview.noUpcomingEvents]: 'No upcoming events, add new event',
  [tokens.page.overview.newEvent]: 'New Event',

  // account page
  [tokens.page.accountPage.account]: 'Account',
  [tokens.page.accountPage.generalTab]: 'General',
  [tokens.page.accountPage.billingTab]: 'Billing',
  [tokens.page.accountPage.notificationsTab]: 'Notifications',
  [tokens.page.accountPage.teamTab]: 'Team',
  [tokens.page.accountPage.securityTab]: 'Security',
  [tokens.page.accountPage.basicDetails]: 'Basic Details',
  [tokens.page.accountPage.select]: 'Select',
  [tokens.page.accountPage.companyName]: 'Company Name',
  [tokens.page.accountPage.save]: 'Save',
  [tokens.page.accountPage.fullName]: 'Full Name',
  [tokens.page.accountPage.emailAddress]: 'Email Address',
  [tokens.page.accountPage.roleDetails]: 'Role Details',
  [tokens.page.accountPage.jobTitle]: 'Job Title',
  [tokens.page.accountPage.departments]: 'Departments',
  [tokens.page.accountPage.entity]: 'Entity',
  [tokens.page.accountPage.locationDetails]: 'Location Details',
  [tokens.page.accountPage.basedIn]: 'Based In',
  [tokens.page.accountPage.localTime]: 'Local Time',
  [tokens.page.accountPage.preferredLanguage]: 'Preferred Language',
  [tokens.page.accountPage.changePlan]: 'Change Plan',
  [tokens.page.accountPage.changePlanSubHeader]: 'Upgrade whenever you are ready',
  [tokens.page.accountPage.year]: 'year',
  [tokens.page.accountPage.usingNow]: 'Using now',
  [tokens.page.accountPage.billingDetails]: 'Billing Details',
  [tokens.page.accountPage.cardNumber]: 'Card Number',
  [tokens.page.accountPage.name]: 'Name',
  [tokens.page.accountPage.notRequiredForTrialPeriod]: 'Not required for trial period',
  [tokens.page.accountPage.notProvided]: 'Not provided',
  [tokens.page.accountPage.trialEndDate]: 'Trial End Date',
  [tokens.page.accountPage.upgradePlan]: 'Upgrade Plan',
  [tokens.page.accountPage.autoRenewText1]: `Your yearly subscription auto-renews for convenience, but you're in control`,
  [tokens.page.accountPage.autoRenewText2]: 'Cancel Auto-Renewal',
  [tokens.page.accountPage.autoRenewText3]: 'at anytime.',
  [tokens.page.accountPage.inviteTeamMembers]: 'Invite team members',
  [tokens.page.accountPage.inviteUpTo50TeamMembers]: 'You can invite up to 50 Team Members.',
  [tokens.page.accountPage.inviteUpTo150TeamMembers]: 'You can invite up to 150 Team Members.',
  [tokens.page.accountPage.sendInvite]: 'Send Invite',
  [tokens.page.accountPage.searchTeamMembers]: 'Search team members',
  [tokens.page.accountPage.sortBy]: 'Sort By',
  [tokens.page.accountPage.oldest]: 'Oldest',
  [tokens.page.accountPage.newest]: 'Newest',
  [tokens.page.accountPage.teamMembers]: 'Team Members',
  [tokens.page.accountPage.location]: 'Location',
  [tokens.page.accountPage.role]: 'Role',
  [tokens.page.accountPage.status]: 'Status',
  [tokens.page.accountPage.actions]: 'Actions',
  [tokens.page.accountPage.changeRole]: 'Change Role',
  [tokens.page.accountPage.remove]: 'Remove',
  [tokens.page.accountPage.revokeInvite]: 'Revoke Invite',
  [tokens.page.accountPage.changePassword]: 'Change Password',
  [tokens.page.accountPage.currentPassword]: 'Current Password',
  [tokens.page.accountPage.newPassword]: 'New Password',
  [tokens.page.accountPage.newConfirmPassword]: 'New Confirm Password',
  [tokens.page.accountPage.password]: 'Password',
  [tokens.page.accountPage.cancel]: 'Cancel',
  [tokens.page.accountPage.edit]: 'Edit',
  [tokens.page.accountPage.deleteAccount]: 'Delete account',
  [tokens.page.accountPage.deleteAccountSubTitle]: 'Delete your account and all of your source data. This is irreversible.',
  [tokens.page.accountPage.loginHistory]: 'Login History',
  [tokens.page.accountPage.loginHistorySubtitle]: 'Your recent login activity',
  [tokens.page.accountPage.deviceTime]: 'Device & Time',
  [tokens.page.accountPage.IPAddress]: 'IP Address',
  [tokens.page.accountPage.email]: 'Email',
  [tokens.page.accountPage.productUpdates]: 'Product updates',
  [tokens.page.accountPage.newsAnnouncementsAndProductUpdates]: 'News, announcements, and product updates.',
  [tokens.page.accountPage.securityUpdates]: 'Security updates',
  [tokens.page.accountPage.NotificationsAboutAccountSecurity]: 'Important notifications about your account security.',
  [tokens.page.accountPage.phoneNotifications]: 'Phone Notifications',
  [tokens.page.accountPage.removeEmployeeTitle]: 'Remove Employee',
  [tokens.page.accountPage.removeEmployeeBody]: 'Are you sure you want to delete this employee from your company?',
  [tokens.page.accountPage.revokeEmployeeTitle]: 'Revoke Employee Invitation',
  [tokens.page.accountPage.revokeEmployeeBody]: `Are you sure you want to revoke this employee's invitation from your company?`,
  [tokens.page.accountPage.revoke]: `Revoke`,

  
  // entity list page
  [tokens.page.entityListPage.entities]: 'Entities',
  [tokens.page.entityListPage.export]: 'Export',
  [tokens.page.entityListPage.add]: 'Add',
  [tokens.page.entityListPage.all]: 'All',
  [tokens.page.entityListPage.searchEntities]: 'Search Entities',
  [tokens.page.entityListPage.sortBy]: 'Sort By',
  [tokens.page.entityListPage.newest]: 'Newest',
  [tokens.page.entityListPage.oldest]: 'Oldest',
  [tokens.page.entityListPage.delete]: 'Delete',
  [tokens.page.entityListPage.edit]: 'Edit',
  [tokens.page.entityListPage.title]: 'Title',
  [tokens.page.entityListPage.location]: 'Location',
  [tokens.page.entityListPage.reportingDate]: 'Reporting Date',
  [tokens.page.entityListPage.status]: 'Status',
  [tokens.page.entityListPage.actions]: 'Actions',
  [tokens.page.entityListPage.rowPerPage]: 'Rows per page',
  
  // entity add page
  [tokens.page.entityAddPage.addEntity]: 'Add Entity',
  [tokens.page.entityAddPage.entityAdded]: 'Entity Added',
  [tokens.page.entityAddPage.type]: 'Type',
  [tokens.page.entityAddPage.country]: 'Country',
  [tokens.page.entityAddPage.registration]: 'Registration',
  [tokens.page.entityAddPage.selectMostSuitable]: 'Select most suitable',
  [tokens.page.entityAddPage.continue]: 'Continue',
  [tokens.page.entityAddPage.companyCorporation]: 'Company/Corporation',
  [tokens.page.entityAddPage.trust]: 'Trust',
  [tokens.page.entityAddPage.partnership]: 'Partnership',
  [tokens.page.entityAddPage.soleProprietorshipSoleTrader]: 'Sole Proprietorship/Sole Trader',
  [tokens.page.entityAddPage.nonProfitOrganizationFoundation]: 'Non-Profit Organization/Foundation',
  [tokens.page.entityAddPage.coOperative]: 'Co-operative',
  [tokens.page.entityAddPage.overseasBranch]: 'Overseas Branch',
  [tokens.page.entityAddPage.whichCountryWasItEstablishedIn]: 'Which country was it established in?',
  [tokens.page.entityAddPage.enterCountry]: 'Enter a Country',
  [tokens.page.entityAddPage.selectedCountry]: 'Selected Country',
  [tokens.page.entityAddPage.back]: 'Back',
  [tokens.page.entityAddPage.whatIsFullLegalNameOfEntity]: 'What is the full legal name of the entity?',
  [tokens.page.entityAddPage.entityName]: 'Entity Name',
  [tokens.page.entityAddPage.entityNumber]: 'Entity Number',
  [tokens.page.entityAddPage.whatWasOfficialDateOfRegistration]: 'When was the official date of registration?',
  [tokens.page.entityAddPage.registrationDate]: 'Registration Date',
  [tokens.page.entityAddPage.createEntity]: 'Create Entity',
  [tokens.page.entityAddPage.allDone]: 'All done!',
  [tokens.page.entityAddPage.previewOfEntity]: 'Here’s a preview of the entity you just added.',
  [tokens.page.entityAddPage.view]: 'View',
  [tokens.page.entityAddPage.addAnotherEntity]: 'Add another entity',
  
  // entity details page
  [tokens.page.entityDetailsPage.entities]: 'Entities',
  [tokens.page.entityDetailsPage.entityId]: 'entity-id',
  [tokens.page.entityDetailsPage.edit]: 'Edit',
  [tokens.page.entityDetailsPage.name]: 'Name',
  [tokens.page.entityDetailsPage.entityNumber]: 'Entity Number',
  [tokens.page.entityDetailsPage.entityType]: 'Entity Type',
  [tokens.page.entityDetailsPage.status]: 'Status',
  [tokens.page.entityDetailsPage.registrationDate]: 'Registration Date',
  [tokens.page.entityDetailsPage.yearlyReportingDate]: 'Yearly Reporting Date',
  [tokens.page.entityDetailsPage.locationDetails]: 'Location Details',
  [tokens.page.entityDetailsPage.country]: 'Country',
  [tokens.page.entityDetailsPage.stateRegion]: 'State/Region',
  [tokens.page.entityDetailsPage.licenceType]: 'Licence Type',
  [tokens.page.entityDetailsPage.licence]: 'Licence',
  [tokens.page.entityDetailsPage.licenceNumber]: 'Licence Number',
  [tokens.page.entityDetailsPage.regulatoryBody]: 'Regulatory Body',
  [tokens.page.entityDetailsPage.state]: 'State',
  [tokens.page.entityDetailsPage.officeHolder]: 'Office Holder',
  [tokens.page.entityDetailsPage.type]: 'Type',
  [tokens.page.entityDetailsPage.shareholder]: 'Shareholder',
  [tokens.page.entityDetailsPage.numberOfSharesHeld]: 'Number Of Shares Held',
  [tokens.page.entityDetailsPage.countryOfResidence]: 'Country Of Residence',
  [tokens.page.entityDetailsPage.passport]: 'Passport',
  [tokens.page.entityDetailsPage.politicallyExposedPerson]: 'Politically Exposed Person',
  [tokens.page.entityDetailsPage.personWithSignificantControl]: 'Person with Significant Control',
  [tokens.page.entityDetailsPage.personWithSignificantControlOptionYes]: 'Yes, Person with Significant Control (PSC)',
  [tokens.page.entityDetailsPage.personWithSignificantControlOptionNo]: 'Not a Person with Significant Control (PSC)',
  [tokens.page.entityDetailsPage.politicallyExposedPersonYes]: 'Yes, Politically Exposed Person (PEP)',
  [tokens.page.entityDetailsPage.politicallyExposedPersonNo]: 'Not a Politically Exposed Person (PEP)',

  // entity edit page
  [tokens.page.entityEditPage.entities]: 'Entities',
  [tokens.page.entityEditPage.select]: 'Select',
  [tokens.page.entityEditPage.entityId]: 'entity-id',
  [tokens.page.entityEditPage.basicDetails]: 'Basic Details',
  [tokens.page.entityEditPage.name]: 'Name',
  [tokens.page.entityEditPage.entityNumber]: 'Entity Number',
  [tokens.page.entityEditPage.country]: 'Country',
  [tokens.page.entityEditPage.state]: 'State',
  [tokens.page.entityEditPage.typeStatus]: 'Type & Status',
  [tokens.page.entityEditPage.entityType]: 'Entity Type',
  [tokens.page.entityEditPage.entityStatus]: 'Entity Status',
  [tokens.page.entityEditPage.registrationReportingDate]: 'Registration & Reporting Date',
  [tokens.page.entityEditPage.registrationDate]: 'Registration Date',
  [tokens.page.entityEditPage.yearlyReportingDate]: 'Yearly Reporting Date',
  [tokens.page.entityEditPage.officeHolders]: 'Office Holders',
  [tokens.page.entityEditPage.shareholders]: 'Shareholders',
  [tokens.page.entityEditPage.licence]: 'Licence(s)',
  [tokens.page.entityEditPage.update]: 'Update',
  [tokens.page.entityEditPage.cancel]: 'Cancel',
  [tokens.page.entityEditPage.licenceType]: 'Licence Type',
  [tokens.page.entityEditPage.licenceNumber]: 'Licence Number',
  [tokens.page.entityEditPage.regulatoryBody]: 'Regulatory Body',
  [tokens.page.entityEditPage.add]: 'Add',
  [tokens.page.entityEditPage.officeHolderType]: 'Office Holder Type',
  [tokens.page.entityEditPage.addCustom]: 'Add Custom',
  [tokens.page.entityEditPage.shareHolderType]: 'Share Holder Type',
  [tokens.page.entityEditPage.numberOfSharesHeld]: 'Number Of Shares Held',
  [tokens.page.entityEditPage.countryOfResidence]: 'Country Of Residence',
  [tokens.page.entityEditPage.individual]: 'Individual',
  [tokens.page.entityEditPage.companyCorporation]: 'Company Corporation',
  [tokens.page.entityEditPage.trust]: 'Trust',
  [tokens.page.entityEditPage.partnership]: 'Partnership',
  [tokens.page.entityEditPage.soleProprietorshipSoleTrader]: 'Sole Proprietorship/Sole Trader',
  [tokens.page.entityEditPage.nonProfitOrganizationFoundation]: 'Non-Profit Organization/Foundation',
  [tokens.page.entityEditPage.coOperative]: 'Co-operative',
  [tokens.page.entityEditPage.overseasBranch]: 'Overseas Branch',
  [tokens.page.entityEditPage.active]: 'Active',
  [tokens.page.entityEditPage.dormant]: 'Dormant',
  [tokens.page.entityEditPage.suspended]: 'Suspended',
  [tokens.page.entityEditPage.administration]: 'Administration',
  [tokens.page.entityEditPage.receivership]: 'Receivership',
  [tokens.page.entityEditPage.liquidation]: 'Liquidation',
  [tokens.page.entityEditPage.deregistered]: 'Deregistered',
  [tokens.page.entityEditPage.politicallyExposedPerson]: 'Politically Exposed Person',
  [tokens.page.entityEditPage.personWithSignificantControl]: 'Person with Significant Control',
  [tokens.page.entityEditPage.personWithSignificantControlOptionYes]: 'Yes, Person with Significant Control (PSC)',
  [tokens.page.entityEditPage.personWithSignificantControlOptionNo]: 'Not a Person with Significant Control (PSC)',
  [tokens.page.entityEditPage.politicallyExposedPersonYes]: 'Yes, Politically Exposed Person (PEP)',
  [tokens.page.entityEditPage.politicallyExposedPersonNo]: 'Not a Politically Exposed Person (PEP)',
  [tokens.page.entityEditPage.entityTimezone]: 'Entity Timezone',
  [tokens.page.entityEditPage.entityTeamSize]: 'Entity Team Size',
  [tokens.page.entityEditPage.entityCurrency]: 'Entity Currency',
  [tokens.page.entityEditPage.functionalCurrency]: 'Functional Currency',
  [tokens.page.entityEditPage.presentationCurrency]: 'Presentation Currency',
  [tokens.page.entityEditPage.industry]: 'Industry',
  [tokens.page.entityEditPage.industryGroup]: 'Industry Group',
  [tokens.page.entityEditPage.industryDivision]: 'Industry Division',
  [tokens.page.entityEditPage.industrySubclass]: 'Industry Subclass',

  // file manager page
  [tokens.page.fileManager.fileManager]: 'File Manager',
  [tokens.page.fileManager.back]: 'Back',
  [tokens.page.fileManager.deleteFileTitle]: 'Delete file?',
  [tokens.page.fileManager.deleteFileBody]: 'Are you sure you want to delete this file?',
  [tokens.page.fileManager.cancel]: 'Cancel',
  [tokens.page.fileManager.delete]: 'Delete',
  [tokens.page.fileManager.search]: 'Search',
  [tokens.page.fileManager.sortBy]: 'Sort By',
  [tokens.page.fileManager.latest]: 'Latest',
  [tokens.page.fileManager.oldest]: 'Oldest',
  [tokens.page.fileManager.byFavorites]: 'By Favorites',
  [tokens.page.fileManager.rowPerPage]: 'Items per page',
  [tokens.page.fileManager.items]: 'items',
  [tokens.page.fileManager.updatedAt]: 'Updated at',
  [tokens.page.fileManager.copyLink]: 'Copy Link',

  // team member page
  [tokens.page.teamMembersPage.name]: 'Name',
  [tokens.page.teamMembersPage.localTime]: 'Local Time',
  [tokens.page.teamMembersPage.location]: 'Location',
  [tokens.page.teamMembersPage.department]: 'Department',
  [tokens.page.teamMembersPage.entity]: 'Entity',
  [tokens.page.teamMembersPage.actions]: 'Actions',
  [tokens.page.teamMembersPage.rowPerPage]: 'Row per page',
  [tokens.page.teamMembersPage.edit]: 'Edit',
  [tokens.page.teamMembersPage.email]: 'Email',
  [tokens.page.teamMembersPage.details]: 'Details',
  [tokens.page.teamMembersPage.confidential]: 'Confidential',
  [tokens.page.teamMembersPage.basicDetails]: 'Basic Details',
  [tokens.page.teamMembersPage.roleDetails]: 'Role Details',
  [tokens.page.teamMembersPage.locationDetails]: 'Location Details',
  [tokens.page.teamMembersPage.phone]: 'Phone',
  [tokens.page.teamMembersPage.country]: 'Country',
  [tokens.page.teamMembersPage.stateRegion]: 'State/Region',
  [tokens.page.teamMembersPage.streetAddress]: 'Street Address',
  [tokens.page.teamMembersPage.zipCode]: 'Zip Code',
  [tokens.page.teamMembersPage.jobTitle]: 'Job Title',
  [tokens.page.teamMembersPage.resetPassword]: 'Reset Password',
  [tokens.page.teamMembersPage.userId]: 'User-Id',
  [tokens.page.teamMembersPage.fullName]: 'Full Name',
  [tokens.page.teamMembersPage.departments]: 'Departments',
  [tokens.page.teamMembersPage.dateFormat]: 'Date Format',
  [tokens.page.teamMembersPage.preferredLanguage]: 'Preferred Language',
  [tokens.page.teamMembersPage.timeZone]: 'Time Zone',
  [tokens.page.teamMembersPage.city]: 'City',
  [tokens.page.teamMembersPage.teamMemberId]: 'Team Member Id',
  [tokens.page.teamMembersPage.update]: 'Update',
  [tokens.page.teamMembersPage.cancel]: 'Cancel',

  // file uploader modal
  [tokens.page.fileUploaderModal.uploadFile]: 'Upload File',
  [tokens.page.fileUploaderModal.fileTag]: 'file tag',
  [tokens.page.fileUploaderModal.remove]: 'Remove',
  [tokens.page.fileUploaderModal.removeAll]: 'Remove All',
  [tokens.page.fileUploaderModal.Uploading]: 'Uploading',
  [tokens.page.fileUploaderModal.upload]: 'Upload',
  [tokens.page.fileUploaderModal.clickToUpload]: 'Click to upload',
  [tokens.page.fileUploaderModal.dragAndDrop]: 'or drag and drop',
  [tokens.page.fileUploaderModal.maxFileSize]: 'Max file size is 20 MB',

  // calendar page
  [tokens.page.calendarPage.newEvent]: 'New Event',
  [tokens.page.calendarPage.view]: 'View',
  [tokens.page.calendarPage.viewOptions.month]: 'Month',
  [tokens.page.calendarPage.viewOptions.week]: 'Week',
  [tokens.page.calendarPage.viewOptions.day]: 'Day',
  [tokens.page.calendarPage.viewOptions.agenda]: 'Agenda',
  [tokens.page.calendarPage.months.january]: 'January',
  [tokens.page.calendarPage.months.february]: 'February',
  [tokens.page.calendarPage.months.march]: 'March',
  [tokens.page.calendarPage.months.april]: 'April',
  [tokens.page.calendarPage.months.may]: 'May',
  [tokens.page.calendarPage.months.june]: 'June',
  [tokens.page.calendarPage.months.july]: 'July',
  [tokens.page.calendarPage.months.august]: 'August',
  [tokens.page.calendarPage.months.september]: 'September',
  [tokens.page.calendarPage.months.october]: 'October',
  [tokens.page.calendarPage.months.november]: 'November',
  [tokens.page.calendarPage.months.december]: 'December',
  [tokens.page.calendarPage.updateConfirmationModal.title]: 'Update event?',
  [tokens.page.calendarPage.updateConfirmationModal.body]: `Are you sure you want to update this event's timing?`,
  [tokens.page.calendarPage.updateConfirmationModal.cancelText]: `Cancel`,
  [tokens.page.calendarPage.updateConfirmationModal.confirmText]: `Update`,
  [tokens.page.calendarPage.eventDialog.editEvent]: 'Edit Event',
  [tokens.page.calendarPage.eventDialog.addEvent]: 'Add Event',
  [tokens.page.calendarPage.eventDialog.title]: 'Title',
  [tokens.page.calendarPage.eventDialog.description]: 'Description',
  [tokens.page.calendarPage.eventDialog.entity]: 'Entity',
  [tokens.page.calendarPage.eventDialog.timeZone]: 'Time Zone',
  [tokens.page.calendarPage.eventDialog.startDate]: 'Start Date *',
  [tokens.page.calendarPage.eventDialog.endDate]: 'End Date *',
  [tokens.page.calendarPage.eventDialog.allDay]: 'All Day',
  [tokens.page.calendarPage.eventDialog.type]: 'Type',
  [tokens.page.calendarPage.eventDialog.regulatoryFilingDeadlineTitle]: 'Regulatory Filing Deadline',
  [tokens.page.calendarPage.eventDialog.regulatoryFilingDeadlineDescription]: 'Deadline date for submitting annual reports, tax filings, or compliance certifications.',
  [tokens.page.calendarPage.eventDialog.significantEntityMilestoneTitle]: 'Significant Entity Milestone',
  [tokens.page.calendarPage.eventDialog.significantEntityMilestoneDescription]: 'Fiscal period-end date, launch of a new product or service, or completion of a major project.',
  [tokens.page.calendarPage.eventDialog.otherImportantEventsTitle]: 'Other Important Events',
  [tokens.page.calendarPage.eventDialog.otherImportantEventsDescription]: `Events that don't fall into the other categories: employee birthdays, team meetings, or industry conferences.`,
  [tokens.page.calendarPage.eventDialog.cancel]: 'Cancel',
  [tokens.page.calendarPage.eventDialog.confirm]: 'Confirm',
  [tokens.page.calendarPage.yearPicker.buttonText]: 'Custom',
  [tokens.page.calendarPage.yearPicker.cancel]: 'Cancel',
  [tokens.page.calendarPage.yearPicker.ok]: 'Ok',

  [tokens.page.calendarPage.deleteModal.title]: 'Delete event?',
  [tokens.page.calendarPage.deleteModal.body]: `Are you sure you want to delete this event?`,
  [tokens.page.calendarPage.deleteModal.cancelText]: `Cancel`,
  [tokens.page.calendarPage.deleteModal.confirmText]: `Delete`,

  // group transaction list
  [tokens.page.groupTransactionList.headerTitle]: 'Eliminate distractions. Focus on your business.',
  [tokens.page.groupTransactionList.headerBody]: 'Managing interactions between entities in your group is critical for international tax company.',
  [tokens.page.groupTransactionList.addGroupTransaction]: 'Add Group Transaction',
  [tokens.page.groupTransactionList.itemsPerPage]: 'Items per page',
  [tokens.page.groupTransactionList.enterKeyword]: 'Enter a keyword',
  [tokens.page.groupTransactionList.noFiltersApplied]: 'No filters applied',
  [tokens.page.groupTransactionList.activeOnly]: 'Active only',
  [tokens.page.groupTransactionList.type]: 'Type',
  [tokens.page.groupTransactionList.entity]: 'Entity',
  [tokens.page.groupTransactionList.country]: 'Country',
  [tokens.page.groupTransactionList.attachment]: 'Attachment',
  [tokens.page.groupTransactionList.method]: 'Method',
  [tokens.page.groupTransactionList.year]: 'Year',
  [tokens.page.groupTransactionList.yes]: 'Yes',
  [tokens.page.groupTransactionList.no]: 'No',

  // group transaction add
  [tokens.page.groupTransactionAdd.addGroupTransaction]: 'Add Group Transaction',
  [tokens.page.groupTransactionAdd.groupTransactionAdded]: 'Group Transaction Added',
  [tokens.page.groupTransactionAdd.transactionTypeCapital]: 'TRANSACTION TYPE',
  [tokens.page.groupTransactionAdd.contractCapital]: 'CONTRACT',
  [tokens.page.groupTransactionAdd.entityCapital]: 'ENTITY',
  [tokens.page.groupTransactionAdd.selectMostSuitable]: 'Select most suitable',
  [tokens.page.groupTransactionAdd.continue]: 'Continue',
  [tokens.page.groupTransactionAdd.saleOfGoodsDescription]: 'When one Entity within the Group sells goods to another Entity within the Group.',
  [tokens.page.groupTransactionAdd.provisionOfServicesDescription]: 'When one Entity within the Group provides services to another Entity within the Group.',
  [tokens.page.groupTransactionAdd.licenseOfIPDescription]: 'When one Entity within the Group transfers intellectual property rights, such as patents, trademarks, copyrights, or proprietary technology, to another Entity within the Group.',
  [tokens.page.groupTransactionAdd.cashCapitalLoanDescription]: 'When one Entity within the Group provides cash, investment, or loans to another Entity within the Group.',
  [tokens.page.groupTransactionAdd.costSharingDescription]: 'When Group Entities collaborate on the research, development or acquisition of intangibles.',
  [tokens.page.groupTransactionAdd.leaseOfTengibleAssetsDescription]: 'When one Entity within the Group leases tangible assets (such as equipment or property) to another Entity within the Group.',
  [tokens.page.groupTransactionAdd.distributionResaleOfGoodsDescription]: 'When one entity within the Group sells goods to another Entity within the Group for distribution or resale.',
  [tokens.page.groupTransactionAdd.managementFeesDescription]: 'Charges for management services provided by one Entity within the Group to another Entity within the Group.',
  [tokens.page.groupTransactionAdd.uploadedFiles]: 'Uploaded files',
  [tokens.page.groupTransactionAdd.uploadFiles]: 'Upload files',
  [tokens.page.groupTransactionAdd.upload]: 'Upload',
  [tokens.page.groupTransactionAdd.back]: 'Back',
  [tokens.page.groupTransactionAdd.noAgreementAvailable]: 'No Agreement available for upload',
  [tokens.page.groupTransactionAdd.otherFile]: 'Other file',
  [tokens.page.groupTransactionAdd.maxFileSize]: 'Max file size',
  [tokens.page.groupTransactionAdd.view]: 'View',
  [tokens.page.groupTransactionAdd.enterEntity]: 'Enter an entity',
  [tokens.page.groupTransactionAdd.addTransaction]: 'Add Transaction',
  [tokens.page.groupTransactionAdd.allDone]: 'All done!',
  [tokens.page.groupTransactionAdd.previewOfAddedTransaction]: 'Here’s a preview of the Group Transaction you just added.',
  [tokens.page.groupTransactionAdd.addAnother]: 'Add another',

  // group transactions edit
  [tokens.page.groupTransactionEdit.groupTransactions]: 'Group transactions',
  [tokens.page.groupTransactionEdit.transactionNotFound]: 'Transaction not found',
  [tokens.page.groupTransactionEdit.transaction]: 'Transaction',
  [tokens.page.groupTransactionEdit.transactionId]: 'transaction-id',
  [tokens.page.groupTransactionEdit.details]: 'Details',
  [tokens.page.groupTransactionEdit.transactionType]: 'Transaction Type',
  [tokens.page.groupTransactionEdit.pricingMethod]: 'Pricing Method',
  [tokens.page.groupTransactionEdit.sendingEntity]: 'Sending Entity',
  [tokens.page.groupTransactionEdit.receivingEntity]: 'Receiving Entity',
  [tokens.page.groupTransactionEdit.effectiveDateOfTransaction]: 'Effective Date Of Transaction',
  [tokens.page.groupTransactionEdit.verified]: 'Verified',
  [tokens.page.groupTransactionEdit.active]: 'Active',
  [tokens.page.groupTransactionEdit.attachments]: 'Attachments',
  [tokens.page.groupTransactionEdit.uploadMore]: 'Upload more',
  [tokens.page.groupTransactionEdit.update]: 'Update',
  [tokens.page.groupTransactionEdit.cancel]: 'Cancel',
  [tokens.page.groupTransactionEdit.deleteAttachment]: 'Delete Attachment',
  [tokens.page.groupTransactionEdit.deleteAttachmentBody]: 'Are you sure you want to delete this attachment?',
  [tokens.page.groupTransactionEdit.delete]: 'Delete',
  [tokens.page.groupTransactionEdit.view]: 'View',

  // group transactions details
  [tokens.page.groupTransactionDetails.groupTransactions]: 'Group transactions',
  [tokens.page.groupTransactionDetails.pricingMethodology]: 'Pricing Methodology',
  [tokens.page.groupTransactionDetails.pricingMethodologyDescription]: 'Select the transfer pricing method for the controlled transaction. Either the Comparable Uncontrolled Price Method (CUP), the Resale Price Method (RPM), the Cost Plus Method (CPM), the Transactional Net Margin Method (TNMM), the Transactional Profit Split Method (TPSM), or the Profit Split Method (PSM).',
  [tokens.page.groupTransactionDetails.comparableTransactions]: 'Comparable Transactions',
  [tokens.page.groupTransactionDetails.comparableTransactionsDescription]: 'Information about comparable transactions or market data used to support the transfer pricing method selected. Including benchmarking studies, industry data, or other sources of comparable pricing information.',
  [tokens.page.groupTransactionDetails.functionalAnalysis]: 'Functional Analysis',
  [tokens.page.groupTransactionDetails.functionalAnalysisDescription]: 'The functions performed, risks assumed, and assets employed by each Entity in the controlled transaction. To determine whether the pricing of the transaction is consistent with the economic substance of the functions performed by each Entity.',
  [tokens.page.groupTransactionDetails.documentationRequirements]: 'Documentation Requirements',
  [tokens.page.groupTransactionDetails.documentationRequirementsDescription]: `Comprehensive documentation to support the arm's length nature of the controlled transaction and demonstrate compliance with regulatory requirements.`,
  [tokens.page.groupTransactionDetails.upload]: 'Upload',
  [tokens.page.groupTransactionDetails.otherFile]: 'or other file',
  [tokens.page.groupTransactionDetails.riskAssessment]: 'Risk Assessment',
  [tokens.page.groupTransactionDetails.riskAssessmentDescription]: 'Assess the transfer pricing risk associated with the transaction by evaluating the potential for transfer pricing audits, adjustments, penalties, or disputes with tax authorities and suggest appropriate measures to mitigate such risks.',
  [tokens.page.groupTransactionDetails.summary]: 'Summary',
  [tokens.page.groupTransactionDetails.method]: 'METHOD',
  [tokens.page.groupTransactionDetails.countries]: 'COUNTRIES',
  [tokens.page.groupTransactionDetails.transactionYear]: 'TRANSACTION YEAR',
  [tokens.page.groupTransactionDetails.entities]: 'ENTITIES',
  [tokens.page.groupTransactionDetails.transactionDetails]: 'Transaction Details',
  [tokens.page.groupTransactionDetails.detailsTab]: 'Details',
  [tokens.page.groupTransactionDetails.pricingMethodTab]: 'Pricing Method',
  [tokens.page.groupTransactionDetails.comparablesTab]: 'Comparables',
  [tokens.page.groupTransactionDetails.functionalTab]: 'Functional',
  [tokens.page.groupTransactionDetails.documentationTab]: 'Documentation',
  [tokens.page.groupTransactionDetails.riskTab]: 'Risk',
  [tokens.page.groupTransactionDetails.saleOfGoodsDetails1]: 'The specific nature of the goods being sold, the quantity or volume of goods exchanged, and any relevant terms or conditions of the sale.',
  [tokens.page.groupTransactionDetails.provisionOfServicesDetails1]: 'The specific nature of the services provided, the scope, duration, and complexity of the services rendered by the Service Provider to the Service Receiver. The pricing arrangement, payment terms, and any performance metrics or service-level agreements (SLAs).',
  [tokens.page.groupTransactionDetails.licenseOfIPDetails1]: 'The specific nature of the intellectual property being licensed, the type of intangible property (e.g., patents, trademarks, copyrights, trade secrets), its significance to the parties involved, and any unique characteristics that may affect its valuation. Including the scope of the license, the duration of the agreement, territorial restrictions, royalty rates, payment terms, and any other relevant provision.',
  [tokens.page.groupTransactionDetails.cashCapitalLoanDetails1]: 'The specific purpose of the cash transfer, capital contribution, or loan. Why the funds are being transferred and how they will be used by the Receiving Entity. This could include financing working capital needs, funding capital expenditures, or supporting specific business initiatives. Review the terms and conditions of the transaction such as the amount of funds transferred.',
  [tokens.page.groupTransactionDetails.cashCapitalLoanDetails2]: 'For Loans, the interest rate (if applicable), repayment schedules or terms, any collateral or security provided, and any other relevant provisions. This type of transfer is common when used to finance operations or help set up and expand into new markets.',
  [tokens.page.groupTransactionDetails.cashCapitalLoanDetails3]: 'For Capital Contributions, what was the number of stock/options of equity that was exchanged. This type of transfer is common when moving investment funds from a holding company to an operating subsidiary.',
  [tokens.page.groupTransactionDetails.costSharingDetails1]: 'The specific nature of the intangible property to be developed, enhanced, or maintained under the cost-sharing arrangement. Including the type of intangible property (e.g., patents, trademarks, copyrights, trade secrets), its significance to the Participants, and any unique characteristics that may affect its valuation. Clarify the scope and objectives of the cost-sharing arrangement, including the specific activities/contributions to be undertaken by each Participant.',
  [tokens.page.groupTransactionDetails.costSharingDetails2]: 'What are the resources, expertise, and other contributions provided by each Participant, as well as the basis for allocating costs, risks, and benefits among the participants. As well as the rights and obligations of the participants, and any dispute resolution mechanisms.',
  [tokens.page.groupTransactionDetails.costSharingDetails3]: 'The expected duration of the arrangement, and the anticipated benefits to be derived from the development, enhancement, or maintenance of the intangible property.',
  [tokens.page.groupTransactionDetails.leaseOfTengibleAssetsDetails1]: 'The specific nature of the tangible assets being leased. Including the type of assets (e.g., equipment, machinery, vehicles, real estate), their condition, usage, and any unique characteristics that may affect their valuation. As well as the duration of the lease, rental payments, renewal options, maintenance responsibilities, and any other relevant provisions.',
  [tokens.page.groupTransactionDetails.distributionResaleOfGoodsDetails1]: 'The specific nature of the goods being distributed or resold such as the type of goods (e.g., finished products, raw materials, components), their characteristics, quality, and any unique features that may affect their valuation. Including the scope of the agreement, pricing arrangements, payment terms, delivery terms, territorial restrictions, and any other relevant provisions.',
  [tokens.page.groupTransactionDetails.managementFeesDetails1]: 'The specific nature of the management services being provided, such as the scope of services, the level of expertise required, and any unique characteristics that may affect the valuation of the services. Including the scope of services, pricing arrangements, payment terms, performance metrics, and any other relevant provisions.',
  
  // message constants from backend
  [tokens.message.verifyEmailFirst]: 'You need to verify your email address first',
  [tokens.message.alreadyInvited]: 'Employee has a pending invite from company:',
  [tokens.message.employeeAlreadyExist]: 'Already exist in the company:',
  [tokens.message.invalidCredentials]: 'Invalid credentials',
  [tokens.message.internalServerError]: 'Internal server error',
  [tokens.message.notFound]: 'Not found',
  [tokens.message.alreadyExist]: 'Already exist',
  [tokens.message.savedSuccesfully]: 'Saved successfully',
  [tokens.message.trialPeriodExpired]: 'Trial period expired',
  [tokens.message.subscriptionCreated]: 'Subscription created',
  [tokens.message.paymentSuccesful]: 'Payment successful!',
  [tokens.message.companyRegistrationSuccessful]: 'Company registration successful!',
  [tokens.message.invalidOTP]: 'Invalid OTP',
  [tokens.message.unsubscribed]: 'You did not purchase our plan',
  [tokens.message.emailSent]: 'Email sent successfully',
  [tokens.message.emailNotSent]: 'Email could not be sent',
  [tokens.message.validToken]: 'Token is valid',
  [tokens.message.inavlidToken]: 'Token is invalid',
  [tokens.message.tokenValidationFailed]: 'Authentication failed',
  [tokens.message.notAuthrized]: 'Not authorized',
  [tokens.message.updatedSuccesfully]: 'Updated successfully',
  [tokens.message.companyNotFound]: 'Company not found',
  [tokens.message.currentPasswordIsWrong]: 'Current password is wrong',
  [tokens.message.loggedOutSuccessfully]: 'Signed out successfully',
  
  // from frontend
  [tokens.message.requiredFieldsEmpty]: 'Fill up all the required fields',
  [tokens.message.removedSuccessfully]: 'Removed successfully',
  [tokens.message.invitationRevokedSuccessfully]: 'Invitation revoked successfully',
  [tokens.message.resetPasswordLinkSent]: 'Reset password link sent to your email',
  [tokens.message.passwordAndConfirmPassMustMatch]: 'Password and confirm password must match',
  [tokens.message.passwordTooShort]: 'Password should be atleast of length 7',
  [tokens.message.passwordUpdated]: 'Password updated successfully',
  [tokens.message.otpSentToEmail]: 'OTP sent to your email',
  [tokens.message.photoUpdatedSuccessfully]: 'Photo updated successfully',
  [tokens.message.employeeRoleUpdatedSuccessfully]: 'Employee\'s role updated successfully',
  [tokens.message.companyNameUpdatedSuccessfully]: 'Company name updated successfully',
  [tokens.message.nameUpdatedSuccessfully]: 'Name updated successfully',
  [tokens.message.somethingWentWrong]: 'Something went wrong',
  [tokens.message.countryFieldRequired]: 'Country is required',
  [tokens.message.entityNameFieldRequired]: 'Entity name is required',
  [tokens.message.entityCategoryFieldRequired]: 'Category is required',
  [tokens.message.entityUpdated]: 'Entity updated',
  [tokens.message.officeHolderCreated]: 'Custom ofiice holder type created succesfully',
  [tokens.message.entityDeleted]: 'Entity deleted successfully',
  [tokens.message.departmentCreated]: 'Department created succesfully',
  [tokens.message.teamMemberUpdated]: 'Team member updated',

  [tokens.message.transactionTypeRequired]: 'Transaction type is required',
  [tokens.message.contractTypeRequired]: 'Contract type is required',
  [tokens.message.contractFileAndFileTagRequired]: 'Either upload a file and file tag or choose \'No argument available to upload\'',
  [tokens.message.entityFieldRequired]: 'Entity not selected',
  [tokens.message.transactionDeleted]: 'Transaction deleted',

  [tokens.message.groupTransactionUpdatedSuccessfully]: 'Group transaction updated succesfully',

  [tokens.message.eventCreated]: 'Event created!',
  [tokens.message.eventUpdated]: 'Event updated!',
  [tokens.message.eventDeleted]: 'Event deleted!',
};

// export const ALREADY_INVITED = 'employee has a pending invite from company: ';
// export const EMPLOYEE_ALREADY_EXIST = 'Already exist in the company: ';
import type { FC } from 'react';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import CreditCard01Icon from '@untitled-ui/icons-react/build/esm/CreditCard01';
import User03Icon from '@untitled-ui/icons-react/build/esm/User03';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';

import { RouterLink } from 'src/components/router-link';
import { useAuth } from 'src/hooks/use-auth';
import { useRouter } from 'src/hooks/use-router';
import { paths } from 'src/paths';
import { Issuer } from 'src/utils/auth';
import { Employee } from 'src/interfaces/employee.interface';
import { useNavigate } from 'react-router';
import { tokens } from 'src/locales/tokens';
import { useTranslation } from 'react-i18next';

interface AccountPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
  employee: Employee;
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const { anchorEl, onClose, open, employee, ...other } = props;
  const router = useRouter();
  const auth = useAuth();

  const navigate = useNavigate();

  const handleLogout = useCallback(
    async (): Promise<void> => {
      try {
        onClose?.();

        switch (auth.issuer) {
          case Issuer.JWT: {
            await auth.signOut();
            break;
          }

          default: {
            console.warn('Using an unknown Auth Issuer, did not log out');
          }
        }

      } catch (err) {
        console.error(err);
        toast.error('Something went wrong!');
      }
    },
    [auth, router, onClose]
  );

  const { t } = useTranslation();

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom'
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 200 } }}
      {...other}
    >
      <Box sx={{ p: 2 }}>
        <Typography variant="body1">
          {employee.fullName}
        </Typography>
        <Typography
          color="text.secondary"
          variant="body2"
          sx={{textOverflow: "ellipsis", overflow: 'hidden', whiteSpace: "nowrap"}}
        >
          {employee.email}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 1 }}>
        <ListItemButton
          component={RouterLink}
          href={paths.profile}
          onClick={onClose}
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5
          }}
        >
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <User03Icon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={(
              <Typography variant="body1">
                {t(tokens.nav.profile)}
              </Typography>
            )}
          />
        </ListItemButton>
        {/* <ListItemButton
          component={RouterLink}
          href={paths.dashboard.account}
          onClick={onClose}
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5
          }}
        >
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <Settings04Icon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={(
              <Typography variant="body1">
                Settings
              </Typography>
            )}
          />
        </ListItemButton> */}
        {employee.isSuperAdmin && (
          <ListItemButton            
            onClick={() => {
              onClose?.()
              navigate(paths.dashboard.account, { state : { tab: 'billing' }})
            }}
            sx={{
              borderRadius: 1,
              px: 1,
              py: 0.5
            }}
          >
            <ListItemIcon>
              <SvgIcon fontSize="small">
                <CreditCard01Icon />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography variant="body1">
                  {t(tokens.nav.billing)}
                </Typography>
              )}
            />
          </ListItemButton>
        )}
      </Box>
      <Divider sx={{ my: '0 !important' }} />
      <Box
        sx={{
          display: 'flex',
          p: 1,
          justifyContent: 'center'
        }}
      >
        <Button
          color="inherit"
          onClick={handleLogout}
          size="small"
        >
          {t(tokens.nav.logout)}
        </Button>
      </Box>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

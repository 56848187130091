import { lazy, Suspense } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';

const TermsPage = lazy(() => import('src/pages/policy/terms'));
const CookiePolicyPage = lazy(() => import('src/pages/policy/cookie-policy'));
const PrivacyPage = lazy(() => import('src/pages/policy/privacy'));

export const policyRoutes: RouteObject[] = [
  {
    path: 'legal',
    element: (
        <Suspense>
          <Outlet />
        </Suspense>
    ),
    children: [
      {
        path: 'terms',
        element: <TermsPage />
      },
      {
        path: 'cookie-policy',
        element: <CookiePolicyPage />
      },
      {
        path: 'privacy',
        element: <PrivacyPage />
      },
    ]
  }
];

import type { AnyAction, PayloadAction} from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'src/store';
import { ThunkAction } from 'redux-thunk';
import { DEPARTMENT } from 'src/endpoints';
import { authRequestHandler } from 'src/utils/fetch';
import { NavigateFunction } from 'react-router';

interface DepartmentsState {
    departments :any
}

const initialState: DepartmentsState = {
    departments:[]
};

export const slice = createSlice({
  name: 'department',
  initialState,
  reducers : {
    setDepartments(state: DepartmentsState, action: PayloadAction<DepartmentsState>): void {
        state.departments = action.payload
    }
  }
});
type AsyncAction = ThunkAction<Promise<void>, RootState, undefined, AnyAction>;

export const getDepartments = (companyId: string, navigate: NavigateFunction): AsyncAction => async (dispatch: AppDispatch): Promise<void> => {
  const { response, resJson } = await authRequestHandler({
    method: 'GET',
    url: `${DEPARTMENT.LIST}/${companyId}`,
    navigate,
  });
  if(response.ok){
    dispatch(slice.actions.setDepartments(resJson?.data));
  }
  };
  

export const { reducer } = slice;

import { tokens } from "../tokens";

export const it = {
[tokens.common.languageChanged]: 'You have changed the application language to english',
[tokens.nav.groupTransactions]: 'Transazioni di Gruppo',
[tokens.nav.academy]: 'Accademia',
[tokens.nav.modules]: 'Moduli',
[tokens.nav.account]: 'Account',
[tokens.nav.analytics]: 'Analitiche',
[tokens.nav.auth]: 'Autenticazione',
[tokens.nav.blog]: 'Blog',
[tokens.nav.browse]: 'Esplora',
[tokens.nav.calendar]: 'Calendario',
[tokens.nav.chat]: 'Chat',
[tokens.nav.checkout]: 'Check-out',
[tokens.nav.concepts]: 'Concetti',
[tokens.nav.contact]: 'Contatto',
[tokens.nav.course]: 'Corso',
[tokens.nav.create]: 'Crea',
[tokens.nav.crypto]: 'Cripto',
[tokens.nav.customers]: 'Clienti',
[tokens.nav.teamMembers]: 'Membri del Team',
[tokens.nav.entities]: 'Entità',
[tokens.nav.dashboard]: 'Dashboard',
[tokens.nav.details]: 'Dettagli',
[tokens.nav.ecommerce]: 'E-commerce',
[tokens.nav.edit]: 'Modifica',
[tokens.nav.error]: 'Errore',
[tokens.nav.feed]: 'Feed',
[tokens.nav.fileManager]: 'Gestore File',
[tokens.nav.finance]: 'Finanza',
[tokens.nav.fleet]: 'Flotta',
[tokens.nav.forgotPassword]: 'Password dimenticata',
[tokens.nav.invoiceList]: 'Fatture',
[tokens.nav.jobList]: 'Elenco Lavori',
[tokens.nav.kanban]: 'Kanban',
[tokens.nav.list]: 'Lista',
[tokens.nav.login]: 'Accesso',
[tokens.nav.logistics]: 'Logistica',
[tokens.nav.mail]: 'Posta',
[tokens.nav.management]: 'Gestione',
[tokens.nav.orderList]: 'Ordini',
[tokens.nav.overview]: 'Panoramica',
[tokens.nav.pages]: 'Pagine',
[tokens.nav.postCreate]: 'Crea Post',
[tokens.nav.postDetails]: 'Dettagli Post',
[tokens.nav.postList]: 'Elenco Post',
[tokens.nav.pricing]: 'Prezzi',
[tokens.nav.productList]: 'Prodotti',
[tokens.nav.profile]: 'Profilo',
[tokens.nav.billing]: 'Fatturazione',
[tokens.nav.logout]: 'Logout',
[tokens.nav.register]: 'Registrati',
[tokens.nav.resetPassword]: 'Ripristina Password',
[tokens.nav.socialMedia]: 'Social Media',
[tokens.nav.verifyCode]: 'Verifica Codice',
[tokens.nav.viewEntities]: 'Visualizza Entità',
[tokens.nav.addEntity]: 'Aggiungi Entità',
[tokens.nav.viewTeamMembers]: 'Visualizza Membri del Team',
[tokens.nav.yourProfile]: 'Il Tuo Profilo',
[tokens.nav.editYourProfile]: 'Modifica Il Tuo Profilo',
[tokens.nav.viewTransactions]: 'Visualizza Transazioni di Gruppo',
[tokens.nav.addTransaction]: 'Aggiungi Transazione di Gruppo',
[tokens.nav.newEvent]: 'Nuovo Evento',
[tokens.nav.agenda]: 'Agenda',

// Page Content
[tokens.page.back]: 'Indietro',
[tokens.page.dashboard]: 'Dashboard',
[tokens.page.teamMembers]: 'Membri del Team',
[tokens.page.search]: 'Ricerca',

// Overview Page
[tokens.page.overview.overview]: 'Panoramica',
[tokens.page.overview.entities]: 'Entità di Gruppo',
[tokens.page.overview.teamMembers]: 'Membri del Team',
[tokens.page.overview.groupTransactions]: 'Transazioni di Gruppo',
[tokens.page.overview.seeAllEntities]: 'Vedi tutte le Entità',
[tokens.page.overview.seeAllTeamMembers]: 'Vedi tutti i Membri del Team',
[tokens.page.overview.seeAllGroupTransactions]: 'Vedi tutte le Transazioni di Gruppo',
[tokens.page.overview.welcome]: 'Benvenuto',
[tokens.page.overview.startingWithOperatingPRO]: 'Iniziare con Operating PRO',
[tokens.page.overview.startingWithOperatingPROBody]: 'Essere guidati attraverso un approccio proattivo alla conformità interaziendale. Risparmiando tempo, denaro e potenziali mal di testa in futuro.',
[tokens.page.overview.inviteTeamMemberTitle]: 'Invita un Membro del Team',
[tokens.page.overview.inviteTeamMemberBody]: 'Gli amministratori possono concedere agli utenti invitati controlli di accesso e delegare specifici compiti da completare. Revisiona il loro lavoro utilizzando flussi di approvazione.',
[tokens.page.overview.verifyYourEmailTitle]: 'Verifica la tua email',
[tokens.page.overview.verifyYourEmailBody]: `Per garantire l'integrità dei nostri sistemi di posta elettronica, ti verrà chiesto di verificare la tua email la prima volta che inviti un membro del team.`,
[tokens.page.overview.addYourFirstEntityTitle]: 'Aggiungi la tua prima Entità',
[tokens.page.overview.addYourFirstEntityBody]: `Inizia a costruire la struttura del tuo gruppo aggiungendo la tua prima entità, che sia la tua Holding Company o una filiale all'estero.`,
[tokens.page.overview.addYourSecondEntityTitle]: 'Aggiungi la tua seconda Entità',
[tokens.page.overview.addYourSecondEntityBody]: 'Le entità nel tuo Gruppo possono assumere la forma di Azienda, Trust, Partnership, Imprenditore Individuale, Non-profit, Co-op o Filiale.',
[tokens.page.overview.addIntercompanyGroupTransactionTitle]: 'Aggiungi una Transazione di Gruppo Interaziendale',
[tokens.page.overview.addIntercompanyGroupTransactionBody]: 'Documenta le transazioni tra le tue entità utilizzando il modulo Transazioni di Gruppo.',
[tokens.page.overview.upgrade]: 'Aggiorna',
[tokens.page.overview.upgradeYourAccountTitle]: 'Aggiorna il tuo account.',
[tokens.page.overview.upgradeYourAccountBody]: 'Sblocca funzionalità esclusive CbCR, File Locali di Pricing di Trasferimento e Regolazioni di Fine Anno.',
[tokens.page.overview.supportCenter]: 'Centro di Supporto',
[tokens.page.overview.needHelpFiguringThingsOut]: 'Hai bisogno di aiuto per capire le cose?',
[tokens.page.overview.needHelpFiguringThingsOutBody]: 'Incerto o hai domande? Il nostro team di esperti è qui per supportarti. Contattaci per ricevere assistenza.',
[tokens.page.overview.support]: 'Supporto',
[tokens.page.overview.upcomingEvents]: 'Eventi in arrivo',
[tokens.page.overview.basedOnCalendarModule]: 'Basato sul modulo Calendario',
[tokens.page.overview.seeAll]: 'Vedi tutti',
[tokens.page.overview.noUpcomingEvents]: 'Nessun evento imminente, aggiungi nuovo evento',
[tokens.page.overview.newEvent]: 'Nuovo Evento',

// Account Page
[tokens.page.accountPage.account]: 'Account',
[tokens.page.accountPage.generalTab]: 'Generale',
[tokens.page.accountPage.billingTab]: 'Fatturazione',
[tokens.page.accountPage.notificationsTab]: 'Notifiche',
[tokens.page.accountPage.teamTab]: 'Team',
[tokens.page.accountPage.securityTab]: 'Sicurezza',
[tokens.page.accountPage.basicDetails]: 'Dettagli di Base',
[tokens.page.accountPage.select]: 'Seleziona',
[tokens.page.accountPage.companyName]: 'Nome Azienda',
[tokens.page.accountPage.save]: 'Salva',
[tokens.page.accountPage.fullName]: 'Nome Completo',
[tokens.page.accountPage.emailAddress]: 'Indirizzo Email',
[tokens.page.accountPage.roleDetails]: 'Dettagli Ruolo',
[tokens.page.accountPage.jobTitle]: 'Titolo di Lavoro',
[tokens.page.accountPage.departments]: 'Dipartimenti',
[tokens.page.accountPage.entity]: 'Entità',
[tokens.page.accountPage.locationDetails]: 'Dettagli Posizione',
[tokens.page.accountPage.basedIn]: 'Basato A',
[tokens.page.accountPage.localTime]: 'Ora Locale',
[tokens.page.accountPage.preferredLanguage]: 'Lingua Preferita',
[tokens.page.accountPage.changePlan]: 'Cambia Piano',
[tokens.page.accountPage.changePlanSubHeader]: 'Aggiorna quando sei pronto',
[tokens.page.accountPage.year]: 'anno',
[tokens.page.accountPage.usingNow]: 'Utilizzando ora',
[tokens.page.accountPage.billingDetails]: 'Dettagli Fatturazione',
[tokens.page.accountPage.cardNumber]: 'Numero di Carta',
[tokens.page.accountPage.name]: 'Nome',
[tokens.page.accountPage.notRequiredForTrialPeriod]: 'Non richiesto per il periodo di prova',
[tokens.page.accountPage.notProvided]: 'Non fornito',
[tokens.page.accountPage.trialEndDate]: 'Data di Fine Prova',
[tokens.page.accountPage.upgradePlan]: 'Piano di Aggiornamento',
[tokens.page.accountPage.autoRenewText1]: `Il tuo abbonamento annuale si rinnova automaticamente per comodità, ma sei tu a controllare`,
[tokens.page.accountPage.autoRenewText2]: 'Annulla il Rinnovo Automatico',
[tokens.page.accountPage.autoRenewText3]: 'in qualsiasi momento.',
[tokens.page.accountPage.inviteTeamMembers]: 'Invita membri del team',
[tokens.page.accountPage.inviteUpTo50TeamMembers]: 'Puoi invitare fino a 50 membri del team.',
[tokens.page.accountPage.inviteUpTo150TeamMembers]: 'Puoi invitare fino a 150 membri del team.',
[tokens.page.accountPage.sendInvite]: 'Invia Invito',
[tokens.page.accountPage.searchTeamMembers]: 'Cerca membri del team',
[tokens.page.accountPage.sortBy]: 'Ordina Per',
[tokens.page.accountPage.oldest]: 'Più Vecchi',
[tokens.page.accountPage.newest]: 'Più Recenti',
[tokens.page.accountPage.teamMembers]: 'Membri del Team',
[tokens.page.accountPage.location]: 'Posizione',
[tokens.page.accountPage.role]: 'Ruolo',
[tokens.page.accountPage.status]: 'Stato',
[tokens.page.accountPage.actions]: 'Azioni',
[tokens.page.accountPage.changeRole]: 'Cambia Ruolo',
[tokens.page.accountPage.remove]: 'Rimuovi',
[tokens.page.accountPage.revokeInvite]: 'Revoca Invito',
[tokens.page.accountPage.changePassword]: 'Cambia Password',
[tokens.page.accountPage.currentPassword]: 'Password Corrente',
[tokens.page.accountPage.newPassword]: 'Nuova Password',
[tokens.page.accountPage.newConfirmPassword]: 'Conferma Nuova Password',
[tokens.page.accountPage.password]: 'Password',
[tokens.page.accountPage.cancel]: 'Annulla',
[tokens.page.accountPage.edit]: 'Modifica',
[tokens.page.accountPage.deleteAccount]: 'Elimina Account',
[tokens.page.accountPage.deleteAccountSubTitle]: 'Elimina il tuo account e tutti i tuoi dati. Questa operazione è irreversibile.',
[tokens.page.accountPage.loginHistory]: 'Cronologia Accessi',
[tokens.page.accountPage.loginHistorySubtitle]: 'La tua attività di accesso recente',
[tokens.page.accountPage.deviceTime]: 'Dispositivo e Ora',
[tokens.page.accountPage.IPAddress]: 'Indirizzo IP',
[tokens.page.accountPage.email]: 'Email',
[tokens.page.accountPage.productUpdates]: 'Aggiornamenti Prodotto',
[tokens.page.accountPage.newsAnnouncementsAndProductUpdates]: 'Notizie, annunci e aggiornamenti del prodotto.',
[tokens.page.accountPage.securityUpdates]: 'Aggiornamenti Sicurezza',
[tokens.page.accountPage.NotificationsAboutAccountSecurity]: 'Notifiche importanti sulla sicurezza del tuo account.',
[tokens.page.accountPage.phoneNotifications]: 'Notifiche Telefono',
[tokens.page.accountPage.removeEmployeeTitle]: 'Rimuovi Dipendente',
[tokens.page.accountPage.removeEmployeeBody]: 'Sei sicuro di voler eliminare questo dipendente dalla tua azienda?',
[tokens.page.accountPage.revokeEmployeeTitle]: 'Revoca Invito Dipendente',
[tokens.page.accountPage.revokeEmployeeBody]: `Sei sicuro di voler revocare l'invito di questo dipendente dalla tua azienda?`,
[tokens.page.accountPage.revoke]: `Revoca`,


// entity list page
[tokens.page.entityListPage.entities]: 'Entità',
[tokens.page.entityListPage.export]: 'Esporta',
[tokens.page.entityListPage.add]: 'Aggiungi',
[tokens.page.entityListPage.all]: 'Tutti',
[tokens.page.entityListPage.searchEntities]: 'Cerca Entità',
[tokens.page.entityListPage.sortBy]: 'Ordina Per',
[tokens.page.entityListPage.newest]: 'Più Recenti',
[tokens.page.entityListPage.oldest]: 'Più Vecchi',
[tokens.page.entityListPage.delete]: 'Elimina',
[tokens.page.entityListPage.edit]: 'Modifica',
[tokens.page.entityListPage.title]: 'Titolo',
[tokens.page.entityListPage.location]: 'Posizione',
[tokens.page.entityListPage.reportingDate]: 'Data di Segnalazione',
[tokens.page.entityListPage.status]: 'Stato',
[tokens.page.entityListPage.actions]: 'Azioni',
[tokens.page.entityListPage.rowPerPage]: 'Righe per pagina',

// entity add page
[tokens.page.entityAddPage.addEntity]: 'Aggiungi Entità',
[tokens.page.entityAddPage.entityAdded]: 'Entità Aggiunta',
[tokens.page.entityAddPage.type]: 'Tipo',
[tokens.page.entityAddPage.country]: 'Paese',
[tokens.page.entityAddPage.registration]: 'Registrazione',
[tokens.page.entityAddPage.selectMostSuitable]: 'Seleziona il più adatto',
[tokens.page.entityAddPage.continue]: 'Continua',
[tokens.page.entityAddPage.companyCorporation]: 'Società/Corporazione',
[tokens.page.entityAddPage.trust]: 'Fiducia',
[tokens.page.entityAddPage.partnership]: 'Partenariato',
[tokens.page.entityAddPage.soleProprietorshipSoleTrader]: 'Impresa Individuale/Commerciante Individuale',
[tokens.page.entityAddPage.nonProfitOrganizationFoundation]: 'Organizzazione Non Profit/Fondazione',
[tokens.page.entityAddPage.coOperative]: 'Cooperativa',
[tokens.page.entityAddPage.overseasBranch]: 'Filiale Estera',
[tokens.page.entityAddPage.whichCountryWasItEstablishedIn]: 'In quale paese è stata costituita?',
[tokens.page.entityAddPage.enterCountry]: 'Inserisci un Paese',
[tokens.page.entityAddPage.selectedCountry]: 'Paese Selezionato',
[tokens.page.entityAddPage.back]: 'Indietro',
[tokens.page.entityAddPage.whatIsFullLegalNameOfEntity]: 'Qual è il nome legale completo dell\'entità?',
[tokens.page.entityAddPage.entityName]: 'Nome Entità',
[tokens.page.entityAddPage.entityNumber]: 'Numero Entità',
[tokens.page.entityAddPage.whatWasOfficialDateOfRegistration]: 'Qual è stata la data ufficiale di registrazione?',
[tokens.page.entityAddPage.registrationDate]: 'Data di Registrazione',
[tokens.page.entityAddPage.createEntity]: 'Crea Entità',
[tokens.page.entityAddPage.allDone]: 'Tutto fatto!',
[tokens.page.entityAddPage.previewOfEntity]: 'Ecco un\'anteprima dell\'entità appena aggiunta.',
[tokens.page.entityAddPage.view]: 'Visualizza',
[tokens.page.entityAddPage.addAnotherEntity]: 'Aggiungi un\'altra entità',

// entity details page
[tokens.page.entityDetailsPage.entities]: 'Entità',
[tokens.page.entityDetailsPage.entityId]: 'ID Entità',
[tokens.page.entityDetailsPage.edit]: 'Modifica',
[tokens.page.entityDetailsPage.name]: 'Nome',
[tokens.page.entityDetailsPage.entityNumber]: 'Numero Entità',
[tokens.page.entityDetailsPage.entityType]: 'Tipo Entità',
[tokens.page.entityDetailsPage.status]: 'Stato',
[tokens.page.entityDetailsPage.registrationDate]: 'Data di Registrazione',
[tokens.page.entityDetailsPage.yearlyReportingDate]: 'Data di Segnalazione Annuale',
[tokens.page.entityDetailsPage.locationDetails]: 'Dettagli Posizione',
[tokens.page.entityDetailsPage.country]: 'Paese',
[tokens.page.entityDetailsPage.stateRegion]: 'Stato/Regione',
[tokens.page.entityDetailsPage.licenceType]: 'Tipo Licenza',
[tokens.page.entityDetailsPage.licence]: 'Licenza',
[tokens.page.entityDetailsPage.licenceNumber]: 'Numero Licenza',
[tokens.page.entityDetailsPage.regulatoryBody]: 'Organo Regolatorio',
[tokens.page.entityDetailsPage.state]: 'Stato',
[tokens.page.entityDetailsPage.officeHolder]: 'Titolare dell\'Ufficio',
[tokens.page.entityDetailsPage.type]: 'Tipo',
[tokens.page.entityDetailsPage.shareholder]: 'Azionista',
[tokens.page.entityDetailsPage.numberOfSharesHeld]: 'Numero di Azioni Detenute',
[tokens.page.entityDetailsPage.countryOfResidence]: 'Paese di Residenza',
[tokens.page.entityDetailsPage.passport]: 'Passaporto',
[tokens.page.entityDetailsPage.politicallyExposedPerson]: 'Persona Politicamente Esposta',
[tokens.page.entityDetailsPage.personWithSignificantControl]: 'Persona con Controllo Significativo',
[tokens.page.entityDetailsPage.personWithSignificantControlOptionYes]: 'Sì, Persona con Controllo Significativo (PSC)',
[tokens.page.entityDetailsPage.personWithSignificantControlOptionNo]: 'Non una Persona con Controllo Significativo (PSC)',
[tokens.page.entityDetailsPage.politicallyExposedPersonYes]: 'Sì, Persona Politicamente Esposta (PEP)',
[tokens.page.entityDetailsPage.politicallyExposedPersonNo]: 'Non una Persona Politicamente Esposta (PEP)',

// entity edit page
[tokens.page.entityEditPage.entities]: 'Entità',
[tokens.page.entityEditPage.select]: 'Seleziona',
[tokens.page.entityEditPage.entityId]: 'ID Entità',
[tokens.page.entityEditPage.basicDetails]: 'Dettagli Base',
[tokens.page.entityEditPage.name]: 'Nome',
[tokens.page.entityEditPage.entityNumber]: 'Numero Entità',
[tokens.page.entityEditPage.country]: 'Paese',
[tokens.page.entityEditPage.state]: 'Stato',
[tokens.page.entityEditPage.typeStatus]: 'Tipo & Stato',
[tokens.page.entityEditPage.entityType]: 'Tipo Entità',
[tokens.page.entityEditPage.entityStatus]: 'Stato Entità',
[tokens.page.entityEditPage.registrationReportingDate]: 'Data di Registrazione & Data di Segnalazione',
[tokens.page.entityEditPage.registrationDate]: 'Data di Registrazione',
[tokens.page.entityEditPage.yearlyReportingDate]: 'Data di Segnalazione Annuale',
[tokens.page.entityEditPage.officeHolders]: 'Titolari dell\'Ufficio',
[tokens.page.entityEditPage.shareholders]: 'Azionisti',
[tokens.page.entityEditPage.licence]: 'Licenza(e)',
[tokens.page.entityEditPage.update]: 'Aggiorna',
[tokens.page.entityEditPage.cancel]: 'Annulla',
[tokens.page.entityEditPage.licenceType]: 'Tipo Licenza',
[tokens.page.entityEditPage.licenceNumber]: 'Numero Licenza',
[tokens.page.entityEditPage.regulatoryBody]: 'Organo Regolatorio',
[tokens.page.entityEditPage.add]: 'Aggiungi',
[tokens.page.entityEditPage.officeHolderType]: 'Tipo Titolare dell\'Ufficio',
[tokens.page.entityEditPage.addCustom]: 'Aggiungi Personalizzato',
[tokens.page.entityEditPage.shareHolderType]: 'Tipo Azionista',
[tokens.page.entityEditPage.numberOfSharesHeld]: 'Numero di Azioni Detenute',
[tokens.page.entityEditPage.countryOfResidence]: 'Paese di Residenza',
[tokens.page.entityEditPage.individual]: 'Individuale',
[tokens.page.entityEditPage.companyCorporation]: 'Società Corporation',
[tokens.page.entityEditPage.trust]: 'Fiducia',
[tokens.page.entityEditPage.partnership]: 'Partenariato',
[tokens.page.entityEditPage.soleProprietorshipSoleTrader]: 'Impresa Individuale/Commerciante Individuale',
[tokens.page.entityEditPage.nonProfitOrganizationFoundation]: 'Organizzazione Non Profit/Fondazione',
[tokens.page.entityEditPage.coOperative]: 'Cooperativa',
[tokens.page.entityEditPage.overseasBranch]: 'Filiale Estera',
[tokens.page.entityEditPage.active]: 'Attivo',
[tokens.page.entityEditPage.dormant]: 'Dormiente',
[tokens.page.entityEditPage.suspended]: 'Sospeso',
[tokens.page.entityEditPage.administration]: 'Amministrazione',
[tokens.page.entityEditPage.receivership]: 'Amministrazione Giudiziaria',
[tokens.page.entityEditPage.liquidation]: 'Liquidazione',
[tokens.page.entityEditPage.deregistered]: 'Deregistrato',
[tokens.page.entityEditPage.politicallyExposedPerson]: 'Persona Politicamente Esposta',
[tokens.page.entityEditPage.personWithSignificantControl]: 'Persona con Controllo Significativo',
[tokens.page.entityEditPage.personWithSignificantControlOptionYes]: 'Sì, Persona con Controllo Significativo (PSC)',
[tokens.page.entityEditPage.personWithSignificantControlOptionNo]: 'Non una Persona con Controllo Significativo (PSC)',
[tokens.page.entityEditPage.politicallyExposedPersonYes]: 'Sì, Persona Politicamente Esposta (PEP)',
[tokens.page.entityEditPage.politicallyExposedPersonNo]: 'Non una Persona Politicamente Esposta (PEP)',
[tokens.page.entityEditPage.entityTimezone]: 'Fuso Orario Entità',
[tokens.page.entityEditPage.entityTeamSize]: 'Dimensione del Team dell\'Entità',
[tokens.page.entityEditPage.entityCurrency]: 'Valuta dell\'Entità',
[tokens.page.entityEditPage.functionalCurrency]: 'Valuta Funzionale',
[tokens.page.entityEditPage.presentationCurrency]: 'Valuta di Presentazione',
[tokens.page.entityEditPage.industry]: 'Settore Industriale',
[tokens.page.entityEditPage.industryGroup]: 'Gruppo Industriale',
[tokens.page.entityEditPage.industryDivision]: 'Divisione Industriale',
[tokens.page.entityEditPage.industrySubclass]: 'Sottoclasse Industriale',

// file manager page
[tokens.page.fileManager.fileManager]: 'Gestore File',
[tokens.page.fileManager.back]: 'Indietro',
[tokens.page.fileManager.deleteFileTitle]: 'Elimina file?',
[tokens.page.fileManager.deleteFileBody]: 'Sei sicuro di voler eliminare questo file?',
[tokens.page.fileManager.cancel]: 'Annulla',
[tokens.page.fileManager.delete]: 'Elimina',
[tokens.page.fileManager.search]: 'Cerca',
[tokens.page.fileManager.sortBy]: 'Ordina Per',
[tokens.page.fileManager.latest]: 'Più Recenti',
[tokens.page.fileManager.oldest]: 'Più Vecchi',
[tokens.page.fileManager.byFavorites]: 'Per Preferiti',
[tokens.page.fileManager.rowPerPage]: 'Elementi per pagina',
[tokens.page.fileManager.items]: 'elementi',
[tokens.page.fileManager.updatedAt]: 'Aggiornato il',
[tokens.page.fileManager.copyLink]: 'Copia Link',

// team member page
[tokens.page.teamMembersPage.name]: 'Nome',
[tokens.page.teamMembersPage.localTime]: 'Ora Locale',
[tokens.page.teamMembersPage.location]: 'Posizione',
[tokens.page.teamMembersPage.department]: 'Dipartimento',
[tokens.page.teamMembersPage.entity]: 'Entità',
[tokens.page.teamMembersPage.actions]: 'Azioni',
[tokens.page.teamMembersPage.rowPerPage]: 'Riga per pagina',
[tokens.page.teamMembersPage.edit]: 'Modifica',
[tokens.page.teamMembersPage.email]: 'Email',
[tokens.page.teamMembersPage.details]: 'Dettagli',
[tokens.page.teamMembersPage.confidential]: 'Confidenziale',
[tokens.page.teamMembersPage.basicDetails]: 'Dettagli di Base',
[tokens.page.teamMembersPage.roleDetails]: 'Dettagli del Ruolo',
[tokens.page.teamMembersPage.locationDetails]: 'Dettagli Posizione',
[tokens.page.teamMembersPage.phone]: 'Telefono',
[tokens.page.teamMembersPage.country]: 'Paese',
[tokens.page.teamMembersPage.stateRegion]: 'Stato/Regione',
[tokens.page.teamMembersPage.streetAddress]: 'Indirizzo',
[tokens.page.teamMembersPage.zipCode]: 'Codice Postale',
[tokens.page.teamMembersPage.jobTitle]: 'Titolo di Lavoro',
[tokens.page.teamMembersPage.resetPassword]: 'Ripristina Password',
[tokens.page.teamMembersPage.userId]: 'ID Utente',
[tokens.page.teamMembersPage.fullName]: 'Nome Completo',
[tokens.page.teamMembersPage.departments]: 'Dipartimenti',
[tokens.page.teamMembersPage.dateFormat]: 'Formato Data',
[tokens.page.teamMembersPage.preferredLanguage]: 'Lingua Preferita',
[tokens.page.teamMembersPage.timeZone]: 'Fuso Orario',
[tokens.page.teamMembersPage.city]: 'Città',
[tokens.page.teamMembersPage.teamMemberId]: 'ID Membro del Team',
[tokens.page.teamMembersPage.update]: 'Aggiorna',
[tokens.page.teamMembersPage.cancel]: 'Annulla',

// file uploader modal
[tokens.page.fileUploaderModal.uploadFile]: 'Carica File',
[tokens.page.fileUploaderModal.fileTag]: 'tag file',
[tokens.page.fileUploaderModal.remove]: 'Rimuovi',
[tokens.page.fileUploaderModal.removeAll]: 'Rimuovi Tutto',
[tokens.page.fileUploaderModal.Uploading]: 'Caricamento in corso',
[tokens.page.fileUploaderModal.upload]: 'Carica',
[tokens.page.fileUploaderModal.clickToUpload]: 'Clicca per caricare',
[tokens.page.fileUploaderModal.dragAndDrop]: 'o trascina e rilascia',
[tokens.page.fileUploaderModal.maxFileSize]: 'La dimensione massima del file è 20 MB',

// calendar page
[tokens.page.calendarPage.newEvent]: 'Nuovo Evento',
[tokens.page.calendarPage.view]: 'Visualizza',
[tokens.page.calendarPage.viewOptions.month]: 'Mese',
[tokens.page.calendarPage.viewOptions.week]: 'Settimana',
[tokens.page.calendarPage.viewOptions.day]: 'Giorno',
[tokens.page.calendarPage.viewOptions.agenda]: 'Agenda',
[tokens.page.calendarPage.months.january]: 'Gennaio',
[tokens.page.calendarPage.months.february]: 'Febbraio',
[tokens.page.calendarPage.months.march]: 'Marzo',
[tokens.page.calendarPage.months.april]: 'Aprile',
[tokens.page.calendarPage.months.may]: 'Maggio',
[tokens.page.calendarPage.months.june]: 'Giugno',
[tokens.page.calendarPage.months.july]: 'Luglio',
[tokens.page.calendarPage.months.august]: 'Agosto',
[tokens.page.calendarPage.months.september]: 'Settembre',
[tokens.page.calendarPage.months.october]: 'Ottobre',
[tokens.page.calendarPage.months.november]: 'Novembre',
[tokens.page.calendarPage.months.december]: 'Dicembre',
[tokens.page.calendarPage.updateConfirmationModal.title]: 'Aggiornare l\'evento?',
[tokens.page.calendarPage.updateConfirmationModal.body]: `Sei sicuro di voler aggiornare l'orario di questo evento?`,
[tokens.page.calendarPage.updateConfirmationModal.cancelText]: `Annulla`,
[tokens.page.calendarPage.updateConfirmationModal.confirmText]: `Aggiorna`,
[tokens.page.calendarPage.eventDialog.editEvent]: 'Modifica Evento',
[tokens.page.calendarPage.eventDialog.addEvent]: 'Aggiungi Evento',
[tokens.page.calendarPage.eventDialog.title]: 'Titolo',
[tokens.page.calendarPage.eventDialog.description]: 'Descrizione',
[tokens.page.calendarPage.eventDialog.entity]: 'Entità',
[tokens.page.calendarPage.eventDialog.timeZone]: 'Fuso Orario',
[tokens.page.calendarPage.eventDialog.startDate]: 'Data di Inizio *',
[tokens.page.calendarPage.eventDialog.endDate]: 'Data di Fine *',
[tokens.page.calendarPage.eventDialog.allDay]: 'Tutto il Giorno',
[tokens.page.calendarPage.eventDialog.type]: 'Tipo',
[tokens.page.calendarPage.eventDialog.regulatoryFilingDeadlineTitle]: 'Scadenza Presentazione Regolamentare',
[tokens.page.calendarPage.eventDialog.regulatoryFilingDeadlineDescription]: 'Data di scadenza per la presentazione di relazioni annuali, dichiarazioni fiscali o certificazioni di conformità.',
[tokens.page.calendarPage.eventDialog.significantEntityMilestoneTitle]: 'Milestone Significativa dell\'Entità',
[tokens.page.calendarPage.eventDialog.significantEntityMilestoneDescription]: 'Fine periodo fiscale, lancio di un nuovo prodotto o servizio, o completamento di un progetto importante.',
[tokens.page.calendarPage.eventDialog.otherImportantEventsTitle]: 'Altri Eventi Importanti',
[tokens.page.calendarPage.eventDialog.otherImportantEventsDescription]: `Eventi che non rientrano nelle altre categorie: compleanni dei dipendenti, riunioni di team o conferenze di settore.`,
[tokens.page.calendarPage.eventDialog.cancel]: 'Annulla',
[tokens.page.calendarPage.eventDialog.confirm]: 'Conferma',
[tokens.page.calendarPage.yearPicker.buttonText]: 'Personalizzato',
[tokens.page.calendarPage.yearPicker.cancel]: 'Annulla',
[tokens.page.calendarPage.yearPicker.ok]: 'Ok',

[tokens.page.calendarPage.deleteModal.title]: 'Eliminare evento?',
[tokens.page.calendarPage.deleteModal.body]: `Sei sicuro di voler eliminare questo evento?`,
[tokens.page.calendarPage.deleteModal.cancelText]: `Annulla`,
[tokens.page.calendarPage.deleteModal.confirmText]: `Elimina`,

// group transaction list
[tokens.page.groupTransactionList.headerTitle]: 'Elimina le distrazioni. Concentrati sul tuo business.',
[tokens.page.groupTransactionList.headerBody]: `Gestire le interazioni tra le entità nel tuo gruppo è fondamentale per l'azienda fiscale internazionale.`,
[tokens.page.groupTransactionList.addGroupTransaction]: 'Aggiungi Transazione di Gruppo',
[tokens.page.groupTransactionList.itemsPerPage]: 'Elementi per pagina',
[tokens.page.groupTransactionList.enterKeyword]: 'Inserisci una parola chiave',
[tokens.page.groupTransactionList.noFiltersApplied]: 'Nessun filtro applicato',
[tokens.page.groupTransactionList.activeOnly]: 'Solo Attive',
[tokens.page.groupTransactionList.type]: 'Tipo',
[tokens.page.groupTransactionList.entity]: 'Entità',
[tokens.page.groupTransactionList.country]: 'Paese',
[tokens.page.groupTransactionList.attachment]: 'Allegato',
[tokens.page.groupTransactionList.method]: 'Metodo',
[tokens.page.groupTransactionList.year]: 'Anno',
[tokens.page.groupTransactionList.yes]: 'Sì',
[tokens.page.groupTransactionList.no]: 'No',

// group transaction add
[tokens.page.groupTransactionAdd.addGroupTransaction]: 'Aggiungi Transazione di Gruppo',
[tokens.page.groupTransactionAdd.groupTransactionAdded]: 'Transazione di Gruppo Aggiunta',
[tokens.page.groupTransactionAdd.transactionTypeCapital]: 'TIPO DI TRANSAZIONE',
[tokens.page.groupTransactionAdd.contractCapital]: 'CONTRATTO',
[tokens.page.groupTransactionAdd.entityCapital]: 'ENTITÀ',
[tokens.page.groupTransactionAdd.selectMostSuitable]: 'Seleziona il più adatto',
[tokens.page.groupTransactionAdd.continue]: 'Continua',
[tokens.page.groupTransactionAdd.saleOfGoodsDescription]: 'Quando un\'Entità all\'interno del Gruppo vende merci a un\'altra Entità all\'interno del Gruppo.',
[tokens.page.groupTransactionAdd.provisionOfServicesDescription]: 'Quando un\'Entità all\'interno del Gruppo fornisce servizi a un\'altra Entità all\'interno del Gruppo.',
[tokens.page.groupTransactionAdd.licenseOfIPDescription]: 'Quando un\'Entità all\'interno del Gruppo trasferisce diritti di proprietà intellettuale, come brevetti, marchi, diritti d\'autore o tecnologia proprietaria, a un\'altra Entità all\'interno del Gruppo.',
[tokens.page.groupTransactionAdd.cashCapitalLoanDescription]: 'Quando un\'Entità all\'interno del Gruppo fornisce denaro contante, investimenti o prestiti a un\'altra Entità all\'interno del Gruppo.',
[tokens.page.groupTransactionAdd.costSharingDescription]: 'Quando le Entità del Gruppo collaborano alla ricerca, sviluppo o acquisizione di intangibili.',
[tokens.page.groupTransactionAdd.leaseOfTengibleAssetsDescription]: 'Quando un\'Entità all\'interno del Gruppo concede in leasing beni tangibili (come attrezzature o proprietà) a un\'altra Entità all\'interno del Gruppo.',
[tokens.page.groupTransactionAdd.distributionResaleOfGoodsDescription]: 'Quando un\'Entità all\'interno del Gruppo vende merci a un\'altra Entità all\'interno del Gruppo per la distribuzione o la rivendita.',
[tokens.page.groupTransactionAdd.managementFeesDescription]: 'Commissioni per i servizi di gestione forniti da un\'Entità all\'interno del Gruppo a un\'altra Entità all\'interno del Gruppo.',
[tokens.page.groupTransactionAdd.uploadedFiles]: 'File Caricati',
[tokens.page.groupTransactionAdd.uploadFiles]: 'Carica File',
[tokens.page.groupTransactionAdd.upload]: 'Carica',
[tokens.page.groupTransactionAdd.back]: 'Indietro',
[tokens.page.groupTransactionAdd.noAgreementAvailable]: 'Nessun Accordo disponibile per il caricamento',
[tokens.page.groupTransactionAdd.otherFile]: 'Altro file',
[tokens.page.groupTransactionAdd.maxFileSize]: 'Dimensione massima file',
[tokens.page.groupTransactionAdd.view]: 'Visualizza',
[tokens.page.groupTransactionAdd.enterEntity]: 'Inserisci un\'entità',
[tokens.page.groupTransactionAdd.addTransaction]: 'Aggiungi Transazione',
[tokens.page.groupTransactionAdd.allDone]: 'Tutto fatto!',
[tokens.page.groupTransactionAdd.previewOfAddedTransaction]: 'Ecco una anteprima della Transazione di Gruppo appena aggiunta.',
[tokens.page.groupTransactionAdd.addAnother]: 'Aggiungi un\'altra',

// group transactions edit
[tokens.page.groupTransactionEdit.groupTransactions]: 'Transazioni di Gruppo',
[tokens.page.groupTransactionEdit.transactionNotFound]: 'Transazione non trovata',
[tokens.page.groupTransactionEdit.transaction]: 'Transazione',
[tokens.page.groupTransactionEdit.transactionId]: 'ID transazione',
[tokens.page.groupTransactionEdit.details]: 'Dettagli',
[tokens.page.groupTransactionEdit.transactionType]: 'Tipo Transazione',
[tokens.page.groupTransactionEdit.pricingMethod]: 'Metodo di Prezzo',
[tokens.page.groupTransactionEdit.sendingEntity]: 'Entità Mittente',
[tokens.page.groupTransactionEdit.receivingEntity]: 'Entità Ricevente',
[tokens.page.groupTransactionEdit.effectiveDateOfTransaction]: 'Data Efficace della Transazione',
[tokens.page.groupTransactionEdit.verified]: 'Verificato',
[tokens.page.groupTransactionEdit.active]: 'Attivo',
[tokens.page.groupTransactionEdit.attachments]: 'Allegati',
[tokens.page.groupTransactionEdit.uploadMore]: 'Carica altro',
[tokens.page.groupTransactionEdit.update]: 'Aggiorna',
[tokens.page.groupTransactionEdit.cancel]: 'Annulla',
[tokens.page.groupTransactionEdit.deleteAttachment]: 'Elimina Allegato',
[tokens.page.groupTransactionEdit.deleteAttachmentBody]: 'Sei sicuro di voler eliminare questo allegato?',
[tokens.page.groupTransactionEdit.delete]: 'Elimina',
[tokens.page.groupTransactionEdit.view]: 'Visualizza',

// group transactions details
[tokens.page.groupTransactionDetails.groupTransactions]: 'Transazioni di Gruppo',
[tokens.page.groupTransactionDetails.pricingMethodology]: 'Metodologia di Prezzo',
[tokens.page.groupTransactionDetails.pricingMethodologyDescription]: 'Seleziona il metodo di pricing dei trasferimenti per la transazione controllata. Sia il Metodo del Prezzo Non Controllato Comparabile (CUP), il Metodo del Prezzo di Rivendita (RPM), il Metodo del Costo Maggiore (CPM), il Metodo del Margine Netto di Transazione (TNMM), il Metodo della Divisione del Profitto Transazionale (TPSM), o il Metodo della Divisione del Profitto (PSM).',
[tokens.page.groupTransactionDetails.comparableTransactions]: 'Transazioni Comparabili',
[tokens.page.groupTransactionDetails.comparableTransactionsDescription]: 'Informazioni sulle transazioni comparabili o sui dati di mercato utilizzati per supportare il metodo di pricing selezionato. Inclusi studi di benchmarking, dati di settore o altre fonti di informazioni di pricing comparabili.',
[tokens.page.groupTransactionDetails.functionalAnalysis]: 'Analisi Funzionale',
[tokens.page.groupTransactionDetails.functionalAnalysisDescription]: 'Le funzioni svolte, i rischi assunti e gli asset impiegati da ciascuna Entità nella transazione controllata. Per determinare se il pricing della transazione è coerente con la sostanza economica delle funzioni svolte da ciascuna Entità.',
[tokens.page.groupTransactionDetails.documentationRequirements]: 'Requisiti di Documentazione',
[tokens.page.groupTransactionDetails.documentationRequirementsDescription]: 'Documentazione completa per supportare la natura di mercato della transazione controllata e dimostrare la conformità ai requisiti normativi.',
[tokens.page.groupTransactionDetails.upload]: 'Carica',
[tokens.page.groupTransactionDetails.otherFile]: 'o altro file',
[tokens.page.groupTransactionDetails.riskAssessment]: 'Valutazione del Rischio',
[tokens.page.groupTransactionDetails.riskAssessmentDescription]: 'Valutare il rischio di pricing dei trasferimenti associato alla transazione mediante la valutazione del potenziale per audit, aggiustamenti, sanzioni o contenziosi con le autorità fiscali e suggerire misure appropriate per mitigare tali rischi.',
[tokens.page.groupTransactionDetails.summary]: 'Sommario',
[tokens.page.groupTransactionDetails.method]: 'METODO',
[tokens.page.groupTransactionDetails.countries]: 'PAESI',
[tokens.page.groupTransactionDetails.transactionYear]: 'ANNO DELLA TRANSAZIONE',
[tokens.page.groupTransactionDetails.entities]: 'ENTITÀ',
[tokens.page.groupTransactionDetails.transactionDetails]: 'Dettagli della Transazione',
[tokens.page.groupTransactionDetails.detailsTab]: 'Dettagli',
[tokens.page.groupTransactionDetails.pricingMethodTab]: 'Metodo di Prezzo',
[tokens.page.groupTransactionDetails.comparablesTab]: 'Comparabili',
[tokens.page.groupTransactionDetails.functionalTab]: 'Funzionale',
[tokens.page.groupTransactionDetails.documentationTab]: 'Documentazione',
[tokens.page.groupTransactionDetails.riskTab]: 'Rischio',
[tokens.page.groupTransactionDetails.saleOfGoodsDetails1]: 'La natura specifica delle merci vendute, la quantità o il volume delle merci scambiate e eventuali termini o condizioni rilevanti della vendita.',
[tokens.page.groupTransactionDetails.provisionOfServicesDetails1]: 'La natura specifica dei servizi forniti, la portata, la durata e la complessità dei servizi resi dal Fornitore di Servizi al Ricevente del Servizio. L\'accordo di pricing, i termini di pagamento e eventuali metriche di performance o accordi di livello di servizio (SLA).',
[tokens.page.groupTransactionDetails.licenseOfIPDetails1]: 'La natura specifica della proprietà intellettuale in licenza, il tipo di proprietà immateriale (ad es. brevetti, marchi, diritti d\'autore, segreti commerciali), la sua importanza per le parti coinvolte e eventuali caratteristiche uniche che possono influenzare la sua valutazione. Inclusa la portata della licenza, la durata dell\'accordo, le restrizioni territoriali, i tassi di royalty, i termini di pagamento e qualsiasi altra disposizione rilevante.',
[tokens.page.groupTransactionDetails.cashCapitalLoanDetails1]: 'Lo scopo specifico del trasferimento di denaro, del contributo di capitale o del prestito. Perché i fondi vengono trasferiti e come saranno utilizzati dall\'Entità Ricevente. Ciò potrebbe includere il finanziamento delle esigenze di capitale circolante, il finanziamento di investimenti in capitale o il supporto a iniziative aziendali specifiche. Esaminare i termini e le condizioni della transazione come l\'importo dei fondi trasferiti.',
[tokens.page.groupTransactionDetails.cashCapitalLoanDetails2]: 'Per i Prestiti, il tasso di interesse (se applicabile), i programmi o i termini di rimborso, eventuali garanzie o sicurezze fornite e qualsiasi altra disposizione rilevante. Questo tipo di trasferimento è comune quando utilizzato per finanziare le operazioni o aiutare a configurare e espandersi in nuovi mercati.',
[tokens.page.groupTransactionDetails.cashCapitalLoanDetails3]: 'Per i Contributi di Capitale, qual è stato il numero di azioni/azioni o equità che è stato scambiato. Questo tipo di trasferimento è comune quando si spostano i fondi di investimento da una società di gestione a una controllata operativa.',
[tokens.page.groupTransactionDetails.costSharingDetails1]: 'La natura specifica della proprietà immateriale da sviluppare, migliorare o mantenere nell\'ambito dell\'accordo di condivisione dei costi. Incluso il tipo di proprietà immateriale (ad es. brevetti, marchi, diritti d\'autore, segreti commerciali), la sua importanza per i Partecipanti e eventuali caratteristiche uniche che possono influenzare la sua valutazione. Chiarire la portata e gli obiettivi dell\'accordo di condivisione dei costi, inclusi le attività/contributi specifici da intraprendere da ciascun Partecipante.',
[tokens.page.groupTransactionDetails.costSharingDetails2]: 'Quali risorse, competenze e altri contributi sono forniti da ciascun Partecipante, oltre alla base per l\'allocazione dei costi, dei rischi e dei benefici tra i partecipanti. Nonché i diritti e gli obblighi dei partecipanti e qualsiasi meccanismo di risoluzione delle controversie.',
[tokens.page.groupTransactionDetails.costSharingDetails3]: 'La durata prevista dell\'accordo e i benefici attesi dallo sviluppo, dal miglioramento o dalla manutenzione della proprietà immateriale.',
[tokens.page.groupTransactionDetails.leaseOfTengibleAssetsDetails1]: 'La natura specifica degli asset tangibili in leasing. Incluso il tipo di asset (ad es. attrezzature, macchinari, veicoli, immobili), il loro stato, uso e eventuali caratteristiche uniche che possono influenzare la loro valutazione. Nonché la durata del contratto di locazione, i pagamenti del canone di locazione, le opzioni di rinnovo, le responsabilità di manutenzione e qualsiasi altra disposizione rilevante.',
[tokens.page.groupTransactionDetails.distributionResaleOfGoodsDetails1]: 'La natura specifica delle merci distribuite o rivendute, come il tipo di merci (ad es. prodotti finiti, materie prime, componenti), le loro caratteristiche, qualità e eventuali caratteristiche uniche che possono influenzare la loro valutazione. Inclusi la portata dell\'accordo, gli accordi di pricing, i termini di pagamento, i termini di consegna, le restrizioni territoriali e qualsiasi altra disposizione rilevante.',
[tokens.page.groupTransactionDetails.managementFeesDetails1]: 'La natura specifica dei servizi di gestione forniti, come la portata dei servizi, il livello di competenza richiesto e eventuali caratteristiche uniche che possono influenzare la valutazione dei servizi. Inclusi la portata dei servizi, gli accordi di pricing, i termini di pagamento, le metriche di performance e qualsiasi altra disposizione rilevante.',

// costanti dei messaggi dal backend
[tokens.message.verifyEmailFirst]: 'Prima devi verificare il tuo indirizzo email',
[tokens.message.alreadyInvited]: `L'impiegato ha un invito in sospeso dall'azienda:`,
[tokens.message.employeeAlreadyExist]: `Già esiste nell'azienda:`,
[tokens.message.invalidCredentials]: 'Credenziali non valide',
[tokens.message.internalServerError]: 'Errore interno del server',
[tokens.message.notFound]: 'Non trovato',
[tokens.message.alreadyExist]: 'Già esistente',
[tokens.message.savedSuccesfully]: 'Salvato con successo',
[tokens.message.trialPeriodExpired]: 'Periodo di prova scaduto',
[tokens.message.subscriptionCreated]: 'Abbonamento creato',
[tokens.message.paymentSuccesful]: 'Pagamento effettuato con successo!',
[tokens.message.companyRegistrationSuccessful]: 'Registrazione aziendale riuscita!',
[tokens.message.invalidOTP]: 'OTP non valido',
[tokens.message.unsubscribed]: 'Non hai acquistato il nostro piano',
[tokens.message.emailSent]: 'Email inviata con successo',
[tokens.message.emailNotSent]: `Impossibile inviare l'email`,
[tokens.message.validToken]: 'Token valido',
[tokens.message.inavlidToken]: 'Token non valido',
[tokens.message.tokenValidationFailed]: 'Autenticazione fallita',
[tokens.message.notAuthrized]: 'Non autorizzato',
[tokens.message.updatedSuccesfully]: 'Aggiornato con successo',
[tokens.message.companyNotFound]: 'Azienda non trovata',
[tokens.message.currentPasswordIsWrong]: 'La password corrente è errata',
[tokens.message.loggedOutSuccessfully]: 'Disconnesso con successo',

// dal frontend
[tokens.message.requiredFieldsEmpty]: 'Compila tutti i campi obbligatori',
[tokens.message.removedSuccessfully]: 'Rimosso con successo',
[tokens.message.invitationRevokedSuccessfully]: 'Invito revocato con successo',
[tokens.message.resetPasswordLinkSent]: 'Link per reimpostare la password inviato alla tua email',
[tokens.message.passwordAndConfirmPassMustMatch]: 'La password e la conferma della password devono corrispondere',
[tokens.message.passwordTooShort]: 'La password deve essere lunga almeno 7 caratteri',
[tokens.message.passwordUpdated]: 'Password aggiornata con successo',
[tokens.message.otpSentToEmail]: 'OTP inviato alla tua email',
[tokens.message.photoUpdatedSuccessfully]: 'Foto aggiornata con successo',
[tokens.message.employeeRoleUpdatedSuccessfully]: `Ruolo dell'impiegato aggiornato con successo`,
[tokens.message.companyNameUpdatedSuccessfully]: `Nome dell'azienda aggiornato con successo`,
[tokens.message.nameUpdatedSuccessfully]: 'Nome aggiornato con successo',
[tokens.message.somethingWentWrong]: 'Qualcosa è andato storto',
[tokens.message.countryFieldRequired]: 'Il campo Paese è obbligatorio',
[tokens.message.entityNameFieldRequired]: 'Il campo Nome entità è obbligatorio',
[tokens.message.entityCategoryFieldRequired]: 'Il campo Categoria è obbligatorio',
[tokens.message.entityUpdated]: 'Entità aggiornata',
[tokens.message.officeHolderCreated]: `Tipo di titolare dell'ufficio personalizzato creato con successo`,
[tokens.message.entityDeleted]: 'Entità eliminata con successo',
[tokens.message.departmentCreated]: 'Dipartimento creato con successo',
[tokens.message.teamMemberUpdated]: 'Membro del team aggiornato',

[tokens.message.transactionTypeRequired]: 'Il tipo di transazione è obbligatorio',
[tokens.message.contractTypeRequired]: 'Il tipo di contratto è obbligatorio',
[tokens.message.contractFileAndFileTagRequired]: `Carica un file e un tag file o scegli 'Nessun argomento disponibile per il caricamento'`,
[tokens.message.entityFieldRequired]: 'Entità non selezionata',
[tokens.message.transactionDeleted]: 'Transazione eliminata',

[tokens.message.groupTransactionUpdatedSuccessfully]: 'Transazione di gruppo aggiornata con successo',

[tokens.message.eventCreated]: 'Evento creato!',
[tokens.message.eventUpdated]: 'Evento aggiornato!',
[tokens.message.eventDeleted]: 'Evento eliminato!',
};
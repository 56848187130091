import { AnyAction, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import { AppDispatch, RootState } from "src/store";
import i18next from "i18next";
import { FILEUPLOAD } from "src/endpoints";
import { NavigateFunction } from "react-router";
import { authRequestHandler } from "src/utils/fetch";
import { Item } from "src/types/file-manager";
import { deleteTransactionAttachmentAction } from "src/slices/transaction";

const t = i18next.t.bind(i18next);

interface FileManagerState {
  folders: Item[];
  isLoading: boolean;
}

const initialState: FileManagerState = {
  folders: [],
  isLoading: true,
};

export const slice = createSlice({
  name: "fileManager",
  initialState,
  reducers: {
    setLoading(state: FileManagerState, action: PayloadAction<boolean>): void {
      state.isLoading = action.payload;
    },
    setFolders(state: FileManagerState, action: PayloadAction<Item[]>): void {
      state.folders = action.payload;
    },
    toggleFolderFavourite(
      state: FileManagerState,
      action: PayloadAction<{ folderId: string; }>
    ): void {
      state.folders = state.folders.map((folder) => {
        if (action.payload.folderId === folder.id) {
          return {
            ...folder,
            isFavourite: !folder.isFavourite,
          };
        }
        return folder;
      });
    },
    toggleFileFavourite(
      state: FileManagerState,
      action: PayloadAction<{ folderId: string; fileId: string; }>
    ): void {
      state.folders = state.folders.map((folder) => {
        if (action.payload.folderId === folder.id) {
          return {
            ...folder,
            files: folder.files?.map((file) => {
              if(action.payload.fileId === file.id){
                return {
                  ...file,
                  isFavourite: !file.isFavourite,
                };
              }
              return file;
            }),
          };
        }
        return folder;
      });
    },
    deleteFile(
      state: FileManagerState,
      action: PayloadAction<{ folderId: string; fileId: string }>
    ): void {
      state.folders = state.folders.map((folder) => {
        if (action.payload.folderId === folder.id) {
          return {
            ...folder,
            files: folder.files?.filter(
              (file) => action.payload.fileId !== file.id
            ),
          };
        }
        return folder;
      });
    },
  },
});

type AsyncAction = ThunkAction<Promise<void>, RootState, undefined, AnyAction>;

export const getFolders =
  (navigate: NavigateFunction): AsyncAction =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(slice.actions.setLoading(true));
    const { response, resJson } = await authRequestHandler({
      url: FILEUPLOAD.LIST_FILES,
      navigate,
      method: "GET",
    });
    if (response.ok) {
      console.log(resJson?.data);
      dispatch(slice.actions.setFolders(resJson?.data));
      dispatch(slice.actions.setLoading(false));
    }
  };

export const deleteFile =
  (navigate: NavigateFunction, folderId: string, fileId: string): AsyncAction =>
  async (dispatch: AppDispatch): Promise<void> => {
    const { response, resJson } = await authRequestHandler({
      url: FILEUPLOAD.DELETE,
      navigate,
      method: "POST",
      body: JSON.stringify({
        folderKey: folderId,
        fileKey: fileId,
      }),
    });
    if (response.ok) {
      dispatch(slice.actions.deleteFile({ folderId, fileId }));
      dispatch(
        deleteTransactionAttachmentAction({
          transactionId: resJson?.data?.transactionId,
          attachmentId: resJson?.data?.removedAttachmentId,
        })
      );
    }
  };

export const deleteFileAction =
  (folderId: string, fileId: string): AsyncAction =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(slice.actions.deleteFile({ folderId, fileId }));
  };

export const toggleFileFavouriteAction =
  (folderId: string, fileId: string): AsyncAction =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(slice.actions.toggleFileFavourite({ folderId, fileId }));
  };

  export const toggleFolderFavouriteAction =
  (folderId: string): AsyncAction =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(slice.actions.toggleFolderFavourite({ folderId }));
  };

export const { reducer } = slice;

import type { AnyAction, PayloadAction} from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'src/store';
import { ThunkAction } from 'redux-thunk';
import { OFFICE_HOLDER_TYPE } from 'src/endpoints';
import { authRequestHandler } from 'src/utils/fetch';
import { NavigateFunction } from 'react-router';

interface OfficeHolderTypeState {
    officeHolderTypes :any
}

const initialState: OfficeHolderTypeState = {
    officeHolderTypes:[]
};

export const slice = createSlice({
  name: 'officeHolderType',
  initialState,
  reducers : {
    setOfficeHolderType(state: OfficeHolderTypeState, action: PayloadAction<OfficeHolderTypeState>): void {
        state.officeHolderTypes = action.payload
    }
  }
});
type AsyncAction = ThunkAction<Promise<void>, RootState, undefined, AnyAction>;

export const getOfficeHolderType = (navigate: NavigateFunction): AsyncAction => async (dispatch: AppDispatch): Promise<void> => {
  const { response, resJson } = await authRequestHandler({
    method: 'GET',
    url: OFFICE_HOLDER_TYPE.LIST,
    navigate,
  });
  if(response.ok){
    dispatch(slice.actions.setOfficeHolderType(resJson?.data));
  }
  };
  

export const { reducer } = slice;

import { tokens } from "../tokens";

export const de = {
  [tokens.common.languageChanged]: 'Sie haben die Anwendungssprache auf Deutsch geändert',
  [tokens.nav.groupTransactions]: 'Gruppentransaktionen',
  [tokens.nav.academy]: "Akademie",
  [tokens.nav.modules]: "Module",
  [tokens.nav.account]: "Konto",
  [tokens.nav.analytics]: "Analytik",
  [tokens.nav.auth]: "Authentifizierung",
  [tokens.nav.blog]: "Blog",
  [tokens.nav.browse]: "Durchsuche",
  [tokens.nav.calendar]: "Kalender",
  [tokens.nav.chat]: "Plaudern",
  [tokens.nav.checkout]: "Auschecken",
  [tokens.nav.concepts]: "Konzepte",
  [tokens.nav.contact]: "Kontakt",
  [tokens.nav.course]: "Kurs",
  [tokens.nav.create]: "Schaffen",
  [tokens.nav.crypto]: "Crypto",
  [tokens.nav.customers]: "Kunden",
  [tokens.nav.teamMembers]: "Teammitglieder",
  [tokens.nav.entities]: "Entitäten",
  [tokens.nav.dashboard]: "Dashboard",
  [tokens.nav.details]: "Einzelheiten",
  [tokens.nav.ecommerce]: "E-Commerce",
  [tokens.nav.edit]: "Bearbeiten",
  [tokens.nav.error]: "Fehler",
  [tokens.nav.feed]: "Füttern",
  [tokens.nav.fileManager]: "Dateimanager",
  [tokens.nav.finance]: "Finanzen",
  [tokens.nav.fleet]: "Flotte",
  [tokens.nav.forgotPassword]: "Passwort Vergessen",
  [tokens.nav.invoiceList]: "Rechnungen",
  [tokens.nav.jobList]: "Stellenangebote",
  [tokens.nav.kanban]: "Kanban",
  [tokens.nav.list]: "Aufführen",
  [tokens.nav.login]: "Anmeldung",
  [tokens.nav.logistics]: "Logistik",
  [tokens.nav.mail]: "E-Mail",
  [tokens.nav.management]: "Verwaltung",
  [tokens.nav.orderList]: "Aufträge",
  [tokens.nav.overview]: "Überblick",
  [tokens.nav.pages]: "Seiten",
  [tokens.nav.postCreate]: "Beitrag erstellen",
  [tokens.nav.postDetails]: "Beitragsdetails",
  [tokens.nav.postList]: "Beitragsliste",
  [tokens.nav.pricing]: "Preisgestaltung",
  [tokens.nav.productList]: "Produkte",
  [tokens.nav.profile]: 'Profil',
  [tokens.nav.billing]: 'Abrechnung',
  [tokens.nav.logout]: 'Abmelden',
  [tokens.nav.register]: "Registrieren",
  [tokens.nav.resetPassword]: "Passwort Zurücksetzen",
  [tokens.nav.socialMedia]: "Sozialen Medien",
  [tokens.nav.verifyCode]: "Code Überprüfen",
  [tokens.nav.viewEntities]: 'Entitäten anzeigen',
  [tokens.nav.addEntity]: 'Entität hinzufügen',
  [tokens.nav.viewTeamMembers]: 'Teammitglieder anzeigen',
  [tokens.nav.yourProfile]: 'Dein Profil',
  [tokens.nav.editYourProfile]: 'Dein Profil bearbeiten',
  [tokens.nav.viewTransactions]: 'Gruppentransaktionen anzeigen',
  [tokens.nav.addTransaction]: 'Gruppentransaktion hinzufügen',
  [tokens.nav.newEvent]: "Neues Ereignis",
  [tokens.nav.agenda]: "Agenda",

  // pages content
  [tokens.page.back]: 'Zurück',
  [tokens.page.dashboard]: 'Dashboard',
  [tokens.page.teamMembers]: "Teammitglieder",
  [tokens.page.search]: "Suche",

  [tokens.page.overview.overview]: "Überblick",
  [tokens.page.overview.entities]: "Gruppen-Entitäten",
  [tokens.page.overview.teamMembers]: "Teammitglieder",
  [tokens.page.overview.groupTransactions]: "Gruppen-Transaktionen",
  [tokens.page.overview.seeAllEntities]: "Alle Entitäten anzeigen",
  [tokens.page.overview.seeAllTeamMembers]: "Alle Teammitglieder anzeigen",
  [tokens.page.overview.seeAllGroupTransactions]: "Alle Gruppen-Transaktionen anzeigen",
  [tokens.page.overview.welcome]: "Willkommen",
  [tokens.page.overview.startingWithOperatingPRO]: "Beginnen Sie mit Operating PRO",
  [tokens.page.overview.startingWithOperatingPROBody]: "Lassen Sie sich durch einen proaktiven Ansatz zur intercompany Compliance führen. Sparen Sie Zeit, Geld und mögliche Kopfschmerzen in Zukunft.",
  [tokens.page.overview.inviteTeamMemberTitle]: "Teammitglied einladen",
  [tokens.page.overview.inviteTeamMemberBody]: "Administratoren können eingeladenen Benutzern Zugriffsrechte gewähren und spezifische Aufgaben zur Erledigung delegieren. Überprüfen Sie ihre Arbeit mithilfe von Genehmigungsworkflows.",
  [tokens.page.overview.verifyYourEmailTitle]: "Bestätigen Sie Ihre E-Mail",
  [tokens.page.overview.verifyYourEmailBody]: "Um die Integrität unserer E-Mail-Systeme zu gewährleisten, werden Sie beim ersten Mal, wenn Sie ein Teammitglied einladen, aufgefordert, Ihre E-Mail zu bestätigen.",
  [tokens.page.overview.addYourFirstEntityTitle]: "Fügen Sie Ihre erste Entität hinzu",
  [tokens.page.overview.addYourFirstEntityBody]: "Beginnen Sie mit dem Aufbau Ihrer Gruppenstruktur, indem Sie Ihre erste Entität hinzufügen, sei es Ihre Holdinggesellschaft oder eine Tochtergesellschaft im Ausland.",
  [tokens.page.overview.addYourSecondEntityTitle]: "Fügen Sie Ihre zweite Entität hinzu",
  [tokens.page.overview.addYourSecondEntityBody]: "Entitäten in Ihrer Gruppe können in Form von Unternehmen, Trusts, Partnerschaften, Einzelunternehmen, Non-Profit-Organisationen, Kooperationen oder Filialen vorliegen.",
  [tokens.page.overview.addIntercompanyGroupTransactionTitle]: "Fügen Sie eine Intercompany-Gruppentransaktion hinzu",
  [tokens.page.overview.addIntercompanyGroupTransactionBody]: "Dokumentieren Sie Transaktionen zwischen Ihren Entitäten mithilfe des Moduls für Gruppentransaktionen.",
  [tokens.page.overview.upgrade]: "Aktualisierung",
  [tokens.page.overview.upgradeYourAccountTitle]: "Aktualisieren Sie Ihr Konto.",
  [tokens.page.overview.upgradeYourAccountBody]: "Entsperren Sie exklusive Funktionen wie CbCR, Transfer Pricing Local Files und Jahresendanpassungen.",
  [tokens.page.overview.supportCenter]: "Support-Center",
  [tokens.page.overview.needHelpFiguringThingsOut]: "Brauchen Sie Hilfe beim Verstehen?",
  [tokens.page.overview.needHelpFiguringThingsOutBody]: "Unsicher oder Fragen? Unser Expertenteam steht Ihnen zur Unterstützung zur Verfügung. Kontaktieren Sie uns für Hilfe.",
  [tokens.page.overview.support]: "Unterstützung",
  [tokens.page.overview.upcomingEvents]: "Kommende Veranstaltungen",
  [tokens.page.overview.basedOnCalendarModule]: "Basierend auf dem Kalendermodul",
  [tokens.page.overview.seeAll]: "Alle anzeigen",
  [tokens.page.overview.noUpcomingEvents]: 'Keine bevorstehenden Veranstaltungen, fügen Sie eine neue Veranstaltung hinzu',
  [tokens.page.overview.newEvent]: 'Neue Veranstaltung',

  [tokens.page.accountPage.account]: 'Konto',
  [tokens.page.accountPage.generalTab]: 'Allgemein',
  [tokens.page.accountPage.billingTab]: 'Abrechnung',
  [tokens.page.accountPage.notificationsTab]: 'Benachrichtigungen',
  [tokens.page.accountPage.teamTab]: 'Team',
  [tokens.page.accountPage.securityTab]: 'Sicherheit',
  [tokens.page.accountPage.basicDetails]: 'Grundlegende Details',
  [tokens.page.accountPage.select]: 'Auswählen',
  [tokens.page.accountPage.companyName]: 'Firmenname',
  [tokens.page.accountPage.save]: 'Speichern',
  [tokens.page.accountPage.fullName]: 'Vollständiger Name',
  [tokens.page.accountPage.emailAddress]: 'E-Mail-Adresse',
  [tokens.page.accountPage.roleDetails]: 'Rollen-Details',
  [tokens.page.accountPage.jobTitle]: 'Jobtitel',
  [tokens.page.accountPage.departments]: 'Abteilungen',
  [tokens.page.accountPage.entity]: 'Einheit',
  [tokens.page.accountPage.locationDetails]: 'Standortdetails',
  [tokens.page.accountPage.basedIn]: 'Basiert in',
  [tokens.page.accountPage.localTime]: 'Ortszeit',
  [tokens.page.accountPage.preferredLanguage]: 'Bevorzugte Sprache',
  [tokens.page.accountPage.changePlan]: 'Tarif ändern',
  [tokens.page.accountPage.changePlanSubHeader]: 'Aktualisieren Sie, wenn Sie bereit sind',
  [tokens.page.accountPage.year]: 'Jahr',
  [tokens.page.accountPage.usingNow]: 'Aktuell in Verwendung',
  [tokens.page.accountPage.billingDetails]: 'Abrechnungsdetails',
  [tokens.page.accountPage.cardNumber]: 'Kartennummer',
  [tokens.page.accountPage.name]: 'Name',
  [tokens.page.accountPage.notRequiredForTrialPeriod]: 'Für die Testphase nicht erforderlich',
  [tokens.page.accountPage.notProvided]: 'Nicht bereitgestellt',
  [tokens.page.accountPage.trialEndDate]: 'Ablaufdatum des Testzeitraums',
  [tokens.page.accountPage.upgradePlan]: 'Tarif aktualisieren',
  [tokens.page.accountPage.autoRenewText1]: 'Ihr jährliches Abonnement verlängert sich automatisch für mehr Komfort, aber Sie haben die Kontrolle',
  [tokens.page.accountPage.autoRenewText2]: 'Automatische Verlängerung kündigen',
  [tokens.page.accountPage.autoRenewText3]: 'jederzeit.',
  [tokens.page.accountPage.inviteTeamMembers]: 'Teammitglieder einladen',
  [tokens.page.accountPage.inviteUpTo50TeamMembers]: 'Sie können bis zu 50 Teammitglieder einladen.',
  [tokens.page.accountPage.inviteUpTo50TeamMembers]: 'Sie können bis zu 150 Teammitglieder einladen.',
  [tokens.page.accountPage.sendInvite]: 'Einladung senden',
  [tokens.page.accountPage.searchTeamMembers]: 'Teammitglieder suchen',
  [tokens.page.accountPage.sortBy]: 'Sortieren nach',
  [tokens.page.accountPage.oldest]: 'Älteste',
  [tokens.page.accountPage.newest]: 'Neueste',
  [tokens.page.accountPage.teamMembers]: 'Teammitglieder',
  [tokens.page.accountPage.location]: 'Standort',
  [tokens.page.accountPage.role]: 'Rolle',
  [tokens.page.accountPage.status]: 'Status',
  [tokens.page.accountPage.actions]: 'Aktionen',
  [tokens.page.accountPage.changeRole]: 'Rolle ändern',
  [tokens.page.accountPage.remove]: 'Entfernen',
  [tokens.page.accountPage.revokeInvite]: 'Einladung widerrufen',
  [tokens.page.accountPage.changePassword]: 'Passwort ändern',
  [tokens.page.accountPage.currentPassword]: 'Aktuelles Passwort',
  [tokens.page.accountPage.newPassword]: 'Neues Passwort',
  [tokens.page.accountPage.newConfirmPassword]: 'Neues Passwort bestätigen',
  [tokens.page.accountPage.password]: 'Passwort',
  [tokens.page.accountPage.cancel]: 'Abbrechen',
  [tokens.page.accountPage.edit]: 'Bearbeiten',
  [tokens.page.accountPage.deleteAccount]: 'Konto löschen',
  [tokens.page.accountPage.deleteAccountSubTitle]: 'Löschen Sie Ihr Konto und alle Ihre Quelldaten. Dies ist nicht rückgängig zu machen.',
  [tokens.page.accountPage.loginHistory]: 'Anmeldeverlauf',
  [tokens.page.accountPage.loginHistorySubtitle]: 'Ihre kürzliche Anmeldeaktivität',
  [tokens.page.accountPage.deviceTime]: 'Gerät & Zeit',
  [tokens.page.accountPage.IPAddress]: 'IP-Adresse',
  [tokens.page.accountPage.email]: 'E-Mail',
  [tokens.page.accountPage.productUpdates]: 'Produktupdates',
  [tokens.page.accountPage.newsAnnouncementsAndProductUpdates]: 'Neuigkeiten, Ankündigungen und Produktupdates.',
  [tokens.page.accountPage.securityUpdates]: 'Sicherheitsupdates',
  [tokens.page.accountPage.NotificationsAboutAccountSecurity]: 'Wichtige Benachrichtigungen über die Sicherheit Ihres Kontos.',
  [tokens.page.accountPage.phoneNotifications]: 'Telefonbenachrichtigungen',
  [tokens.page.accountPage.removeEmployeeTitle]: 'Mitarbeiter entfernen',
  [tokens.page.accountPage.removeEmployeeBody]: 'Sind Sie sicher, dass Sie diesen Mitarbeiter aus Ihrem Unternehmen löschen möchten?',
  [tokens.page.accountPage.revokeEmployeeTitle]: 'Einladung des Mitarbeiters widerrufen',
  [tokens.page.accountPage.revokeEmployeeBody]: 'Sind Sie sicher, dass Sie die Einladung dieses Mitarbeiters aus Ihrem Unternehmen widerrufen möchten?',
  [tokens.page.accountPage.revoke]: 'Widerrufen',
  
  [tokens.page.entityListPage.entities]: 'Entitäten',
  [tokens.page.entityListPage.export]: 'Exportieren',
  [tokens.page.entityListPage.add]: 'Hinzufügen',
  [tokens.page.entityListPage.all]: 'Alle',
  [tokens.page.entityListPage.searchEntities]: 'Entitäten suchen',
  [tokens.page.entityListPage.sortBy]: 'Sortieren nach',
  [tokens.page.entityListPage.newest]: 'Neueste',
  [tokens.page.entityListPage.oldest]: 'Älteste',
  [tokens.page.entityListPage.delete]: 'Löschen',
  [tokens.page.entityListPage.edit]: 'Bearbeiten',
  [tokens.page.entityListPage.title]: 'Titel',
  [tokens.page.entityListPage.location]: 'Standort',
  [tokens.page.entityListPage.reportingDate]: 'Berichtsdatum',
  [tokens.page.entityListPage.status]: 'Status',
  [tokens.page.entityListPage.actions]: 'Aktionen',
  [tokens.page.entityListPage.rowPerPage]: 'Zeilen pro Seite',

  [tokens.page.entityAddPage.addEntity]: 'Entität hinzufügen',
  [tokens.page.entityAddPage.entityAdded]: 'Entität hinzugefügt',
  [tokens.page.entityAddPage.type]: 'Typ',
  [tokens.page.entityAddPage.country]: 'Land',
  [tokens.page.entityAddPage.registration]: 'Registrierung',
  [tokens.page.entityAddPage.selectMostSuitable]: 'Am besten geeignete auswählen',
  [tokens.page.entityAddPage.continue]: 'Weiter',
  [tokens.page.entityAddPage.companyCorporation]: 'Unternehmen/Gesellschaft',
  [tokens.page.entityAddPage.trust]: 'Treuhänder',
  [tokens.page.entityAddPage.partnership]: 'Partnerschaft',
  [tokens.page.entityAddPage.soleProprietorshipSoleTrader]: 'Einzelunternehmen/Einzelhändler',
  [tokens.page.entityAddPage.nonProfitOrganizationFoundation]: 'Gemeinnützige Organisation/Stiftung',
  [tokens.page.entityAddPage.coOperative]: 'Genossenschaft',
  [tokens.page.entityAddPage.overseasBranch]: 'Auslandsniederlassung',
  [tokens.page.entityAddPage.whichCountryWasItEstablishedIn]: 'In welchem Land wurde sie gegründet?',
  [tokens.page.entityAddPage.enterCountry]: 'Land eingeben',
  [tokens.page.entityAddPage.selectedCountry]: 'Ausgewähltes Land',
  [tokens.page.entityAddPage.back]: 'Zurück',
  [tokens.page.entityAddPage.whatIsFullLegalNameOfEntity]: 'Was ist der vollständige rechtliche Name der Entität?',
  [tokens.page.entityAddPage.entityName]: 'Entitätsname',
  [tokens.page.entityAddPage.entityNumber]: 'Entitätsnummer',
  [tokens.page.entityAddPage.whatWasOfficialDateOfRegistration]: 'Was war das offizielle Registrierungsdatum?',
  [tokens.page.entityAddPage.registrationDate]: 'Registrierungsdatum',
  [tokens.page.entityAddPage.createEntity]: 'Entität erstellen',
  [tokens.page.entityAddPage.allDone]: 'Alles erledigt!',
  [tokens.page.entityAddPage.previewOfEntity]: 'Hier ist eine Vorschau der gerade hinzugefügten Entität.',
  [tokens.page.entityAddPage.view]: 'Anzeigen',
  [tokens.page.entityAddPage.addAnotherEntity]: 'Eine weitere Entität hinzufügen',

  [tokens.page.entityDetailsPage.entities]: 'Entitäten',
  [tokens.page.entityDetailsPage.entityId]: 'Entitäts-ID',
  [tokens.page.entityDetailsPage.edit]: 'Bearbeiten',
  [tokens.page.entityDetailsPage.name]: 'Name',
  [tokens.page.entityDetailsPage.entityNumber]: 'Entitätsnummer',
  [tokens.page.entityDetailsPage.entityType]: 'Entitätstyp',
  [tokens.page.entityDetailsPage.status]: 'Status',
  [tokens.page.entityDetailsPage.registrationDate]: 'Registrierungsdatum',
  [tokens.page.entityDetailsPage.yearlyReportingDate]: 'Jährliches Berichtsdatum',
  [tokens.page.entityDetailsPage.locationDetails]: 'Standortdetails',
  [tokens.page.entityDetailsPage.country]: 'Land',
  [tokens.page.entityDetailsPage.stateRegion]: 'Bundesland/Region',
  [tokens.page.entityDetailsPage.licenceType]: 'Lizenztyp',
  [tokens.page.entityDetailsPage.licence]: 'Lizenz',
  [tokens.page.entityDetailsPage.licenceNumber]: 'Lizenznummer',
  [tokens.page.entityDetailsPage.regulatoryBody]: 'Regulierende Behörde',
  [tokens.page.entityDetailsPage.state]: 'Bundesland',
  [tokens.page.entityDetailsPage.officeHolder]: 'Funktionsträger',
  [tokens.page.entityDetailsPage.type]: 'Typ',
  [tokens.page.entityDetailsPage.shareholder]: 'Aktionär',
  [tokens.page.entityDetailsPage.numberOfSharesHeld]: 'Anzahl der gehaltenen Aktien',
  [tokens.page.entityDetailsPage.countryOfResidence]: 'Land des Wohnsitzes',
  [tokens.page.entityDetailsPage.passport]: 'Reisepass',
  [tokens.page.entityDetailsPage.politicallyExposedPerson]: 'Politisch exponierte Person',
  [tokens.page.entityDetailsPage.personWithSignificantControl]: 'Person mit erheblichem Einfluss',
  [tokens.page.entityDetailsPage.personWithSignificantControlOptionYes]: 'Ja, Person mit erheblichem Einfluss (PSC)',
  [tokens.page.entityDetailsPage.personWithSignificantControlOptionNo]: 'Keine Person mit erheblichem Einfluss (PSC)',
  [tokens.page.entityDetailsPage.politicallyExposedPersonYes]: 'Ja, politisch exponierte Person (PEP)',
  [tokens.page.entityDetailsPage.politicallyExposedPersonNo]: 'Keine politisch exponierte Person (PEP)',

  [tokens.page.entityEditPage.entities]: 'Entitäten',
  [tokens.page.entityEditPage.select]: 'Auswählen',
  [tokens.page.entityEditPage.entityId]: 'Entitäts-ID',
  [tokens.page.entityEditPage.basicDetails]: 'Grundlegende Details',
  [tokens.page.entityEditPage.name]: 'Name',
  [tokens.page.entityEditPage.entityNumber]: 'Entitätsnummer',
  [tokens.page.entityEditPage.country]: 'Land',
  [tokens.page.entityEditPage.state]: 'Bundesland',
  [tokens.page.entityEditPage.typeStatus]: 'Typ & Status',
  [tokens.page.entityEditPage.entityType]: 'Entitätstyp',
  [tokens.page.entityEditPage.entityStatus]: 'Entitätsstatus',
  [tokens.page.entityEditPage.registrationReportingDate]: 'Registrierungs- & Berichtsdatum',
  [tokens.page.entityEditPage.registrationDate]: 'Registrierungsdatum',
  [tokens.page.entityEditPage.yearlyReportingDate]: 'Jährliches Berichtsdatum',
  [tokens.page.entityEditPage.officeHolders]: 'Amtsinhaber',
  [tokens.page.entityEditPage.shareholders]: 'Aktionäre',
  [tokens.page.entityEditPage.licence]: 'Lizenz(en)',
  [tokens.page.entityEditPage.update]: 'Aktualisieren',
  [tokens.page.entityEditPage.cancel]: 'Abbrechen',
  [tokens.page.entityEditPage.licenceType]: 'Lizenztyp',
  [tokens.page.entityEditPage.licenceNumber]: 'Lizenznummer',
  [tokens.page.entityEditPage.regulatoryBody]: 'Regulierende Behörde',
  [tokens.page.entityEditPage.add]: 'Hinzufügen',
  [tokens.page.entityEditPage.officeHolderType]: 'Funktionsträgertyp',
  [tokens.page.entityEditPage.addCustom]: 'Benutzerdefiniert hinzufügen',
  [tokens.page.entityEditPage.shareHolderType]: 'Aktionärstyp',
  [tokens.page.entityEditPage.numberOfSharesHeld]: 'Anzahl der gehaltenen Aktien',
  [tokens.page.entityEditPage.countryOfResidence]: 'Land des Wohnsitzes',
  [tokens.page.entityEditPage.individual]: 'Einzelperson',
  [tokens.page.entityEditPage.companyCorporation]: 'Unternehmensgesellschaft',
  [tokens.page.entityEditPage.trust]: 'Treuhänderschaft',
  [tokens.page.entityEditPage.partnership]: 'Partnerschaft',
  [tokens.page.entityEditPage.soleProprietorshipSoleTrader]: 'Einzelunternehmen/Einzelhändler',
  [tokens.page.entityEditPage.nonProfitOrganizationFoundation]: 'Gemeinnützige Organisation/Stiftung',
  [tokens.page.entityEditPage.coOperative]: 'Genossenschaft',
  [tokens.page.entityEditPage.overseasBranch]: 'Auslandsniederlassung',
  [tokens.page.entityEditPage.active]: 'Aktiv',
  [tokens.page.entityEditPage.dormant]: 'Ruhen',
  [tokens.page.entityEditPage.suspended]: 'Ausgesetzt',
  [tokens.page.entityEditPage.administration]: 'Verwaltung',
  [tokens.page.entityEditPage.receivership]: 'Verwaltung durch Insolvenzverwalter',
  [tokens.page.entityEditPage.liquidation]: 'Liquidation',
  [tokens.page.entityEditPage.deregistered]: 'Abgemeldet',
  [tokens.page.entityEditPage.politicallyExposedPerson]: 'Politisch exponierte Person',
  [tokens.page.entityEditPage.personWithSignificantControl]: 'Person mit erheblichem Einfluss',
  [tokens.page.entityEditPage.personWithSignificantControlOptionYes]: 'Ja, Person mit erheblichem Einfluss (PSC)',
  [tokens.page.entityEditPage.personWithSignificantControlOptionNo]: 'Keine Person mit erheblichem Einfluss (PSC)',
  [tokens.page.entityEditPage.politicallyExposedPersonYes]: 'Ja, politisch exponierte Person (PEP)',
  [tokens.page.entityEditPage.politicallyExposedPersonNo]: 'Keine politisch exponierte Person (PEP)',
  [tokens.page.entityEditPage.entityTimezone]: 'Zeitzone der Entität',
  [tokens.page.entityEditPage.entityTeamSize]: 'Größe des Entitätsteams',
  [tokens.page.entityEditPage.entityCurrency]: 'Währung der Entität',
  [tokens.page.entityEditPage.functionalCurrency]: 'Funktionswährung',
  [tokens.page.entityEditPage.presentationCurrency]: 'Präsentationswährung',
  [tokens.page.entityEditPage.industry]: 'Industrie',
  [tokens.page.entityEditPage.industryGroup]: 'Industriegruppe',
  [tokens.page.entityEditPage.industryDivision]: 'Industrieabteilung',
  [tokens.page.entityEditPage.industrySubclass]: 'Industrieunterklasse',

  [tokens.page.fileManager.fileManager]: 'Dateimanager',
  [tokens.page.fileManager.back]: 'Zurück',
  [tokens.page.fileManager.deleteFileTitle]: 'Datei löschen?',
  [tokens.page.fileManager.deleteFileBody]: 'Sind Sie sicher, dass Sie diese Datei löschen möchten?',
  [tokens.page.fileManager.cancel]: 'Abbrechen',
  [tokens.page.fileManager.delete]: 'Löschen',
  [tokens.page.fileManager.search]: 'Suchen',
  [tokens.page.fileManager.sortBy]: 'Sortieren nach',
  [tokens.page.fileManager.latest]: 'Neueste',
  [tokens.page.fileManager.oldest]: 'Älteste',
  [tokens.page.fileManager.byFavorites]: 'Nach Favoriten',
  [tokens.page.fileManager.rowPerPage]: 'Einträge pro Seite',
  [tokens.page.fileManager.items]: 'Einträge',
  [tokens.page.fileManager.updatedAt]: 'Aktualisiert am',
  [tokens.page.fileManager.copyLink]: 'Link kopieren',

  [tokens.page.teamMembersPage.name]: "Name",
  [tokens.page.teamMembersPage.localTime]: "Ortszeit",
  [tokens.page.teamMembersPage.location]: "Ort",
  [tokens.page.teamMembersPage.department]: "Abteilung",
  [tokens.page.teamMembersPage.entity]: "Entität",
  [tokens.page.teamMembersPage.actions]: "Aktionen",
  [tokens.page.teamMembersPage.rowPerPage]: "Zeilen pro Seite",
  [tokens.page.teamMembersPage.edit]: "Bearbeiten",
  [tokens.page.teamMembersPage.email]: "E-Mail",
  [tokens.page.teamMembersPage.details]: "Details",
  [tokens.page.teamMembersPage.confidential]: 'Vertraulich',
  [tokens.page.teamMembersPage.basicDetails]: "Grundlegende Details",
  [tokens.page.teamMembersPage.roleDetails]: "Rollen-Details",
  [tokens.page.teamMembersPage.locationDetails]: "Ort Details",
  [tokens.page.teamMembersPage.phone]: "Telefon",
  [tokens.page.teamMembersPage.country]: "Land",
  [tokens.page.teamMembersPage.stateRegion]: "Bundesland/Region",
  [tokens.page.teamMembersPage.streetAddress]: "Straßenadresse",
  [tokens.page.teamMembersPage.zipCode]: "Postleitzahl",
  [tokens.page.teamMembersPage.jobTitle]: "Berufsbezeichnung",
  [tokens.page.teamMembersPage.resetPassword]: "Passwort zurücksetzen",
  [tokens.page.teamMembersPage.userId]: "Benutzer-ID",
  [tokens.page.teamMembersPage.fullName]: "Vollständiger Name",
  [tokens.page.teamMembersPage.departments]: "Abteilungen",
  [tokens.page.teamMembersPage.dateFormat]: "Datumsformat",
  [tokens.page.teamMembersPage.preferredLanguage]: "Bevorzugte Sprache",
  [tokens.page.teamMembersPage.timeZone]: "Zeitzone",
  [tokens.page.teamMembersPage.city]: "Stadt",
  [tokens.page.teamMembersPage.teamMemberId]: "Teammitglied-ID",
  [tokens.page.teamMembersPage.update]: 'Aktualisieren',
  [tokens.page.teamMembersPage.cancel]: 'Abbrechen',

  [tokens.page.fileUploaderModal.uploadFile]: 'Datei hochladen',
  [tokens.page.fileUploaderModal.fileTag]: 'Datei-Tag',
  [tokens.page.fileUploaderModal.remove]: 'Entfernen',
  [tokens.page.fileUploaderModal.removeAll]: 'Alle entfernen',
  [tokens.page.fileUploaderModal.Uploading]: 'Lädt hoch',
  [tokens.page.fileUploaderModal.upload]: 'Hochladen',
  [tokens.page.fileUploaderModal.clickToUpload]: 'Klicken, um hochzuladen',
  [tokens.page.fileUploaderModal.dragAndDrop]: 'oder ziehen und ablegen',
  [tokens.page.fileUploaderModal.maxFileSize]: 'Die maximale Dateigröße beträgt 20 MB',

  [tokens.page.calendarPage.newEvent]: 'Neues Ereignis',
  [tokens.page.calendarPage.view]: 'Ansicht',
  [tokens.page.calendarPage.viewOptions.month]: 'Monat',
  [tokens.page.calendarPage.viewOptions.week]: 'Woche',
  [tokens.page.calendarPage.viewOptions.day]: 'Tag',
  [tokens.page.calendarPage.viewOptions.agenda]: 'Agenda',
  [tokens.page.calendarPage.months.january]: 'Januar',
  [tokens.page.calendarPage.months.february]: 'Februar',
  [tokens.page.calendarPage.months.march]: 'März',
  [tokens.page.calendarPage.months.april]: 'April',
  [tokens.page.calendarPage.months.may]: 'Mai',
  [tokens.page.calendarPage.months.june]: 'Juni',
  [tokens.page.calendarPage.months.july]: 'Juli',
  [tokens.page.calendarPage.months.august]: 'August',
  [tokens.page.calendarPage.months.september]: 'September',
  [tokens.page.calendarPage.months.october]: 'Oktober',
  [tokens.page.calendarPage.months.november]: 'November',
  [tokens.page.calendarPage.months.december]: 'Dezember',
  [tokens.page.calendarPage.updateConfirmationModal.title]: 'Ereignis aktualisieren?',
  [tokens.page.calendarPage.updateConfirmationModal.body]: 'Sind Sie sicher, dass Sie die Zeit dieses Ereignisses aktualisieren möchten?',
  [tokens.page.calendarPage.updateConfirmationModal.cancelText]: 'Abbrechen',
  [tokens.page.calendarPage.updateConfirmationModal.confirmText]: 'Aktualisieren',
  [tokens.page.calendarPage.eventDialog.editEvent]: 'Ereignis bearbeiten',
  [tokens.page.calendarPage.eventDialog.addEvent]: 'Ereignis hinzufügen',
  [tokens.page.calendarPage.eventDialog.title]: 'Titel',
  [tokens.page.calendarPage.eventDialog.description]: 'Beschreibung',
  [tokens.page.calendarPage.eventDialog.entity]: 'Einheit',
  [tokens.page.calendarPage.eventDialog.timeZone]: 'Zeitzone',
  [tokens.page.calendarPage.eventDialog.startDate]: 'Startdatum',
  [tokens.page.calendarPage.eventDialog.endDate]: 'Enddatum',
  [tokens.page.calendarPage.eventDialog.allDay]: 'Ganztägig',
  [tokens.page.calendarPage.eventDialog.type]: 'Typ',
  [tokens.page.calendarPage.eventDialog.regulatoryFilingDeadlineTitle]: 'Regulatorische Einreichungsfrist',
  [tokens.page.calendarPage.eventDialog.regulatoryFilingDeadlineDescription]: 'Frist für die Einreichung von Jahresberichten, Steuererklärungen oder Compliance-Zertifizierungen.',
  [tokens.page.calendarPage.eventDialog.significantEntityMilestoneTitle]: 'Bedeutsames Ereignis der Einheit',
  [tokens.page.calendarPage.eventDialog.significantEntityMilestoneDescription]: 'Fiskalperioden-Enddatum, Einführung eines neuen Produkts oder Dienstes oder Abschluss eines wichtigen Projekts.',
  [tokens.page.calendarPage.eventDialog.otherImportantEventsTitle]: 'Andere wichtige Ereignisse',
  [tokens.page.calendarPage.eventDialog.otherImportantEventsDescription]: 'Ereignisse, die nicht in die anderen Kategorien fallen: Geburtstage von Mitarbeitern, Team-Meetings oder Branchenkonferenzen.',
  [tokens.page.calendarPage.eventDialog.cancel]: 'Abbrechen',
  [tokens.page.calendarPage.eventDialog.confirm]: 'Bestätigen',
  [tokens.page.calendarPage.yearPicker.buttonText]: 'Benutzerdefiniert',
  [tokens.page.calendarPage.yearPicker.cancel]: 'Abbrechen',
  [tokens.page.calendarPage.yearPicker.ok]: 'Ok',
  [tokens.page.calendarPage.deleteModal.title]: 'Ereignis löschen?',
  [tokens.page.calendarPage.deleteModal.body]: 'Sind Sie sicher, dass Sie dieses Ereignis löschen möchten?',
  [tokens.page.calendarPage.deleteModal.cancelText]: 'Abbrechen',
  [tokens.page.calendarPage.deleteModal.confirmText]: 'Löschen',

  [tokens.page.groupTransactionList.headerTitle]: 'Ablenkungen beseitigen. Konzentrieren Sie sich auf Ihr Geschäft.', 
  [tokens.page.groupTransactionList.headerBody]: 'Die Verwaltung von Interaktionen zwischen Einheiten in Ihrer Gruppe ist für internationale Steuerunternehmen entscheidend.', 
  [tokens.page.groupTransactionList.addGroupTransaction]: 'Gruppentransaktion hinzufügen', 
  [tokens.page.groupTransactionList.itemsPerPage]: 'Elemente pro Seite',
  [tokens.page.groupTransactionList.enterKeyword]: 'Geben Sie ein Stichwort ein',
  [tokens.page.groupTransactionList.noFiltersApplied]: 'Keine Filter angewendet',
  [tokens.page.groupTransactionList.activeOnly]: 'Nur aktiv',
  [tokens.page.groupTransactionList.type]: 'Typ',
  [tokens.page.groupTransactionList.entity]: 'Unternehmen',
  [tokens.page.groupTransactionList.country]: 'Land',
  [tokens.page.groupTransactionList.attachment]: 'Anhang',
  [tokens.page.groupTransactionList.method]: 'Methode',
  [tokens.page.groupTransactionList.year]: 'Jahr',
  [tokens.page.groupTransactionList.yes]: 'Ja',
  [tokens.page.groupTransactionList.no]: 'Nein',

  [tokens.page.groupTransactionAdd.addGroupTransaction]: 'Gruppen-Transaktion hinzufügen',
  [tokens.page.groupTransactionAdd.groupTransactionAdded]: 'Gruppen-Transaktion hinzugefügt',
  [tokens.page.groupTransactionAdd.transactionTypeCapital]: 'TRANSATIONSTYP',
  [tokens.page.groupTransactionAdd.contractCapital]: 'VERTRAG',
  [tokens.page.groupTransactionAdd.entityCapital]: 'UNTERNEHMEN',
  [tokens.page.groupTransactionAdd.selectMostSuitable]: 'Am besten geeigneten auswählen',
  [tokens.page.groupTransactionAdd.continue]: 'Weiter',
  [tokens.page.groupTransactionAdd.saleOfGoodsDescription]: 'Wenn ein Unternehmen innerhalb der Gruppe Waren an ein anderes Unternehmen innerhalb der Gruppe verkauft.',
  [tokens.page.groupTransactionAdd.provisionOfServicesDescription]: 'Wenn ein Unternehmen innerhalb der Gruppe Dienstleistungen für ein anderes Unternehmen innerhalb der Gruppe erbringt.',
  [tokens.page.groupTransactionAdd.licenseOfIPDescription]: 'Wenn ein Unternehmen innerhalb der Gruppe Rechte an geistigem Eigentum, wie Patente, Marken, Urheberrechte oder proprietäre Technologien, an ein anderes Unternehmen innerhalb der Gruppe überträgt.',
  [tokens.page.groupTransactionAdd.cashCapitalLoanDescription]: 'Wenn ein Unternehmen innerhalb der Gruppe Bargeld, Investitionen oder Darlehen an ein anderes Unternehmen innerhalb der Gruppe bereitstellt.',
  [tokens.page.groupTransactionAdd.costSharingDescription]: 'Wenn Gruppenunternehmen bei der Forschung, Entwicklung oder Beschaffung von immateriellen Vermögenswerten zusammenarbeiten.',
  [tokens.page.groupTransactionAdd.leaseOfTengibleAssetsDescription]: 'Wenn ein Unternehmen innerhalb der Gruppe Sachanlagen (wie Ausrüstung oder Immobilien) an ein anderes Unternehmen innerhalb der Gruppe vermietet.',
  [tokens.page.groupTransactionAdd.distributionResaleOfGoodsDescription]: 'Wenn ein Unternehmen innerhalb der Gruppe Waren an ein anderes Unternehmen innerhalb der Gruppe zum Vertrieb oder Weiterverkauf verkauft.',
  [tokens.page.groupTransactionAdd.managementFeesDescription]: 'Gebühren für Managementdienstleistungen, die von einem Unternehmen innerhalb der Gruppe für ein anderes Unternehmen innerhalb der Gruppe erbracht werden.',
  [tokens.page.groupTransactionAdd.uploadedFiles]: 'Hochgeladene Dateien',
  [tokens.page.groupTransactionAdd.uploadFiles]: 'Dateien hochladen',
  [tokens.page.groupTransactionAdd.upload]: 'Hochladen',
  [tokens.page.groupTransactionAdd.back]: 'Zurück',
  [tokens.page.groupTransactionAdd.noAgreementAvailable]: 'Keine Vereinbarung für den Upload verfügbar',
  [tokens.page.groupTransactionAdd.otherFile]: 'Andere Datei',
  [tokens.page.groupTransactionAdd.maxFileSize]: 'Maximale Dateigröße',
  [tokens.page.groupTransactionAdd.view]: 'Anzeigen',
  [tokens.page.groupTransactionAdd.enterEntity]: 'Ein Unternehmen eingeben',
  [tokens.page.groupTransactionAdd.addTransaction]: 'Transaktion hinzufügen',
  [tokens.page.groupTransactionAdd.allDone]: 'Alles erledigt!',
  [tokens.page.groupTransactionAdd.previewOfAddedTransaction]: 'Hier ist eine Vorschau der Gruppentransaktion, die Sie gerade hinzugefügt haben.',
  [tokens.page.groupTransactionAdd.addAnother]: 'Eine weitere hinzufügen',

  [tokens.page.groupTransactionEdit.groupTransactions]: 'Gruppentransaktionen',
  [tokens.page.groupTransactionEdit.transactionNotFound]: 'Transaktion nicht gefunden',
  [tokens.page.groupTransactionEdit.transaction]: 'Transaktion',
  [tokens.page.groupTransactionEdit.transactionId]: 'transaktion-id',
  [tokens.page.groupTransactionEdit.details]: 'Details',
  [tokens.page.groupTransactionEdit.transactionType]: 'Transaktionstyp',
  [tokens.page.groupTransactionEdit.pricingMethod]: 'Preismethode',
  [tokens.page.groupTransactionEdit.sendingEntity]: 'Sendende Einheit',
  [tokens.page.groupTransactionEdit.receivingEntity]: 'Empfangende Einheit',
  [tokens.page.groupTransactionEdit.effectiveDateOfTransaction]: 'Effektives Transaktionsdatum',
  [tokens.page.groupTransactionEdit.verified]: 'Verifiziert',
  [tokens.page.groupTransactionEdit.active]: 'Aktiv',
  [tokens.page.groupTransactionEdit.attachments]: 'Anhänge',
  [tokens.page.groupTransactionEdit.uploadMore]: 'Mehr hochladen',
  [tokens.page.groupTransactionEdit.update]: 'Aktualisieren',
  [tokens.page.groupTransactionEdit.cancel]: 'Abbrechen',
  [tokens.page.groupTransactionEdit.deleteAttachment]: 'Anhang löschen',
  [tokens.page.groupTransactionEdit.deleteAttachmentBody]: 'Sind Sie sicher, dass Sie diesen Anhang löschen möchten?',
  [tokens.page.groupTransactionEdit.delete]: 'Löschen',
  [tokens.page.groupTransactionEdit.view]: 'Ansehen',

  [tokens.page.groupTransactionDetails.groupTransactions]: 'Gruppentransaktionen',
  [tokens.page.groupTransactionDetails.pricingMethodology]: 'Preismethodik',
  [tokens.page.groupTransactionDetails.pricingMethodologyDescription]: 'Wählen Sie die Verrechnungspreismethode für die kontrollierte Transaktion aus. Entweder die Methode des vergleichbaren unkontrollierten Preises (CUP), die Wiederverkaufspreismethode (RPM), die Kostenaufschlagsmethode (CPM), die Transaktionsnettomargenmethode (TNMM), die Transaktionsgewinnaufteilungsmethode (TPSM) oder die Gewinnaufteilungsmethode (PSM).',
  [tokens.page.groupTransactionDetails.comparableTransactions]: 'Vergleichbare Transaktionen',
  [tokens.page.groupTransactionDetails.comparableTransactionsDescription]: 'Informationen über vergleichbare Transaktionen oder Marktdaten, die zur Unterstützung der ausgewählten Verrechnungspreismethode verwendet werden. Dazu gehören Benchmark-Studien, Branchendaten oder andere Quellen für vergleichbare Preisinformationen.',
  [tokens.page.groupTransactionDetails.functionalAnalysis]: 'Funktionale Analyse',
  [tokens.page.groupTransactionDetails.functionalAnalysisDescription]: 'Die von jeder Einheit in der kontrollierten Transaktion ausgeführten Funktionen, übernommenen Risiken und eingesetzten Vermögenswerte. Um festzustellen, ob die Preise der Transaktion mit der wirtschaftlichen Substanz der von jeder Einheit ausgeführten Funktionen übereinstimmen.',
  [tokens.page.groupTransactionDetails.documentationRequirements]: 'Dokumentationsanforderungen',
  [tokens.page.groupTransactionDetails.documentationRequirementsDescription]: 'Umfassende Dokumentation zur Unterstützung der Verrechnungspreisnatur der kontrollierten Transaktion und zur Demonstration der Einhaltung gesetzlicher Anforderungen.',
  [tokens.page.groupTransactionDetails.upload]: 'Hochladen',
  [tokens.page.groupTransactionDetails.otherFile]: 'oder andere Datei',
  [tokens.page.groupTransactionDetails.riskAssessment]: 'Risikobewertung',
  [tokens.page.groupTransactionDetails.riskAssessmentDescription]: 'Bewerten Sie das Verrechnungspreisrisiko, das mit der Transaktion verbunden ist, indem Sie das Potenzial für Verrechnungspreisprüfungen, -anpassungen, -strafen oder -streitigkeiten mit Steuerbehörden bewerten und geeignete Maßnahmen zur Minimierung solcher Risiken vorschlagen.',
  [tokens.page.groupTransactionDetails.summary]: 'Zusammenfassung',
  [tokens.page.groupTransactionDetails.method]: 'METHODE',
  [tokens.page.groupTransactionDetails.countries]: 'LÄNDER',
  [tokens.page.groupTransactionDetails.transactionYear]: 'TRANSAKTIONSJAHR',
  [tokens.page.groupTransactionDetails.entities]: 'EINHEITEN',
  [tokens.page.groupTransactionDetails.transactionDetails]: 'Transaktionsdetails',
  [tokens.page.groupTransactionDetails.detailsTab]: 'Details',
  [tokens.page.groupTransactionDetails.pricingMethodTab]: 'Preismethode',
  [tokens.page.groupTransactionDetails.comparablesTab]: 'Vergleichbare',
  [tokens.page.groupTransactionDetails.functionalTab]: 'Funktional',
  [tokens.page.groupTransactionDetails.documentationTab]: 'Dokumentation',
  [tokens.page.groupTransactionDetails.riskTab]: 'Risiko',
  [tokens.page.groupTransactionDetails.saleOfGoodsDetails1]: 'Die spezifische Art der verkauften Waren, die Menge oder das Volumen der ausgetauschten Waren und alle relevanten Bedingungen des Verkaufs.',
  [tokens.page.groupTransactionDetails.provisionOfServicesDetails1]: 'Die spezifische Art der erbrachten Dienstleistungen, der Umfang, die Dauer und die Komplexität der Dienstleistungen, die vom Dienstleister an den Dienstempfänger erbracht werden. Die Preisgestaltung, Zahlungsbedingungen und etwaige Leistungskennzahlen oder Service-Level-Vereinbarungen (SLAs).',
  [tokens.page.groupTransactionDetails.licenseOfIPDetails1]: 'Die spezifische Art des lizenzierten geistigen Eigentums, die Art des immateriellen Vermögenswerts (z. B. Patente, Marken, Urheberrechte, Geschäftsgeheimnisse), seine Bedeutung für die beteiligten Parteien und alle einzigartigen Eigenschaften, die seine Bewertung beeinflussen können. Dazu gehören der Umfang der Lizenz, die Dauer der Vereinbarung, territoriale Einschränkungen, Lizenzgebühren, Zahlungsbedingungen und alle anderen relevanten Bestimmungen.',
  [tokens.page.groupTransactionDetails.cashCapitalLoanDetails1]: 'Der spezifische Zweck der Geldüberweisung, Kapitaleinlage oder Darlehensgewährung. Warum die Mittel übertragen werden und wie sie von der empfangenden Einheit verwendet werden. Dies könnte die Finanzierung des Betriebskapitalbedarfs, die Finanzierung von Investitionen oder die Unterstützung bestimmter Geschäftsinitiativen umfassen. Überprüfen Sie die Bedingungen der Transaktion wie die Höhe der übertragenen Mittel.',
  [tokens.page.groupTransactionDetails.cashCapitalLoanDetails2]: 'Für Darlehen die Zinssatz (falls zutreffend), Rückzahlungspläne oder -bedingungen, etwaige Sicherheiten oder Sicherheiten und alle anderen relevanten Bestimmungen. Diese Art von Transfer ist üblich, wenn es darum geht, den Betrieb zu finanzieren oder bei der Einrichtung und Expansion in neue Märkte zu helfen.',
  [tokens.page.groupTransactionDetails.cashCapitalLoanDetails3]: 'Für Kapitaleinlagen, welche Anzahl von Aktien/Optionen des Eigenkapitals ausgetauscht wurde. Diese Art von Transfer ist üblich, wenn Investitionsmittel von einer Holdinggesellschaft an eine operative Tochtergesellschaft übertragen werden.',
  [tokens.page.groupTransactionDetails.costSharingDetails1]: 'Die spezifische Art des zu entwickelnden, zu verbessern oder zu wartenden immateriellen Vermögenswerts unter der Kostenbeteiligungsvereinbarung. Dazu gehören die Art des immateriellen Vermögenswerts (z. B. Patente, Marken, Urheberrechte, Geschäftsgeheimnisse), seine Bedeutung für die Teilnehmer und alle einzigartigen Eigenschaften, die seine Bewertung beeinflussen können. Klarstellen des Umfangs und der Ziele der Kostenbeteiligungsvereinbarung, einschließlich der spezifischen Aktivitäten/Beiträge, die von jedem Teilnehmer durchgeführt werden sollen.',
  [tokens.page.groupTransactionDetails.costSharingDetails2]: 'Welche Ressourcen, Fachkenntnisse und sonstigen Beiträge von jedem Teilnehmer bereitgestellt werden, sowie die Grundlage für die Zuweisung von Kosten, Risiken und Nutzen zwischen den Teilnehmern. Sowie die Rechte und Pflichten der Teilnehmer und etwaige Streitbeilegungsmechanismen.',
  [tokens.page.groupTransactionDetails.costSharingDetails3]: 'Die erwartete Dauer der Vereinbarung und die zu erwartenden Vorteile aus der Entwicklung, Verbesserung oder Wartung des immateriellen Vermögenswerts.',
  [tokens.page.groupTransactionDetails.leaseOfTengibleAssetsDetails1]: 'Die spezifische Art der vermieteten Sachanlagen. Dazu gehören die Art der Anlagen (z. B. Ausrüstung, Maschinen, Fahrzeuge, Immobilien), ihr Zustand, ihre Nutzung und alle einzigartigen Eigenschaften, die ihre Bewertung beeinflussen können. Sowie die Laufzeit des Mietvertrags, Mietzahlungen, Verlängerungsoptionen, Wartungsverantwortlichkeiten und alle anderen relevanten Bestimmungen.',
  [tokens.page.groupTransactionDetails.distributionResaleOfGoodsDetails1]: 'Die spezifische Art der verteilten oder weiterverkauften Waren wie die Art der Waren (z. B. Fertigprodukte, Rohstoffe, Komponenten), ihre Merkmale, Qualität und alle einzigartigen Merkmale, die ihre Bewertung beeinflussen können. Dazu gehören der Umfang der Vereinbarung, die Preisgestaltung, Zahlungsbedingungen, Lieferbedingungen, territoriale Beschränkungen und alle anderen relevanten Bestimmungen.',
  [tokens.page.groupTransactionDetails.managementFeesDetails1]: 'Die spezifische Art der bereitgestellten Managementdienstleistungen, wie der Umfang der Dienstleistungen, das erforderliche Fachwissen und alle einzigartigen Eigenschaften, die die Bewertung der Dienstleistungen beeinflussen können. Dazu gehören der Umfang der Dienstleistungen, die Preisgestaltung, Zahlungsbedingungen, Leistungskennzahlen und alle anderen relevanten Bestimmungen.',

  // message constants
  [tokens.message.verifyEmailFirst]: 'Sie müssen zuerst Ihre E-Mail-Adresse überprüfen',
  [tokens.message.alreadyInvited]: 'Mitarbeiter hat eine ausstehende Einladung von der Firma:',
  [tokens.message.employeeAlreadyExist]: 'Existiert bereits in der Firma:',
  [tokens.message.invalidCredentials]: "Ungültige Anmeldedaten",
  [tokens.message.internalServerError]: "Interner Serverfehler",
  [tokens.message.notFound]: "Nicht gefunden",
  [tokens.message.alreadyExist]: "Existiert bereits",
  [tokens.message.savedSuccesfully]: "Erfolgreich gespeichert",
  [tokens.message.trialPeriodExpired]: "Probezeit abgelaufen",
  [tokens.message.subscriptionCreated]: "Abonnement erstellt",
  [tokens.message.paymentSuccesful]: "Zahlung erfolgreich!",
  [tokens.message.companyRegistrationSuccessful]:
    "Unternehmensregistrierung erfolgreich!",
  [tokens.message.invalidOTP]: "Ungültige OTP",
  [tokens.message.unsubscribed]: "Sie haben unser Angebot nicht erworben",
  [tokens.message.emailSent]: "E-Mail erfolgreich versendet",
  [tokens.message.emailNotSent]: "E-Mail konnte nicht versendet werden",
  [tokens.message.validToken]: "Token ist gültig",
  [tokens.message.inavlidToken]: "Token ist ungültig",
  [tokens.message.tokenValidationFailed]: "Authentifizierung fehlgeschlagen",
  [tokens.message.notAuthrized]: "Nicht autorisiert",
  [tokens.message.updatedSuccesfully]: "Erfolgreich aktualisiert",
  [tokens.message.companyNotFound]: "Unternehmen nicht gefunden",
  [tokens.message.currentPasswordIsWrong]: 'Das aktuelle Passwort ist falsch',
  [tokens.message.loggedOutSuccessfully]: 'Erfolgreich abgemeldet',

  [tokens.message.requiredFieldsEmpty]: 'Füllen Sie alle erforderlichen Felder aus',
  [tokens.message.removedSuccessfully]: 'Erfolgreich entfernt',
  [tokens.message.invitationRevokedSuccessfully]: 'Einladung erfolgreich widerrufen',
  [tokens.message.resetPasswordLinkSent]: 'Link zum Zurücksetzen des Passworts an Ihre E-Mail gesendet',
  [tokens.message.passwordAndConfirmPassMustMatch]: 'Passwort und Bestätigungspasswort müssen übereinstimmen',
  [tokens.message.passwordTooShort]: 'Das Passwort sollte mindestens 7 Zeichen lang sein',
  [tokens.message.passwordUpdated]: 'Passwort erfolgreich aktualisiert',
  [tokens.message.otpSentToEmail]: 'OTP an Ihre E-Mail gesendet',
  [tokens.message.photoUpdatedSuccessfully]: 'Foto erfolgreich aktualisiert',
  [tokens.message.employeeRoleUpdatedSuccessfully]: 'Rolle des Mitarbeiters erfolgreich aktualisiert',
  [tokens.message.companyNameUpdatedSuccessfully]: 'Firmenname erfolgreich aktualisiert',
  [tokens.message.nameUpdatedSuccessfully]: 'Name erfolgreich aktualisiert',
  [tokens.message.somethingWentWrong]: 'Etwas ist schief gelaufen',
  [tokens.message.countryFieldRequired]: 'Land ist erforderlich',
  [tokens.message.entityNameFieldRequired]: 'Entity-Name ist erforderlich',
  [tokens.message.entityCategoryFieldRequired]: 'Kategorie ist erforderlich',
  [tokens.message.entityUpdated]: 'Entity aktualisiert',
  [tokens.message.officeHolderCreated]: 'Benutzerdefinierter Büroinhabertyp erfolgreich erstellt',
  [tokens.message.entityDeleted]: 'Entity erfolgreich gelöscht',
  [tokens.message.departmentCreated]: 'Abteilung erfolgreich erstellt',
  [tokens.message.teamMemberUpdated]: 'Teammitglied aktualisiert',
  [tokens.message.transactionTypeRequired]: 'Transaktionstyp ist erforderlich',
  [tokens.message.contractTypeRequired]: 'Vertragstyp ist erforderlich',
  [tokens.message.contractFileAndFileTagRequired]: 'Laden Sie entweder eine Datei und einen Datei-Tag hoch oder wählen Sie \'Kein Argument verfügbar zum Hochladen\' aus',
  [tokens.message.entityFieldRequired]: 'Entität nicht ausgewählt',
  [tokens.message.transactionDeleted]: 'Transaktion gelöscht',
  [tokens.message.groupTransactionUpdatedSuccessfully]: 'Gruppentransaktion erfolgreich aktualisiert',

  [tokens.message.eventCreated]: 'Ereignis erstellt!',
  [tokens.message.eventUpdated]: 'Ereignis aktualisiert!',
  [tokens.message.eventDeleted]: 'Ereignis gelöscht!',
};

import type { AnyAction, PayloadAction} from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'src/store';
import { ThunkAction } from 'redux-thunk';
import { EMPLOYEE } from 'src/endpoints';
import { authRequestHandler } from 'src/utils/fetch';
import { NavigateFunction } from 'react-router';
interface loginLogsState {
    loginLogs :any
}

const initialState: loginLogsState = {
    loginLogs : []
};


export const slice = createSlice({
  name: 'loginLogs',
  initialState,
  reducers : {
    setLoginLogs(state: loginLogsState, action: PayloadAction<loginLogsState>): void {
        state.loginLogs = action.payload
    }
  }
});
type AsyncAction = ThunkAction<Promise<void>, RootState, undefined, AnyAction>;

export const getLoginLogs = (navigate: NavigateFunction): AsyncAction => async (dispatch: AppDispatch): Promise<void> => {
  const { response, resJson } = await authRequestHandler({
    url: EMPLOYEE.GET_EMPLOYEE_LOGIN_LOGS,
    navigate,
    method: 'GET',
  })
  if (response.ok){
    dispatch(slice.actions.setLoginLogs(resJson?.data));
  }
  };
  

export const { reducer } = slice;

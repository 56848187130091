import { lazy, Suspense } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';
import { AuthProtected } from 'src/guards/auth-protected';

import { Layout as DashboardLayout } from 'src/layouts/dashboard';

const IndexPage = lazy(() => import('src/pages/dashboard/index'));

// Entities
const EntitiesListPage = lazy(() => import('src/pages/dashboard/entities/list'));
const EntitiesAddPage = lazy(() => import('src/pages/dashboard/entities/add')); // create new entity
const EntitiesDetail = lazy(() => import('src/pages/dashboard/entities/detail')); // create new entity
const EntitiesEdit = lazy(() => import('src/pages/dashboard/entities/edit')); // create new entity

// Team Members
const TeamMemberListPage = lazy(() => import('src/pages/dashboard/teamMembers/list'));
const TeamMemberDetailPage = lazy(() => import('src/pages/dashboard/teamMembers/detail'));
const TeamMemberEditPage = lazy(() => import('src/pages/dashboard/teamMembers/edit'));

// group-transaction
const GroupTransaction = lazy(() => import('src/pages/dashboard/group-transaction/group-transaction'));
const GroupTransactionAdd = lazy(() => import('src/pages/dashboard/group-transaction/add'));
const GroupTransactionEdit = lazy(() => import('src/pages/dashboard/group-transaction/edit'));
const GroupTransactionDetail = lazy(() => import('src/pages/dashboard/group-transaction/detail'));

// Other
const EventsPage = lazy(() => import('src/pages/dashboard/events'));

const AccountPage = lazy(() => import('src/pages/dashboard/account'));

const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));

export const dashboardRoutes: RouteObject[] = [
  {
    path: 'dashboard',
    element: (
      <AuthProtected>
        <DashboardLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthProtected>
    ),
    children: [
      {
        index: true,
        element: <IndexPage />
      },
      {
        path: 'team-members',
        children: [
          {
            index: true,
            element: <TeamMemberListPage />
          },
          {
            path: ':teamMemberId',
            element: <TeamMemberDetailPage />
          },
          {
            path: 'edit/:teamMemberId',
            element: <TeamMemberEditPage />
          }
        ]
      },
      {
        path: 'entities',
        children: [
          {
            index: true,
            element: <EntitiesListPage />
          },
          {
            path: 'create',
            element: <EntitiesAddPage />
          },
          {
            path: ':entityId',
            element: <EntitiesDetail />
          },
          {
            path: 'edit/:entityId',
            element: <EntitiesEdit />
          },
        ]
      },
      {
        path: 'group-transactions',
        children: [
          {
            index: true,
            element: <GroupTransaction />
          },
          {
            path: 'add',
            element: <GroupTransactionAdd />
          },
          {
            path: ':transactionId',
            element: <GroupTransactionDetail />
          },
          {
            path: 'edit/:transactionId',
            element: <GroupTransactionEdit />
          },
        ]
      },
      {
        path: 'calendar',
        element: <EventsPage />
      },
      {
        path: 'account',
        element: <AccountPage />
      },
      {
        path: 'file-manager',
        element: <FileManagerPage />
      },
    ]
  }
];

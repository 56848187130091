import type { User } from "src/types/user";
import { decode } from "src/utils/jwt";
import { Company } from "src/interfaces/company.interface";
import { AUTH } from "src/endpoints";
import { Employee } from "src/interfaces/employee.interface";
import { requestHandler } from "src/utils/fetch";
import { NavigateFunction } from "react-router";
const STORAGE_KEY: string = "users";

// NOTE: We use sessionStorage since memory storage is lost after page reload.
//  This should be replaced with a server call that returns DB persisted data.

const getPersistedUsers = (): User[] => {
  try {
    const data = sessionStorage.getItem(STORAGE_KEY);

    if (!data) {
      return [];
    }

    return JSON.parse(data) as User[];
  } catch (err) {
    console.error(err);
    return [];
  }
};

type SignInRequest = {
  email: string;
  password: string;
  clientBrowser: string;
};

type SignInResponse = Promise<{
  accessToken: string;
}>;

type SignUpResponse = Promise<{
  accessToken?: string;
  tempToken?: string;
}>;

type EmployeeSignUpResponse = Promise<{
  redirectUrl?: string;
}>;

type MeRequest = {
  accessToken: string;
};

type MeResponse = Promise<User>;

class AuthApi {
  async signIn(
    request: SignInRequest,
    navigate: NavigateFunction
  ): SignInResponse {
    return new Promise(async (resolve, reject) => {
      try {
        const { response, resJson } = await requestHandler({
          url: AUTH.SIGNIN,
          method: "POST",
          navigate,
          body: JSON.stringify(request),
        });
        if (response.ok) {
          resolve({ accessToken: resJson.data.accessToken });
        } else {
          reject({status: response?.status, message: resJson?.error});
        }
      } catch (err) {
        console.error("[Auth Api]: 1", err);
        reject(new Error("Internal server error"));
      }
    });
  }

  async companySignUp(
    payload: Company,
    navigate: NavigateFunction
  ): SignUpResponse {
    return new Promise(async (resolve, reject) => {
      try {
        const { response, resJson } = await requestHandler({
          url: AUTH.SIGNUP,
          method: "post",
          navigate,
          body: JSON.stringify({ ...payload, paymentSuccessful: false }),
        });
        if (response.ok) {
          resolve({ tempToken: resJson.data.tempToken });
        } else if (response.status === 409) {
          // toast.error(resJson?.error)
          reject(new Error("Your work email must be a valid email"));
        }
      } catch (err) {
        console.error("[Auth Api]:2 ", err);
        reject(new Error(err.message));
      }
    });
  }

  async employeeSignup(
    payload: Employee,
    navigate: NavigateFunction
  ): EmployeeSignUpResponse {
    return new Promise(async (resolve, reject) => {
      try {
        const { response, resJson } = await requestHandler({
          url: AUTH.SIGNUP,
          method: "post",
          navigate,
          body: JSON.stringify({ ...payload }),
        });
        if (response.ok) {
          resolve({ redirectUrl: "/auth/login" });
        } else {
          resolve({ redirectUrl: "/invalid-token" });
        }
      } catch (error) {
        console.error("[Auth Api]:2 ", error);
        reject(new Error("Internal server error"));
      }
    });
  }

  // not implemented yet
  me(request: MeRequest): MeResponse {
    const { accessToken } = request;

    return new Promise((resolve, reject) => {
      try {
        // Decode access token
        const decodedToken = decode(accessToken) as any;

        // Merge static users (data file) with persisted users (browser storage)
        const mergedUsers = [...getPersistedUsers()];

        // Find the user
        const { userId } = decodedToken;
        const user = mergedUsers.find((user) => user.id === userId);

        if (!user) {
          reject(new Error("Invalid authorization token"));
          return;
        }

        resolve({
          id: user.id,
          avatar: user.avatar,
          email: user.email,
          name: user.name,
          plan: user.plan,
        });
      } catch (err) {
        console.error("[Auth Api]:3 ", err);
        reject(new Error("Internal server error"));
      }
    });
  }
}

export const authApi = new AuthApi();

import type { AnyAction, PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "src/store";
import { ThunkAction } from "redux-thunk";
import { COMPANY } from "src/endpoints";
import { Employee } from "src/interfaces/employee.interface";
import { ModuleAccess } from "src/interfaces/moduleAccess.interface";
import { tokens } from "src/locales/tokens";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { authRequestHandler } from "src/utils/fetch";
import { NavigateFunction } from "react-router";
interface EmployeeState {
  employees: Employee[];
  filteredEmployees: Employee[];
  isLoading: boolean
}

const initialState: EmployeeState = {
  employees: [],
  filteredEmployees: [],
  isLoading: true,
};

export const slice = createSlice({
  name: "companyEmployees",
  initialState,
  reducers: {
    setLoading(      
      state: EmployeeState,
      action: PayloadAction<boolean>
    ): void {
      state.isLoading = action.payload
    },
    setEmployees(
      state: EmployeeState,
      action: PayloadAction<Employee[]>
    ): void {
      state.employees = action.payload;
    },
    setEmployeeModuleAccesRights(
      state: EmployeeState,
      action: PayloadAction<{employeeId: string, moduleAccesRights: ModuleAccess[]}>
    ): void {
      const { employeeId, moduleAccesRights } = action.payload;

      state.employees = state.employees.map((item) => {
        if(item._id === employeeId){
          return {
            ...item,
            moduleAccessRights: moduleAccesRights,
          } as Employee
        }
        return item
      })

      state.filteredEmployees = state.filteredEmployees.map((item) => {
        if(item._id === employeeId){
          return {
            ...item,
            moduleAccessRights: moduleAccesRights,
          } as Employee
        }
        return item
      })

    },
    setFilteredEmployees(
      state: EmployeeState,
      action: PayloadAction<Employee[]>
    ): void {
      state.filteredEmployees = action.payload;
    },
  },
});

type AsyncAction = ThunkAction<Promise<void>, RootState, undefined, AnyAction>;

export const updateEmployeeModuleAccesRights =
  (
    employeeId: string, 
    moduleAccesRights: ModuleAccess[]
  ) => (dispatch: AppDispatch) => {
    dispatch(slice.actions.setEmployeeModuleAccesRights({employeeId, moduleAccesRights}))
  };

export const getEmployees =
  (navigate: NavigateFunction): AsyncAction =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(slice.actions.setLoading(true))
    const { response, resJson } = await authRequestHandler({
      method: 'GET',
      url:COMPANY.LIST_EMPLOYEES,
      navigate,
    })
    if(response.ok){
      dispatch(slice.actions.setEmployees(resJson?.data));
      dispatch(slice.actions.setFilteredEmployees(resJson?.data));
      dispatch(slice.actions.setLoading(false))
    }
  };

export const filterEmployees =
  (searchQuery: string, sortOrder: string): AsyncAction =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    const { employees } = getState().companyEmployees;

    // Implement your filtering logic here based on the searchQuery
    const filteredEmployees = employees.filter((employee) =>
      employee.fullName?.toLowerCase().includes(searchQuery.toLowerCase()) || 
      employee.email?.toLowerCase().includes(searchQuery.toLowerCase()) 
    );

    const sortedEmployees = filteredEmployees.slice().sort((a, b) => {
      const dateA = new Date(a.createdAt!).getTime();
      const dateB = new Date(b.createdAt!).getTime();

      if (sortOrder === "asc") {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });

    dispatch(slice.actions.setFilteredEmployees(sortedEmployees));
  };


export const { reducer } = slice;

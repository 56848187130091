import {
  Avatar,
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Link,
  Stack,
  SvgIcon,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import ArrowLeftIcon from "@untitled-ui/icons-react/build/esm/ArrowLeft";
import Edit02Icon from "@untitled-ui/icons-react/build/esm/Edit02";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RouterLink } from "src/components/router-link";
import { Seo } from "src/components/seo";
import { Employee } from "src/interfaces/employee.interface";
import { tokens } from "src/locales/tokens";
import { paths } from "src/paths";
import { RootState } from "src/store";
import { getInitials } from "src/utils/get-initials";

import Grid from '@mui/material/Unstable_Grid2';
import { TeamMemberBasicDetails } from "src/sections/dashboard/teamMember/teamMember-basic-details";
import { TeamMemberRoleDetails } from "src/sections/dashboard/teamMember/teamMember-role-details";
import { TeamMemberLocationDetails } from "src/sections/dashboard/teamMember/teamMember-location-details";
import { Country, State } from "country-state-city";
import { useNavigate } from "react-router";

const Page = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const employee: Employee = useSelector((state: RootState) => state.employeeAccount.employee);

  return (
    <>
      <Seo title={employee?.fullName} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={4}>
            <Stack spacing={4}>
              <div>
                <Link
                  color="text.primary"
                  onClick={() => navigate(-1)}
                  component={RouterLink}
                //   href={paths.dashboard.index}
                  sx={{
                    alignItems: "center",
                    display: "inline-flex",
                  }}
                  underline="hover"
                >
                  <SvgIcon sx={{ mr: 1 }}>
                    <ArrowLeftIcon />
                  </SvgIcon>
                  <Typography variant="subtitle2">
                    {t(tokens.page.back)}
                  </Typography>
                </Link>
              </div>
              <Stack
                alignItems="flex-start"
                direction={{
                  xs: "column",
                  md: "row",
                }}
                justifyContent="space-between"
                spacing={4}
              >
                <Stack alignItems="center" direction="row" spacing={2}>
                  <Avatar
                    src={employee?.photo || '#'}
                    sx={{
                      height: 64,
                      width: 64,
                    }}
                  >
                    {getInitials(employee?.fullName || '')}
                  </Avatar>
                  <Stack spacing={1}>
                    <Typography variant="h4">{employee?.fullName || ''}</Typography>
                    <Stack alignItems="center" direction="row" spacing={1}>
                      <Typography
                        variant="subtitle2"
                        sx={{ textTransform: "lowercase" }}
                      >
                        {t(tokens.page.teamMembersPage.teamMemberId)}:
                      </Typography>
                      <Chip label={employee?._id || ''} size="small" />
                    </Stack>
                    <Stack alignItems="center" direction="row" spacing={1}>
                      <Typography
                        variant="subtitle2"
                        sx={{ textTransform: "lowercase" }}
                      >
                        {t(tokens.page.teamMembersPage.email)}:
                      </Typography>
                      <Chip label={employee?.email || ''} size="small" />
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
              <div>
                <Grid container spacing={4}>
                  <Grid xs={12} lg={4}>
                    <TeamMemberBasicDetails
                      email={employee?.email}
                      phone={employee?.phone}
                    />
                  </Grid>
                  <Grid xs={12} lg={8}>
                    <Stack spacing={4}>
                      <TeamMemberRoleDetails
                        jobTitle={employee?.jobTitle}
                        department={employee?.departments
                          ?.map((item) =>
                            typeof item === "string" ? "-" : item.title
                          )
                          .join(", ")}
                        entity={
                          typeof employee.companyId === "string"
                            ? ""
                            : employee.companyId?.companyName
                        }
                      />
                    </Stack>
                  </Grid>
                  <Grid xs={12} lg={12}>
                    <Stack spacing={4}>
                      <TeamMemberLocationDetails
                        country={
                          Country?.getCountryByCode(
                            employee?.address?.[0]?.country || ""
                          )?.name
                        }
                        state={
                          State?.getStateByCodeAndCountry(
                            employee?.address?.[0]?.state || "",
                            employee?.address?.[0]?.country || ""
                          )?.name
                        }
                        city={employee?.address?.[0]?.city}
                        street={employee?.address?.[0]?.street}
                        zipCode={employee?.address?.[0]?.zipCode}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </div>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default Page;

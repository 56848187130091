import type { AnyAction, PayloadAction} from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'src/store';
import { ThunkAction } from 'redux-thunk';
import { EMPLOYEE } from 'src/endpoints';
import { TOKEN_VALIDATION_FAILED } from 'src/constants';
import toast from 'react-hot-toast';
import { authRequestHandler } from 'src/utils/fetch';
import { NavigateFunction } from 'react-router';
import { Employee } from 'src/interfaces/employee.interface';

interface AccessRights {
  isVisible: boolean;
  canEdit: boolean;
  haveAdminRights: boolean;
}
interface EmployeeState {
    employee:Employee
    
}

const initialState: EmployeeState = {
    employee:{}
};


export const slice = createSlice({
  name: 'employeeAccount',
  initialState,
  reducers : {
    setEmployeeAccounts(state: EmployeeState, action: PayloadAction<Employee>): void {
        state.employee = action.payload
    }
  }
});
type AsyncAction = ThunkAction<Promise<void>, RootState, undefined, AnyAction>;

export const getEmployeeAccounts = (navigate: NavigateFunction): AsyncAction => async (dispatch: AppDispatch): Promise<void> => {
  const { response, resJson } = await authRequestHandler({
    url: EMPLOYEE.GET_INFO,
    method: 'GET',
    navigate,
  })
  if (response.ok){
    dispatch(slice.actions.setEmployeeAccounts(resJson?.data));
  }
  };
  

export const { reducer } = slice;

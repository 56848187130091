import type { FC } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { Theme } from '@mui/material/styles/createTheme';

import { PropertyList } from 'src/components/property-list';
import { PropertyListItem } from 'src/components/property-list-item';
import { useTranslation } from 'react-i18next';
import { tokens } from 'src/locales/tokens';

interface TeamMemberLocationDetailsProps {
  country?: string;
  state?: string;
  city?: string;
  street?: string;
  zipCode?: string;
}

export const TeamMemberLocationDetails: FC<TeamMemberLocationDetailsProps> = (props) => {
  const { country, state, city, street, zipCode } = props;
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const { t } = useTranslation();

  const align = mdUp ? 'horizontal' : 'vertical';

  // not implemented
  return (
    <Card >
      <CardHeader title={`${t(tokens.page.teamMembersPage.locationDetails)}`} />
      <PropertyList>
        <PropertyListItem
          divider
          align={align}
          label={`${t(tokens.page.teamMembersPage.country)}`}
          value={country}
        />
        <PropertyListItem
          divider
          align={align}
          label={`${t(tokens.page.teamMembersPage.stateRegion)}`}
          value={state}
        />
        <PropertyListItem
          align={align}
          divider
          label={`${t(tokens.page.teamMembersPage.city)}`}
          value={city}
        />
        <PropertyListItem
          divider
          align={align}
          label={`${t(tokens.page.teamMembersPage.streetAddress)}`}
          value={street}
        />
        <PropertyListItem
          // divider
          align={align}
          label={`${t(tokens.page.teamMembersPage.zipCode)}`}
          value={zipCode}
        />
      </PropertyList>
    </Card>
  );
};

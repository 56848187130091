import type { AnyAction, PayloadAction} from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'src/store';
import { ThunkAction } from 'redux-thunk';
import { MODULE } from 'src/endpoints';
import { authRequestHandler } from 'src/utils/fetch';
import { NavigateFunction } from 'react-router';

interface ModuleState {
    modules :any
}

const initialState: ModuleState = {
    modules:[]
};

export const slice = createSlice({
  name: 'modules',
  initialState,
  reducers : {
    setModules(state: ModuleState, action: PayloadAction<ModuleState>): void {
        state.modules = action.payload
    }
  }
});
type AsyncAction = ThunkAction<Promise<void>, RootState, undefined, AnyAction>;

export const getModules = (navigate: NavigateFunction): AsyncAction => async (dispatch: AppDispatch): Promise<void> => {
  const { response, resJson } = await authRequestHandler({
    url: MODULE.LIST,
    navigate,
    method: 'GET'
  })
  if(response.ok){
    dispatch(slice.actions.setModules(resJson?.data));
  }
  };
  

export const { reducer } = slice;

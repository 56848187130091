import type { FC } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { Theme } from '@mui/material/styles/createTheme';

import { PropertyList } from 'src/components/property-list';
import { PropertyListItem } from 'src/components/property-list-item';
import { useTranslation } from 'react-i18next';
import { tokens } from 'src/locales/tokens';

interface TeamMemberRoleDetailsProps {
  jobTitle?: string;
  department?: string;
  entity?: string;
}

export const TeamMemberRoleDetails: FC<TeamMemberRoleDetailsProps> = (props) => {
  const { jobTitle, department, entity } = props;
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const { t } = useTranslation();

  const align = mdUp ? 'horizontal' : 'vertical';

  return (
    <Card {...props}>
      <CardHeader title={`${t(tokens.page.teamMembersPage.roleDetails)}`} />
      <PropertyList>
        <PropertyListItem
          align={align}
          divider
          label={`${t(tokens.page.teamMembersPage.jobTitle)}`}
          value={jobTitle}
        />
        <PropertyListItem
          align={align}
          divider
          label={`${t(tokens.page.teamMembersPage.department)}`}
          value={department}
        />
        <PropertyListItem
          align={align}
          label={`${t(tokens.page.teamMembersPage.entity)}`}
          value={entity}
        />
      </PropertyList>
    </Card>
  );
};

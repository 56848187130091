import { tokens } from "../tokens";

export const es = {
  [tokens.common.languageChanged]: 'Ha cambiado el idioma de la aplicación a español',
  [tokens.nav.groupTransactions]: 'Transacciones de grupo',
  [tokens.nav.academy]: "Academia",
  [tokens.nav.modules]: "Módulos",
  [tokens.nav.account]: "Cuenta",
  [tokens.nav.analytics]: "Analítica",
  [tokens.nav.auth]: "Autenticación",
  [tokens.nav.blog]: "Blog",
  [tokens.nav.browse]: "Buscar",
  [tokens.nav.calendar]: "Calendario",
  [tokens.nav.chat]: "Chat",
  [tokens.nav.checkout]: "Pago",
  [tokens.nav.concepts]: "Conceptos",
  [tokens.nav.contact]: "Contacto",
  [tokens.nav.course]: "Curso",
  [tokens.nav.create]: "Crear",
  [tokens.nav.crypto]: "Crypto",
  [tokens.nav.customers]: "Clientes",
  [tokens.nav.teamMembers]: "Miembros del Equipo.",
  [tokens.nav.entities]: "Entidades",
  [tokens.nav.dashboard]: "Dashboard",
  [tokens.nav.details]: "Detalles",
  [tokens.nav.ecommerce]: "E-Commerce",
  [tokens.nav.edit]: "Editar",
  [tokens.nav.error]: "Error",
  [tokens.nav.feed]: "Fuente social",
  [tokens.nav.fileManager]: "Administrador de Archivos",
  [tokens.nav.finance]: "Finanzas",
  [tokens.nav.fleet]: "Parque",
  [tokens.nav.forgotPassword]: "Recuperar Contraseña",
  [tokens.nav.invoiceList]: "Facturas",
  [tokens.nav.jobList]: "Listado de Trabajos",
  [tokens.nav.kanban]: "Kanban",
  [tokens.nav.list]: "Lista",
  [tokens.nav.login]: "Acceso",
  [tokens.nav.logistics]: "Logística",
  [tokens.nav.mail]: "Correo",
  [tokens.nav.management]: "Gestión",
  [tokens.nav.orderList]: "Pedidos",
  [tokens.nav.overview]: "Visión general",
  [tokens.nav.pages]: "Páginas",
  [tokens.nav.postCreate]: "Crear Articulo",
  [tokens.nav.postDetails]: "Detalles del Articulo",
  [tokens.nav.postList]: "Lista de Articulos",
  [tokens.nav.pricing]: "Precios",
  [tokens.nav.productList]: "Productos",
  [tokens.nav.profile]: 'Perfil',
  [tokens.nav.billing]: 'Facturación',
  [tokens.nav.logout]: 'Cerrar sesión',
  [tokens.nav.register]: "Registrarse",
  [tokens.nav.resetPassword]: "Restablecer Contraseña",
  [tokens.nav.socialMedia]: "Redes Sociales",
  [tokens.nav.verifyCode]: "Verificar Código",
  [tokens.nav.viewEntities]: 'Ver entidades',
  [tokens.nav.addEntity]: 'Agregar entidad',
  [tokens.nav.viewTeamMembers]: 'Ver miembros del equipo',
  [tokens.nav.yourProfile]: 'Tu perfil',
  [tokens.nav.editYourProfile]: 'Editar tu perfil',
  [tokens.nav.viewTransactions]: 'Ver transacciones del grupo',
  [tokens.nav.addTransaction]: 'Agregar transacción de grupo',
  [tokens.nav.newEvent]: "Nuevo Evento",
  [tokens.nav.agenda]: "Agenda",

  // pages content
  [tokens.page.back]: 'Volver',
  [tokens.page.dashboard]: 'Tablero',
  [tokens.page.teamMembers]: "Miembros del Equipo.",
  [tokens.page.search]: "Buscar",

  [tokens.page.overview.overview]: "Visión general",
  [tokens.page.overview.entities]: "Entidades del grupo",
  [tokens.page.overview.teamMembers]: "Miembros del equipo",
  [tokens.page.overview.groupTransactions]: "Transacciones del grupo",
  [tokens.page.overview.seeAllEntities]: "Ver todas las entidades",
  [tokens.page.overview.seeAllTeamMembers]: "Ver todos los miembros del equipo",
  [tokens.page.overview.seeAllGroupTransactions]: "Ver todas las transacciones del grupo",
  [tokens.page.overview.welcome]: "Bienvenido",
  [tokens.page.overview.startingWithOperatingPRO]: "Comenzando con Operating PRO",
  [tokens.page.overview.startingWithOperatingPROBody]: "Sea guiado a través de un enfoque proactivo para el cumplimiento intercompany. Ahorrándole tiempo, dinero y posibles dolores de cabeza en el futuro.",
  [tokens.page.overview.inviteTeamMemberTitle]: "Invitar a un miembro del equipo",
  [tokens.page.overview.inviteTeamMemberBody]: "Los administradores pueden otorgar a los usuarios invitados controles de acceso y delegar tareas específicas para su completación. Revise su trabajo utilizando flujos de aprobación.",
  [tokens.page.overview.verifyYourEmailTitle]: "Verificar su correo electrónico",
  [tokens.page.overview.verifyYourEmailBody]: "Para garantizar la integridad de nuestros sistemas de correo electrónico, se le pedirá que verifique su correo electrónico la primera vez que invite a un miembro del equipo.",
  [tokens.page.overview.addYourFirstEntityTitle]: "Agregue su primera entidad",
  [tokens.page.overview.addYourFirstEntityBody]: "Comience a construir su estructura de grupo agregando su primera entidad, ya sea su empresa matriz o una subsidiaria en el extranjero.",
  [tokens.page.overview.addYourSecondEntityTitle]: "Agregue su segunda entidad",
  [tokens.page.overview.addYourSecondEntityBody]: "Las entidades en su grupo pueden tomar la forma de una empresa, un fideicomiso, una asociación, un comerciante único, una organización sin fines de lucro, una cooperativa o una sucursal.",
  [tokens.page.overview.addIntercompanyGroupTransactionTitle]: "Agregar una transacción de grupo interempresa",
  [tokens.page.overview.addIntercompanyGroupTransactionBody]: "Documente las transacciones entre sus entidades utilizando el módulo de Transacciones de Grupo.",
  [tokens.page.overview.upgrade]: "Actualizar",
  [tokens.page.overview.upgradeYourAccountTitle]: "Actualice su cuenta.",
  [tokens.page.overview.upgradeYourAccountBody]: "Desbloquee funciones exclusivas como CbCR, Transfer Pricing Local Files y Ajustes de fin de año.",
  [tokens.page.overview.supportCenter]: "Centro de soporte",
  [tokens.page.overview.needHelpFiguringThingsOut]: "¿Necesita ayuda para entender las cosas?",
  [tokens.page.overview.needHelpFiguringThingsOutBody]: "¿No está seguro o tiene preguntas? Nuestro equipo de expertos está aquí para ayudarlo. Contáctenos para obtener ayuda.",
  [tokens.page.overview.support]: "Apoyo",
  [tokens.page.overview.upcomingEvents]: "Próximos eventos",
  [tokens.page.overview.basedOnCalendarModule]: "Basado en el módulo de calendario",
  [tokens.page.overview.seeAll]: "Ver todo",
  [tokens.page.overview.noUpcomingEvents]: 'No hay eventos próximos, añadir nuevo evento',
  [tokens.page.overview.newEvent]: 'Nuevo evento',

  [tokens.page.accountPage.account]: 'Cuenta',
  [tokens.page.accountPage.generalTab]: 'General',
  [tokens.page.accountPage.billingTab]: 'Facturación',
  [tokens.page.accountPage.notificationsTab]: 'Notificaciones',
  [tokens.page.accountPage.teamTab]: 'Equipo',
  [tokens.page.accountPage.securityTab]: 'Seguridad',
  [tokens.page.accountPage.basicDetails]: 'Detalles básicos',
  [tokens.page.accountPage.select]: 'Seleccionar',
  [tokens.page.accountPage.companyName]: 'Nombre de la empresa',
  [tokens.page.accountPage.save]: 'Guardar',
  [tokens.page.accountPage.fullName]: 'Nombre completo',
  [tokens.page.accountPage.emailAddress]: 'Dirección de correo electrónico',
  [tokens.page.accountPage.roleDetails]: 'Detalles del rol',
  [tokens.page.accountPage.jobTitle]: 'Cargo',
  [tokens.page.accountPage.departments]: 'Departamentos',
  [tokens.page.accountPage.entity]: 'Entidad',
  [tokens.page.accountPage.locationDetails]: 'Detalles de la ubicación',
  [tokens.page.accountPage.basedIn]: 'Con sede en',
  [tokens.page.accountPage.localTime]: 'Hora local',
  [tokens.page.accountPage.preferredLanguage]: 'Idioma preferido',
  [tokens.page.accountPage.changePlan]: 'Cambiar plan',
  [tokens.page.accountPage.changePlanSubHeader]: 'Actualiza cuando estés listo',
  [tokens.page.accountPage.year]: 'año',
  [tokens.page.accountPage.usingNow]: 'Usando actualmente',
  [tokens.page.accountPage.billingDetails]: 'Detalles de facturación',
  [tokens.page.accountPage.cardNumber]: 'Número de tarjeta',
  [tokens.page.accountPage.name]: 'Nombre',
  [tokens.page.accountPage.notRequiredForTrialPeriod]: 'No requerido para el período de prueba',
  [tokens.page.accountPage.notProvided]: 'No proporcionado',
  [tokens.page.accountPage.trialEndDate]: 'Fecha de finalización de la prueba',
  [tokens.page.accountPage.upgradePlan]: 'Actualizar plan',
  [tokens.page.accountPage.autoRenewText1]: 'Su suscripción anual se renueva automáticamente para su conveniencia, pero usted tiene el control',
  [tokens.page.accountPage.autoRenewText2]: 'Cancelar la renovación automática',
  [tokens.page.accountPage.autoRenewText3]: 'en cualquier momento.',
  [tokens.page.accountPage.inviteTeamMembers]: 'Invitar miembros del equipo',
  [tokens.page.accountPage.inviteUpTo50TeamMembers]: 'Puedes invitar hasta 50 miembros del equipo.',
  [tokens.page.accountPage.inviteUpTo50TeamMembers]: 'Puedes invitar hasta 150 miembros del equipo.',
  [tokens.page.accountPage.sendInvite]: 'Enviar invitación',
  [tokens.page.accountPage.searchTeamMembers]: 'Buscar miembros del equipo',
  [tokens.page.accountPage.sortBy]: 'Ordenar por',
  [tokens.page.accountPage.oldest]: 'Más antiguo',
  [tokens.page.accountPage.newest]: 'Más nuevo',
  [tokens.page.accountPage.teamMembers]: 'Miembros del equipo',
  [tokens.page.accountPage.location]: 'Ubicación',
  [tokens.page.accountPage.role]: 'Rol',
  [tokens.page.accountPage.status]: 'Estado',
  [tokens.page.accountPage.actions]: 'Acciones',
  [tokens.page.accountPage.changeRole]: 'Cambiar rol',
  [tokens.page.accountPage.remove]: 'Eliminar',
  [tokens.page.accountPage.revokeInvite]: 'Revocar invitación',
  [tokens.page.accountPage.changePassword]: 'Cambiar contraseña',
  [tokens.page.accountPage.currentPassword]: 'Contraseña actual',
  [tokens.page.accountPage.newPassword]: 'Nueva contraseña',
  [tokens.page.accountPage.newConfirmPassword]: 'Confirmar nueva contraseña',
  [tokens.page.accountPage.password]: 'Contraseña',
  [tokens.page.accountPage.cancel]: 'Cancelar',
  [tokens.page.accountPage.edit]: 'Editar',
  [tokens.page.accountPage.deleteAccount]: 'Eliminar cuenta',
  [tokens.page.accountPage.deleteAccountSubTitle]: 'Eliminar tu cuenta y todos tus datos fuente. Esto es irreversible.',
  [tokens.page.accountPage.loginHistory]: 'Historial de inicio de sesión',
  [tokens.page.accountPage.loginHistorySubtitle]: 'Su actividad de inicio de sesión reciente',
  [tokens.page.accountPage.deviceTime]: 'Dispositivo y hora',
  [tokens.page.accountPage.IPAddress]: 'Dirección IP',
  [tokens.page.accountPage.email]: 'Correo electrónico',
  [tokens.page.accountPage.productUpdates]: 'Actualizaciones del producto',
  [tokens.page.accountPage.newsAnnouncementsAndProductUpdates]: 'Noticias, anuncios y actualizaciones del producto.',
  [tokens.page.accountPage.securityUpdates]: 'Actualizaciones de seguridad',
  [tokens.page.accountPage.NotificationsAboutAccountSecurity]: 'Notificaciones importantes sobre la seguridad de su cuenta.',
  [tokens.page.accountPage.phoneNotifications]: 'Notificaciones por teléfono',
  [tokens.page.accountPage.removeEmployeeTitle]: 'Mitarbeiter entfernen',
  [tokens.page.accountPage.removeEmployeeBody]: 'Sind Sie sicher, dass Sie diesen Mitarbeiter aus Ihrem Unternehmen löschen möchten?',
  [tokens.page.accountPage.revokeEmployeeTitle]: 'Mitarbeiter-Einladung widerrufen',
  [tokens.page.accountPage.revokeEmployeeBody]: 'Sind Sie sicher, dass Sie die Einladung dieses Mitarbeiters aus Ihrem Unternehmen widerrufen möchten?',
  [tokens.page.accountPage.revoke]: 'Widerrufen',
  
  [tokens.page.entityListPage.entities]: 'Entidades',
  [tokens.page.entityListPage.export]: 'Exportar',
  [tokens.page.entityListPage.add]: 'Agregar',
  [tokens.page.entityListPage.all]: 'Todos',
  [tokens.page.entityListPage.searchEntities]: 'Buscar entidades',
  [tokens.page.entityListPage.sortBy]: 'Ordenar por',
  [tokens.page.entityListPage.newest]: 'Más nuevo',
  [tokens.page.entityListPage.oldest]: 'Más antiguo',
  [tokens.page.entityListPage.delete]: 'Eliminar',
  [tokens.page.entityListPage.edit]: 'Editar',
  [tokens.page.entityListPage.title]: 'Título',
  [tokens.page.entityListPage.location]: 'Ubicación',
  [tokens.page.entityListPage.reportingDate]: 'Fecha de reporte',
  [tokens.page.entityListPage.status]: 'Estado',
  [tokens.page.entityListPage.actions]: 'Acciones',
  [tokens.page.entityListPage.rowPerPage]: 'Filas por página',

  [tokens.page.entityAddPage.addEntity]: 'Agregar entidad',
  [tokens.page.entityAddPage.entityAdded]: 'Entidad agregada',
  [tokens.page.entityAddPage.type]: 'Tipo',
  [tokens.page.entityAddPage.country]: 'País',
  [tokens.page.entityAddPage.registration]: 'Registro',
  [tokens.page.entityAddPage.selectMostSuitable]: 'Seleccionar la más adecuada',
  [tokens.page.entityAddPage.continue]: 'Continuar',
  [tokens.page.entityAddPage.companyCorporation]: 'Empresa/Corporación',
  [tokens.page.entityAddPage.trust]: 'Fideicomiso',
  [tokens.page.entityAddPage.partnership]: 'Asociación',
  [tokens.page.entityAddPage.soleProprietorshipSoleTrader]: 'Empresa individual/Comerciante único',
  [tokens.page.entityAddPage.nonProfitOrganizationFoundation]: 'Organización sin fines de lucro/Fundación',
  [tokens.page.entityAddPage.coOperative]: 'Cooperativa',
  [tokens.page.entityAddPage.overseasBranch]: 'Sucursal en el extranjero',
  [tokens.page.entityAddPage.whichCountryWasItEstablishedIn]: '¿En qué país se estableció?',
  [tokens.page.entityAddPage.enterCountry]: 'Ingresar un país',
  [tokens.page.entityAddPage.selectedCountry]: 'País seleccionado',
  [tokens.page.entityAddPage.back]: 'Volver',
  [tokens.page.entityAddPage.whatIsFullLegalNameOfEntity]: '¿Cuál es el nombre legal completo de la entidad?',
  [tokens.page.entityAddPage.entityName]: 'Nombre de la entidad',
  [tokens.page.entityAddPage.entityNumber]: 'Número de entidad',
  [tokens.page.entityAddPage.whatWasOfficialDateOfRegistration]: '¿Cuál fue la fecha oficial de registro?',
  [tokens.page.entityAddPage.registrationDate]: 'Fecha de registro',
  [tokens.page.entityAddPage.createEntity]: 'Crear entidad',
  [tokens.page.entityAddPage.allDone]: '¡Todo listo!',
  [tokens.page.entityAddPage.previewOfEntity]: 'Aquí hay una vista previa de la entidad que acabas de agregar.',
  [tokens.page.entityAddPage.view]: 'Ver',
  [tokens.page.entityAddPage.addAnotherEntity]: 'Agregar otra entidad',

  [tokens.page.entityDetailsPage.entities]: 'Entidades',
  [tokens.page.entityDetailsPage.entityId]: 'ID de entidad',
  [tokens.page.entityDetailsPage.edit]: 'Editar',
  [tokens.page.entityDetailsPage.name]: 'Nombre',
  [tokens.page.entityDetailsPage.entityNumber]: 'Número de entidad',
  [tokens.page.entityDetailsPage.entityType]: 'Tipo de entidad',
  [tokens.page.entityDetailsPage.status]: 'Estado',
  [tokens.page.entityDetailsPage.registrationDate]: 'Fecha de registro',
  [tokens.page.entityDetailsPage.yearlyReportingDate]: 'Fecha de informe anual',
  [tokens.page.entityDetailsPage.locationDetails]: 'Detalles de ubicación',
  [tokens.page.entityDetailsPage.country]: 'País',
  [tokens.page.entityDetailsPage.stateRegion]: 'Estado/Región',
  [tokens.page.entityDetailsPage.licenceType]: 'Tipo de licencia',
  [tokens.page.entityDetailsPage.licence]: 'Licencia',
  [tokens.page.entityDetailsPage.licenceNumber]: 'Número de licencia',
  [tokens.page.entityDetailsPage.regulatoryBody]: 'Organismo regulador',
  [tokens.page.entityDetailsPage.state]: 'Estado',
  [tokens.page.entityDetailsPage.officeHolder]: 'Titular de la oficina',
  [tokens.page.entityDetailsPage.type]: 'Tipo',
  [tokens.page.entityDetailsPage.shareholder]: 'Accionista',
  [tokens.page.entityDetailsPage.numberOfSharesHeld]: 'Número de acciones mantenidas',
  [tokens.page.entityDetailsPage.countryOfResidence]: 'País de residencia',
  [tokens.page.entityDetailsPage.passport]: 'Pasaporte',
  [tokens.page.entityDetailsPage.politicallyExposedPerson]: 'Persona políticamente expuesta',
  [tokens.page.entityDetailsPage.personWithSignificantControl]: 'Persona con control significativo',
  [tokens.page.entityDetailsPage.personWithSignificantControlOptionYes]: 'Sí, Persona con control significativo (PSC)',
  [tokens.page.entityDetailsPage.personWithSignificantControlOptionNo]: 'No es una Persona con control significativo (PSC)',
  [tokens.page.entityDetailsPage.politicallyExposedPersonYes]: 'Sí, Persona políticamente expuesta (PEP)',
  [tokens.page.entityDetailsPage.politicallyExposedPersonNo]: 'No es una Persona políticamente expuesta (PEP)',

  [tokens.page.entityEditPage.entities]: 'Entidades',
  [tokens.page.entityEditPage.select]: 'Seleccionar',
  [tokens.page.entityEditPage.entityId]: 'ID de entidad',
  [tokens.page.entityEditPage.basicDetails]: 'Detalles básicos',
  [tokens.page.entityEditPage.name]: 'Nombre',
  [tokens.page.entityEditPage.entityNumber]: 'Número de entidad',
  [tokens.page.entityEditPage.country]: 'País',
  [tokens.page.entityEditPage.state]: 'Estado',
  [tokens.page.entityEditPage.typeStatus]: 'Tipo y estado',
  [tokens.page.entityEditPage.entityType]: 'Tipo de entidad',
  [tokens.page.entityEditPage.entityStatus]: 'Estado de la entidad',
  [tokens.page.entityEditPage.registrationReportingDate]: 'Fecha de registro e informe',
  [tokens.page.entityEditPage.registrationDate]: 'Fecha de registro',
  [tokens.page.entityEditPage.yearlyReportingDate]: 'Fecha de informe anual',
  [tokens.page.entityEditPage.officeHolders]: 'Titulares de la oficina',
  [tokens.page.entityEditPage.shareholders]: 'Accionistas',
  [tokens.page.entityEditPage.licence]: 'Licencia(s)',
  [tokens.page.entityEditPage.update]: 'Actualizar',
  [tokens.page.entityEditPage.cancel]: 'Cancelar',
  [tokens.page.entityEditPage.licenceType]: 'Tipo de licencia',
  [tokens.page.entityEditPage.licenceNumber]: 'Número de licencia',
  [tokens.page.entityEditPage.regulatoryBody]: 'Organismo regulador',
  [tokens.page.entityEditPage.add]: 'Agregar',
  [tokens.page.entityEditPage.officeHolderType]: 'Tipo de titular de la oficina',
  [tokens.page.entityEditPage.addCustom]: 'Agregar personalizado',
  [tokens.page.entityEditPage.shareHolderType]: 'Tipo de accionista',
  [tokens.page.entityEditPage.numberOfSharesHeld]: 'Número de acciones mantenidas',
  [tokens.page.entityEditPage.countryOfResidence]: 'País de residencia',
  [tokens.page.entityEditPage.individual]: 'Individual',
  [tokens.page.entityEditPage.companyCorporation]: 'Corporación empresarial',
  [tokens.page.entityEditPage.trust]: 'Fideicomiso',
  [tokens.page.entityEditPage.partnership]: 'Sociedad',
  [tokens.page.entityEditPage.soleProprietorshipSoleTrader]: 'Empresario individual',
  [tokens.page.entityEditPage.nonProfitOrganizationFoundation]: 'Organización sin fines de lucro/Fundación',
  [tokens.page.entityEditPage.coOperative]: 'Cooperativa',
  [tokens.page.entityEditPage.overseasBranch]: 'Sucursal en el extranjero',
  [tokens.page.entityEditPage.active]: 'Activo',
  [tokens.page.entityEditPage.dormant]: 'Inactivo',
  [tokens.page.entityEditPage.suspended]: 'Suspendido',
  [tokens.page.entityEditPage.administration]: 'Administración',
  [tokens.page.entityEditPage.receivership]: 'Administración judicial',
  [tokens.page.entityEditPage.liquidation]: 'Liquidación',
  [tokens.page.entityEditPage.deregistered]: 'Dado de baja',
  [tokens.page.entityEditPage.politicallyExposedPerson]: 'Persona políticamente expuesta',
  [tokens.page.entityEditPage.personWithSignificantControl]: 'Persona con control significativo',
  [tokens.page.entityEditPage.personWithSignificantControlOptionYes]: 'Sí, Persona con control significativo (PSC)',
  [tokens.page.entityEditPage.personWithSignificantControlOptionNo]: 'No es una Persona con control significativo (PSC)',
  [tokens.page.entityEditPage.politicallyExposedPersonYes]: 'Sí, Persona políticamente expuesta (PEP)',
  [tokens.page.entityEditPage.politicallyExposedPersonNo]: 'No es una Persona políticamente expuesta (PEP)',
  [tokens.page.entityEditPage.entityTimezone]: 'Zona horaria de la entidad',
  [tokens.page.entityEditPage.entityTeamSize]: 'Tamaño del equipo de la entidad',
  [tokens.page.entityEditPage.entityCurrency]: 'Moneda de la entidad',
  [tokens.page.entityEditPage.functionalCurrency]: 'Moneda funcional',
  [tokens.page.entityEditPage.presentationCurrency]: 'Moneda de presentación',
  [tokens.page.entityEditPage.industry]: 'Industria',
  [tokens.page.entityEditPage.industryGroup]: 'Grupo de industria',
  [tokens.page.entityEditPage.industryDivision]: 'División de la industria',
  [tokens.page.entityEditPage.industrySubclass]: 'Subclase de la industria',

  [tokens.page.fileManager.fileManager]: 'Gestor de archivos',
  [tokens.page.fileManager.back]: 'Volver',
  [tokens.page.fileManager.deleteFileTitle]: '¿Eliminar archivo?',
  [tokens.page.fileManager.deleteFileBody]: '¿Estás seguro de que quieres eliminar este archivo?',
  [tokens.page.fileManager.cancel]: 'Cancelar',
  [tokens.page.fileManager.delete]: 'Eliminar',
  [tokens.page.fileManager.search]: 'Buscar',
  [tokens.page.fileManager.sortBy]: 'Ordenar por',
  [tokens.page.fileManager.latest]: 'Más reciente',
  [tokens.page.fileManager.oldest]: 'Más antiguo',
  [tokens.page.fileManager.byFavorites]: 'Por favoritos',
  [tokens.page.fileManager.rowPerPage]: 'Elementos por página',
  [tokens.page.fileManager.items]: 'elementos',
  [tokens.page.fileManager.updatedAt]: 'Actualizado el',
  [tokens.page.fileManager.copyLink]: 'Copiar enlace',

  [tokens.page.teamMembersPage.name]: "Nombre",
  [tokens.page.teamMembersPage.localTime]: "Hora Local",
  [tokens.page.teamMembersPage.location]: "Ubicación",
  [tokens.page.teamMembersPage.department]: "Departamento",
  [tokens.page.teamMembersPage.entity]: "Entidad",
  [tokens.page.teamMembersPage.actions]: "Acciones",
  [tokens.page.teamMembersPage.rowPerPage]: "Filas por página",
  [tokens.page.teamMembersPage.edit]: "Editar",
  [tokens.page.teamMembersPage.email]: "Correo Electrónico",
  [tokens.page.teamMembersPage.details]: "Detalles",
  [tokens.page.teamMembersPage.confidential]: 'Confidencial',
  [tokens.page.teamMembersPage.basicDetails]: "Detalles Básicos",
  [tokens.page.teamMembersPage.roleDetails]: "Detalles del Rol",
  [tokens.page.teamMembersPage.locationDetails]: "Detalles de Ubicación",
  [tokens.page.teamMembersPage.phone]: "Teléfono",
  [tokens.page.teamMembersPage.country]: "País",
  [tokens.page.teamMembersPage.stateRegion]: "Estado/Región",
  [tokens.page.teamMembersPage.streetAddress]: "Dirección",
  [tokens.page.teamMembersPage.zipCode]: "Código Postal",
  [tokens.page.teamMembersPage.jobTitle]: "Cargo",
  [tokens.page.teamMembersPage.resetPassword]: "Restablecer Contraseña",
  [tokens.page.teamMembersPage.userId]: "ID de Usuario",
  [tokens.page.teamMembersPage.fullName]: "Nombre Completo",
  [tokens.page.teamMembersPage.departments]: "Departamentos",
  [tokens.page.teamMembersPage.dateFormat]: "Formato de Fecha",
  [tokens.page.teamMembersPage.preferredLanguage]: "Idioma Preferido",
  [tokens.page.teamMembersPage.timeZone]: "Zona Horaria",
  [tokens.page.teamMembersPage.city]: "Ciudad",
  [tokens.page.teamMembersPage.teamMemberId]: "ID de miembro del equipo",
  [tokens.page.teamMembersPage.update]: 'Actualizar',
  [tokens.page.teamMembersPage.cancel]: 'Cancelar',

  [tokens.page.fileUploaderModal.uploadFile]: 'Subir archivo',
  [tokens.page.fileUploaderModal.fileTag]: 'etiqueta de archivo',
  [tokens.page.fileUploaderModal.remove]: 'Eliminar',
  [tokens.page.fileUploaderModal.removeAll]: 'Eliminar todo',
  [tokens.page.fileUploaderModal.Uploading]: 'Subiendo',
  [tokens.page.fileUploaderModal.upload]: 'Subir',
  [tokens.page.fileUploaderModal.clickToUpload]: 'Haz clic para subir',
  [tokens.page.fileUploaderModal.dragAndDrop]: 'o arrastra y suelta',
  [tokens.page.fileUploaderModal.maxFileSize]: 'El tamaño máximo del archivo es de 20 MB',

  [tokens.page.calendarPage.newEvent]: 'Nuevo evento',
  [tokens.page.calendarPage.view]: 'Ver',
  [tokens.page.calendarPage.viewOptions.month]: 'Mes',
  [tokens.page.calendarPage.viewOptions.week]: 'Semana',
  [tokens.page.calendarPage.viewOptions.day]: 'Día',
  [tokens.page.calendarPage.viewOptions.agenda]: 'Agenda',
  [tokens.page.calendarPage.months.january]: 'Enero',
  [tokens.page.calendarPage.months.february]: 'Febrero',
  [tokens.page.calendarPage.months.march]: 'Marzo',
  [tokens.page.calendarPage.months.april]: 'Abril',
  [tokens.page.calendarPage.months.may]: 'Mayo',
  [tokens.page.calendarPage.months.june]: 'Junio',
  [tokens.page.calendarPage.months.july]: 'Julio',
  [tokens.page.calendarPage.months.august]: 'Agosto',
  [tokens.page.calendarPage.months.september]: 'Septiembre',
  [tokens.page.calendarPage.months.october]: 'Octubre',
  [tokens.page.calendarPage.months.november]: 'Noviembre',
  [tokens.page.calendarPage.months.december]: 'Diciembre',
  [tokens.page.calendarPage.updateConfirmationModal.title]: '¿Actualizar evento?',
  [tokens.page.calendarPage.updateConfirmationModal.body]: '¿Estás seguro de que deseas actualizar la hora de este evento?',
  [tokens.page.calendarPage.updateConfirmationModal.cancelText]: 'Cancelar',
  [tokens.page.calendarPage.updateConfirmationModal.confirmText]: 'Actualizar',
  [tokens.page.calendarPage.eventDialog.editEvent]: 'Editar evento',
  [tokens.page.calendarPage.eventDialog.addEvent]: 'Agregar evento',
  [tokens.page.calendarPage.eventDialog.title]: 'Título',
  [tokens.page.calendarPage.eventDialog.description]: 'Descripción',
  [tokens.page.calendarPage.eventDialog.entity]: 'Entidad',
  [tokens.page.calendarPage.eventDialog.timeZone]: 'Zona horaria',
  [tokens.page.calendarPage.eventDialog.startDate]: 'Fecha de inicio',
  [tokens.page.calendarPage.eventDialog.endDate]: 'Fecha de finalización',
  [tokens.page.calendarPage.eventDialog.allDay]: 'Todo el día',
  [tokens.page.calendarPage.eventDialog.type]: 'Tipo',
  [tokens.page.calendarPage.eventDialog.regulatoryFilingDeadlineTitle]: 'Fecha límite de presentación regulatoria',
  [tokens.page.calendarPage.eventDialog.regulatoryFilingDeadlineDescription]: 'Fecha límite para presentar informes anuales, declaraciones de impuestos o certificaciones de cumplimiento.',
  [tokens.page.calendarPage.eventDialog.significantEntityMilestoneTitle]: 'Hitos significativos de la entidad',
  [tokens.page.calendarPage.eventDialog.significantEntityMilestoneDescription]: 'Fecha de fin de período fiscal, lanzamiento de un nuevo producto o servicio, o finalización de un proyecto importante.',
  [tokens.page.calendarPage.eventDialog.otherImportantEventsTitle]: 'Otros eventos importantes',
  [tokens.page.calendarPage.eventDialog.otherImportantEventsDescription]: 'Eventos que no se clasifican en las otras categorías: cumpleaños de empleados, reuniones de equipo o conferencias de la industria.',
  [tokens.page.calendarPage.eventDialog.cancel]: 'Cancelar',
  [tokens.page.calendarPage.eventDialog.confirm]: 'Confirmar',
  [tokens.page.calendarPage.deleteModal.title]: '¿Eliminar evento?',
  [tokens.page.calendarPage.deleteModal.body]: '¿Estás seguro de que deseas eliminar este evento?',
  [tokens.page.calendarPage.deleteModal.cancelText]: 'Cancelar',
  [tokens.page.calendarPage.deleteModal.confirmText]: 'Eliminar',
  [tokens.page.calendarPage.yearPicker.buttonText]: 'Personalizado',
  [tokens.page.calendarPage.yearPicker.cancel]: 'Cancelar',
  [tokens.page.calendarPage.yearPicker.ok]: 'Aceptar',

  [tokens.page.groupTransactionList.headerTitle]: 'Eliminar distracciones. Concéntrate en tu negocio.', 
  [tokens.page.groupTransactionList.headerBody]: 'Gestionar las interacciones entre entidades en tu grupo es fundamental para la empresa tributaria internacional.', 
  [tokens.page.groupTransactionList.addGroupTransaction]: 'Agregar transacción de grupo',
  [tokens.page.groupTransactionList.itemsPerPage]: 'Elementos por página',
  [tokens.page.groupTransactionList.enterKeyword]: 'Ingrese una palabra clave',
  [tokens.page.groupTransactionList.noFiltersApplied]: 'No se aplicaron filtros',
  [tokens.page.groupTransactionList.activeOnly]: 'Solo activo',
  [tokens.page.groupTransactionList.type]: 'Tipo',
  [tokens.page.groupTransactionList.entity]: 'Entidad',
  [tokens.page.groupTransactionList.country]: 'País',
  [tokens.page.groupTransactionList.attachment]: 'Adjunto',
  [tokens.page.groupTransactionList.method]: 'Método',
  [tokens.page.groupTransactionList.year]: 'Año',
  [tokens.page.groupTransactionList.yes]: 'Sí',
  [tokens.page.groupTransactionList.no]: 'No', 

  [tokens.page.groupTransactionAdd.addGroupTransaction]: 'Agregar Transacción de Grupo',
  [tokens.page.groupTransactionAdd.groupTransactionAdded]: 'Transacción de Grupo Agregada',
  [tokens.page.groupTransactionAdd.transactionTypeCapital]: 'TIPO DE TRANSACCIÓN',
  [tokens.page.groupTransactionAdd.contractCapital]: 'CONTRATO',
  [tokens.page.groupTransactionAdd.entityCapital]: 'ENTIDAD',
  [tokens.page.groupTransactionAdd.selectMostSuitable]: 'Seleccionar la más adecuada',
  [tokens.page.groupTransactionAdd.continue]: 'Continuar',
  [tokens.page.groupTransactionAdd.saleOfGoodsDescription]: 'Cuando una entidad dentro del grupo vende productos a otra entidad dentro del grupo.',
  [tokens.page.groupTransactionAdd.provisionOfServicesDescription]: 'Cuando una entidad dentro del grupo proporciona servicios a otra entidad dentro del grupo.',
  [tokens.page.groupTransactionAdd.licenseOfIPDescription]: 'Cuando una entidad dentro del grupo transfiere derechos de propiedad intelectual, como patentes, marcas comerciales, derechos de autor o tecnología propietaria, a otra entidad dentro del grupo.',
  [tokens.page.groupTransactionAdd.cashCapitalLoanDescription]: 'Cuando una entidad dentro del grupo proporciona efectivo, inversiones o préstamos a otra entidad dentro del grupo.',
  [tokens.page.groupTransactionAdd.costSharingDescription]: 'Cuando las entidades del grupo colaboran en la investigación, desarrollo o adquisición de intangibles.',
  [tokens.page.groupTransactionAdd.leaseOfTengibleAssetsDescription]: 'Cuando una entidad dentro del grupo arrienda activos tangibles (como equipos o propiedades) a otra entidad dentro del grupo.',
  [tokens.page.groupTransactionAdd.distributionResaleOfGoodsDescription]: 'Cuando una entidad dentro del grupo vende productos a otra entidad dentro del grupo para su distribución o reventa.',
  [tokens.page.groupTransactionAdd.managementFeesDescription]: 'Cargos por servicios de gestión proporcionados por una entidad dentro del grupo a otra entidad dentro del grupo.',
  [tokens.page.groupTransactionAdd.uploadedFiles]: 'Archivos cargados',
  [tokens.page.groupTransactionAdd.uploadFiles]: 'Cargar archivos',
  [tokens.page.groupTransactionAdd.upload]: 'Cargar',
  [tokens.page.groupTransactionAdd.back]: 'Volver',
  [tokens.page.groupTransactionAdd.noAgreementAvailable]: 'Ningún acuerdo disponible para cargar',
  [tokens.page.groupTransactionAdd.otherFile]: 'Otro archivo',
  [tokens.page.groupTransactionAdd.maxFileSize]: 'Tamaño máximo del archivo',
  [tokens.page.groupTransactionAdd.view]: 'Ver',
  [tokens.page.groupTransactionAdd.enterEntity]: 'Ingresar una entidad',
  [tokens.page.groupTransactionAdd.addTransaction]: 'Agregar Transacción',
  [tokens.page.groupTransactionAdd.allDone]: '¡Todo listo!',
  [tokens.page.groupTransactionAdd.previewOfAddedTransaction]: 'Aquí tienes una vista previa de la transacción de grupo que acabas de agregar.',
  [tokens.page.groupTransactionAdd.addAnother]: 'Agregar otro',

  [tokens.page.groupTransactionEdit.groupTransactions]: 'Transacciones de grupo',
  [tokens.page.groupTransactionEdit.transactionNotFound]: 'Transacción no encontrada',
  [tokens.page.groupTransactionEdit.transaction]: 'Transacción',
  [tokens.page.groupTransactionEdit.transactionId]: 'ID de transacción',
  [tokens.page.groupTransactionEdit.details]: 'Detalles',
  [tokens.page.groupTransactionEdit.transactionType]: 'Tipo de transacción',
  [tokens.page.groupTransactionEdit.pricingMethod]: 'Método de fijación de precios',
  [tokens.page.groupTransactionEdit.sendingEntity]: 'Entidad emisora',
  [tokens.page.groupTransactionEdit.receivingEntity]: 'Entidad receptora',
  [tokens.page.groupTransactionEdit.effectiveDateOfTransaction]: 'Fecha efectiva de la transacción',
  [tokens.page.groupTransactionEdit.verified]: 'Verificado',
  [tokens.page.groupTransactionEdit.active]: 'Activo',
  [tokens.page.groupTransactionEdit.attachments]: 'Adjuntos',
  [tokens.page.groupTransactionEdit.uploadMore]: 'Subir más',
  [tokens.page.groupTransactionEdit.update]: 'Actualizar',
  [tokens.page.groupTransactionEdit.cancel]: 'Cancelar',
  [tokens.page.groupTransactionEdit.deleteAttachment]: 'Eliminar adjunto',
  [tokens.page.groupTransactionEdit.deleteAttachmentBody]: '¿Estás seguro de que quieres eliminar este adjunto?',
  [tokens.page.groupTransactionEdit.delete]: 'Eliminar',
  [tokens.page.groupTransactionEdit.view]: 'Ver',

  [tokens.page.groupTransactionDetails.groupTransactions]: 'Transacciones de grupo',
  [tokens.page.groupTransactionDetails.pricingMethodology]: 'Metodología de fijación de precios',
  [tokens.page.groupTransactionDetails.pricingMethodologyDescription]: 'Seleccione el método de precios de transferencia para la transacción controlada. Ya sea el Método del Precio No Controlado Comparativo (CUP), el Método del Precio de Reventa (RPM), el Método del Costo Plus (CPM), el Método de Margen Neto Transaccional (TNMM), el Método de División de Beneficios Transaccional (TPSM) o el Método de División de Beneficios (PSM).',
  [tokens.page.groupTransactionDetails.comparableTransactions]: 'Transacciones comparables',
  [tokens.page.groupTransactionDetails.comparableTransactionsDescription]: 'Información sobre transacciones comparables o datos de mercado utilizados para respaldar el método de precios de transferencia seleccionado. Incluyendo estudios de benchmarking, datos de la industria u otras fuentes de información de precios comparables.',
  [tokens.page.groupTransactionDetails.functionalAnalysis]: 'Análisis funcional',
  [tokens.page.groupTransactionDetails.functionalAnalysisDescription]: 'Las funciones realizadas, los riesgos asumidos y los activos empleados por cada Entidad en la transacción controlada. Para determinar si el precio de la transacción es consistente con la sustancia económica de las funciones realizadas por cada Entidad.',
  [tokens.page.groupTransactionDetails.documentationRequirements]: 'Requisitos de documentación',
  [tokens.page.groupTransactionDetails.documentationRequirementsDescription]: 'Documentación exhaustiva para respaldar la naturaleza de precio de transferencia de la transacción controlada y demostrar el cumplimiento de los requisitos normativos.',
  [tokens.page.groupTransactionDetails.upload]: 'Subir',
  [tokens.page.groupTransactionDetails.otherFile]: 'u otro archivo',
  [tokens.page.groupTransactionDetails.riskAssessment]: 'Evaluación de riesgos',
  [tokens.page.groupTransactionDetails.riskAssessmentDescription]: 'Evaluar el riesgo de precios de transferencia asociado con la transacción mediante la evaluación del potencial de auditorías de precios de transferencia, ajustes, sanciones o disputas con autoridades fiscales y sugerir medidas apropiadas para mitigar dichos riesgos.',
  [tokens.page.groupTransactionDetails.summary]: 'Resumen',
  [tokens.page.groupTransactionDetails.method]: 'MÉTODO',
  [tokens.page.groupTransactionDetails.countries]: 'PAÍSES',
  [tokens.page.groupTransactionDetails.transactionYear]: 'AÑO DE TRANSACCIÓN',
  [tokens.page.groupTransactionDetails.entities]: 'ENTIDADES',
  [tokens.page.groupTransactionDetails.transactionDetails]: 'Detalles de la transacción',
  [tokens.page.groupTransactionDetails.detailsTab]: 'Detalles',
  [tokens.page.groupTransactionDetails.pricingMethodTab]: 'Método de fijación de precios',
  [tokens.page.groupTransactionDetails.comparablesTab]: 'Transacciones comparables',
  [tokens.page.groupTransactionDetails.functionalTab]: 'Funcional',
  [tokens.page.groupTransactionDetails.documentationTab]: 'Documentación',
  [tokens.page.groupTransactionDetails.riskTab]: 'Riesgo',
  [tokens.page.groupTransactionDetails.saleOfGoodsDetails1]: 'La naturaleza específica de los bienes vendidos, la cantidad o volumen de bienes intercambiados y cualquier término o condición relevante de la venta.',
  [tokens.page.groupTransactionDetails.provisionOfServicesDetails1]: 'La naturaleza específica de los servicios prestados, el alcance, la duración y la complejidad de los servicios prestados por el proveedor de servicios al receptor de servicios. El arreglo de precios, los términos de pago y cualquier métrica de rendimiento o acuerdos de nivel de servicio (SLA) relevantes.',
  [tokens.page.groupTransactionDetails.licenseOfIPDetails1]: 'La naturaleza específica de la propiedad intelectual licenciada, el tipo de propiedad intangible (por ejemplo, patentes, marcas comerciales, derechos de autor, secretos comerciales), su importancia para las partes involucradas y cualquier característica única que pueda afectar su valoración. Esto incluye el alcance de la licencia, la duración del acuerdo, restricciones territoriales, tasas de regalías, términos de pago y cualquier otra disposición relevante.',
  [tokens.page.groupTransactionDetails.cashCapitalLoanDetails1]: 'El propósito específico de la transferencia de fondos, contribución de capital o préstamo. Por qué se transfieren los fondos y cómo los utilizará la Entidad Receptora. Esto podría incluir la financiación de las necesidades de capital de trabajo, la financiación de gastos de capital o el apoyo a iniciativas comerciales específicas. Revise los términos y condiciones de la transacción, como la cantidad de fondos transferidos.',
  [tokens.page.groupTransactionDetails.cashCapitalLoanDetails2]: 'Para Préstamos, la tasa de interés (si corresponde), programas o términos de reembolso, cualquier garantía o seguridad proporcionada y cualquier otra disposición relevante. Este tipo de transferencia es común cuando se utiliza para financiar operaciones o ayudar a configurar y expandirse en nuevos mercados.',
  [tokens.page.groupTransactionDetails.cashCapitalLoanDetails3]: 'Para Contribuciones de capital, cuál fue el número de acciones/opciones de capital que se intercambiaron. Este tipo de transferencia es común al mover fondos de inversión de una sociedad tenedora a una subsidiaria operativa.',
  [tokens.page.groupTransactionDetails.costSharingDetails1]: 'La naturaleza específica de la propiedad intangible a desarrollar, mejorar o mantener en virtud del acuerdo de reparto de costos. Esto incluye el tipo de propiedad intangible (por ejemplo, patentes, marcas comerciales, derechos de autor, secretos comerciales), su importancia para los Participantes y cualquier característica única que pueda afectar su valoración. Aclarar el alcance y los objetivos del acuerdo de reparto de costos, incluidas las actividades/contribuciones específicas que realizará cada Participante.',
  [tokens.page.groupTransactionDetails.costSharingDetails2]: 'Qué recursos, experiencia y otras contribuciones proporciona cada Participante, así como la base para asignar costos, riesgos y beneficios entre los participantes. Así como los derechos y obligaciones de los participantes y cualquier mecanismo de resolución de disputas.',
  [tokens.page.groupTransactionDetails.costSharingDetails3]: 'La duración esperada del acuerdo y los beneficios anticipados derivados del desarrollo, mejora o mantenimiento de la propiedad intangible.',
  [tokens.page.groupTransactionDetails.leaseOfTengibleAssetsDetails1]: 'La naturaleza específica de los activos tangibles arrendados. Esto incluye el tipo de activos (por ejemplo, equipo, maquinaria, vehículos, bienes raíces), su estado, uso y cualquier característica única que pueda afectar su valoración. Así como la duración del arrendamiento, pagos de alquiler, opciones de renovación, responsabilidades de mantenimiento y cualquier otra disposición relevante.',
  [tokens.page.groupTransactionDetails.distributionResaleOfGoodsDetails1]: 'La naturaleza específica de los bienes distribuidos o revendidos, como el tipo de bienes (por ejemplo, productos terminados, materias primas, componentes), sus características, calidad y cualquier característica única que pueda afectar su valoración. Esto incluye el alcance del acuerdo, los arreglos de precios, los términos de pago, los términos de entrega, las restricciones territoriales y cualquier otra disposición relevante.',
  [tokens.page.groupTransactionDetails.managementFeesDetails1]: 'La naturaleza específica de los servicios de gestión proporcionados, como el alcance de los servicios, el nivel de experiencia requerido y cualquier característica única que pueda afectar la valoración de los servicios. Esto incluye el alcance de los servicios, los arreglos de precios, los términos de pago, las métricas de rendimiento y cualquier otra disposición relevante.',

  // message constants
  [tokens.message.verifyEmailFirst]: 'Primero necesitas verificar tu dirección de correo electrónico',
  [tokens.message.alreadyInvited]: 'El empleado tiene una invitación pendiente de la empresa:',
  [tokens.message.employeeAlreadyExist]: 'Ya existe en la empresa:',
  [tokens.message.invalidCredentials]: "Credenciales inválidas",
  [tokens.message.internalServerError]: "Error interno del servidor",
  [tokens.message.notFound]: "No encontrado",
  [tokens.message.alreadyExist]: "Ya existe",
  [tokens.message.savedSuccesfully]: "Guardado exitosamente",
  [tokens.message.trialPeriodExpired]: "Período de prueba expirado",
  [tokens.message.subscriptionCreated]: "Suscripción creada",
  [tokens.message.paymentSuccesful]: "Pago exitoso",
  [tokens.message.companyRegistrationSuccessful]: "Registro de empresa exitoso",
  [tokens.message.invalidOTP]: "OTP inválido",
  [tokens.message.unsubscribed]: "No ha adquirido nuestro plan",
  [tokens.message.emailSent]: "Correo electrónico enviado exitosamente",
  [tokens.message.emailNotSent]: "No se pudo enviar el correo electrónico",
  [tokens.message.validToken]: "El token es válido",
  [tokens.message.inavlidToken]: "El token no es válido",
  [tokens.message.tokenValidationFailed]: "Fallo en la autenticación del token",
  [tokens.message.notAuthrized]: "No autorizado",
  [tokens.message.updatedSuccesfully]: "Actualizado exitosamente",
  [tokens.message.companyNotFound]: "Empresa no encontrada",
  [tokens.message.currentPasswordIsWrong]: 'La contraseña actual es incorrecta',
  [tokens.message.loggedOutSuccessfully]: 'Cierre de sesión exitoso',

  [tokens.message.requiredFieldsEmpty]: 'Füllen Sie alle erforderlichen Felder aus',
  [tokens.message.removedSuccessfully]: 'Eliminado exitosamente',
  [tokens.message.invitationRevokedSuccessfully]: 'Invitación revocada exitosamente',
  [tokens.message.resetPasswordLinkSent]: 'Enlace para restablecer la contraseña enviado a su correo electrónico',
  [tokens.message.passwordAndConfirmPassMustMatch]: 'La contraseña y la confirmación de la contraseña deben coincidir',
  [tokens.message.passwordTooShort]: 'La contraseña debe tener al menos 7 caracteres de longitud',
  [tokens.message.passwordUpdated]: 'Contraseña actualizada exitosamente',
  [tokens.message.otpSentToEmail]: 'OTP enviado a su correo electrónico',
  [tokens.message.photoUpdatedSuccessfully]: 'Foto actualizada exitosamente',
  [tokens.message.employeeRoleUpdatedSuccessfully]: 'Rol del empleado actualizado exitosamente',
  [tokens.message.companyNameUpdatedSuccessfully]: 'Nombre de la empresa actualizado exitosamente',
  [tokens.message.nameUpdatedSuccessfully]: 'Nombre actualizado exitosamente',
  [tokens.message.somethingWentWrong]: 'Algo salió mal',
  [tokens.message.countryFieldRequired]: 'Se requiere el país',
  [tokens.message.entityNameFieldRequired]: 'Se requiere el nombre de la entidad',
  [tokens.message.entityCategoryFieldRequired]: 'Se requiere la categoría',
  [tokens.message.entityUpdated]: 'Entidad actualizada',
  [tokens.message.officeHolderCreated]: 'Tipo de titular de oficina personalizado creado exitosamente',
  [tokens.message.entityDeleted]: 'Entidad eliminada exitosamente',
  [tokens.message.departmentCreated]: 'Departamento creado exitosamente',
  [tokens.message.teamMemberUpdated]: 'Miembro del equipo actualizado',
  [tokens.message.transactionTypeRequired]: 'Se requiere tipo de transacción',
  [tokens.message.contractTypeRequired]: 'Se requiere tipo de contrato',
  [tokens.message.contractFileAndFileTagRequired]: 'Suba un archivo y una etiqueta de archivo o elija \'No hay argumento disponible para cargar\'',
  [tokens.message.entityFieldRequired]: 'Entidad no seleccionada',
  [tokens.message.transactionDeleted]: 'Transacción eliminada',
  [tokens.message.groupTransactionUpdatedSuccessfully]: 'Transacción de grupo actualizada exitosamente',

  [tokens.message.eventCreated]: '¡Evento creado!',
  [tokens.message.eventUpdated]: '¡Evento actualizado!',
  [tokens.message.eventDeleted]: '¡Evento eliminado!',
};

import type { AnyAction, PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "src/store";
import { ThunkAction } from "redux-thunk";
import { Transaction } from "src/interfaces/transaction.interface";
import { TRANSACTION } from "src/endpoints";
import { NavigateFunction } from "react-router";
import { authRequestHandler } from "src/utils/fetch";
import { getFolders } from "./file-manager";

interface TransactionState {
  transactions: Transaction[];
  isLoading: boolean;
}

const initialState: TransactionState = {
  transactions: [],
  isLoading: true,
};

export const slice = createSlice({
  name: "entities",
  initialState,
  reducers: {
    setLoading(state: TransactionState, action: PayloadAction<boolean>): void {
      state.isLoading = action.payload;
    },
    setTransactions(
      state: TransactionState,
      action: PayloadAction<Transaction[]>
    ): void {
      state.transactions = action.payload;
    },
    removeTransactionAttachment(
      state: TransactionState,
      action: PayloadAction<{ transactionId: string; attachmentId: string }>
    ): void {
      state.transactions = state.transactions.map((transaction) => {
        if (transaction._id === action.payload.transactionId) {
          const attachments = transaction.attachments?.filter(
            (attachment) => attachment._id !== action.payload.attachmentId
          );
          return {
            ...transaction,
            attachments: attachments,
          };
        }
        return transaction;
      });
    },
  },
});

type AsyncAction = ThunkAction<Promise<void>, RootState, undefined, AnyAction>;

export const getTransactions =
  (navigate: NavigateFunction): AsyncAction =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(slice.actions.setLoading(true));
    const { response, resJson } = await authRequestHandler({
      method: "GET",
      url: TRANSACTION.LIST_ALL,
      navigate,
    });
    if (response.ok) {
      dispatch(slice.actions.setTransactions(resJson?.data));
      dispatch(slice.actions.setLoading(false));
    }
  };

export const deleteTransactionAttachment =
  (
    navigate: NavigateFunction,
    transactionId: string,
    attachmentId: string
  ): AsyncAction =>
  async (dispatch: AppDispatch): Promise<void> => {
    const { response, resJson } = await authRequestHandler({
      url: TRANSACTION.DELETE_ATTACHMENT,
      navigate,
      method: "POST",
      body: JSON.stringify({
        transactionId,
        attachmentId,
      }),
    });
    if (response.ok) {
      dispatch(
        slice.actions.removeTransactionAttachment({
          transactionId,
          attachmentId,
        })
      );
      dispatch(getFolders(navigate));
    }
  };

interface deleteTransactionAttachmentByIdsParams {
  transactionId: string;
  attachmentId: string;
}
export const deleteTransactionAttachmentAction =
  ({
    transactionId,
    attachmentId,
  }: deleteTransactionAttachmentByIdsParams): AsyncAction =>
  async (dispatch: AppDispatch): Promise<void> => {
    dispatch(
      slice.actions.removeTransactionAttachment({ transactionId, attachmentId })
    );
  };

export const { reducer } = slice;
